import React from "react";
import PropTypes from "prop-types";
import DepositEtherModal from "./DepositEtherModal";
import DepositCreditModal from "./DepositCreditModal";
import { useSelector } from "react-redux";

export default function DepositBalanceModal({
  showModal,
  onClose,
  modalType,
  creditBalance,
  etherBalance,
}) {
  if (!showModal) return null; // If showModal is false, don't display the modal.
  const user = useSelector((state) => state.user);
  return (
    <>
      {" "}
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm z-40"
        onClick={onClose}
      ></div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg max-w-md w-full relative transition duration-300 ease-in-out">
          {modalType === "depositEther" && (
            <DepositEtherModal
              onClose={onClose}
              etherBalance={etherBalance}
              responsableWallet={user?.responsableWallet}
            />
          )}
          {modalType === "depositCredit" && (
            <DepositCreditModal
              onClose={onClose}
              creditBalance={creditBalance}
              responsableWallet={user?.responsableWallet}
            />
          )}
        </div>
      </div>
    </>
  );
}
DepositBalanceModal.propTypes = {
  showModal: PropTypes.bool,
  modalType: PropTypes.string,
  onClose: PropTypes.func,
  creditBalance: PropTypes.number,
  etherBalance: PropTypes.number,
};
