import React from "react";
import { getTranslation } from "../../utils/translate/translation";
import UniversitiesListTable from "./UniversityListTable";
const translation = {
  en: {
    UniversitiesList: "Universities List",
  },
  ar: {
    UniversitiesList: "قائمة الجامعات",
  },
  fr: {
    UniversitiesList: "Liste des universités",
  },
};
export default function UniversitiesList() {
  return (
    <div className="flex flex-col p-4 w-full md:p-6 lg:p-8">
      <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
        {getTranslation(translation, "UniversitiesList")}
      </h1>
      <div className="flex flex-col p-4 justify-start w-full space-y-1 bg-white rounded sm:p-0 md:p-6 lg:p-8 h-fit">
        <UniversitiesListTable />
      </div>
    </div>
  );
}
