import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import logo from "../../assets/certified_logo.png";
import { passwordIcon } from "../../assets/svg/Index";
import MainButton from "../../components/button/MainButton";
import DateInput from "../../components/input/DateInput";
import Input from "../../components/input/Input";
import { SIGN_IN } from "../../store/features/studentSlice/studentSlice";
import {
  useLoginStudentsMutation,
  useSigninStudentWithPasswordMutation,
} from "../../store/service/usersService";
import { getTranslation } from "../../utils/translate/translation";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";
function StudentsLoginPage() {
  const translate = {
    ar: {
      SPACE_STUDENT: "فضاء الطالب",
      ENTER_NATIONAL_ID: "الرّجاء إدخال رقم المعرّف الوطني الخاص بك",
      NATIONAL_ID: "المعرّف الوطني",
      INVALID_CREDENTIALS: "بيانات الاعتماد غير صالحة",
      LOGIN: "دخول",
      ALREADY_HAVE_ACCOUNT: "لديك حساب بالفعل ؟",
      FIRST_TIME_LOGIN: "تسجيل الدخول لأول مرة ؟",
      ALREADY_Confirm_ACCOUNT:
        "لقد قمت بتأكيد حسابك بالفعل قم بتسجيل الدخول بكلمة السر الخاصة بك",
    },
    fr: {
      SPACE_STUDENT: "Espace étudiant",
      ENTER_NATIONAL_ID:
        "Veuillez saisir votre numéro d'identification national",
      NATIONAL_ID: "Numéro d'identification national",
      INVALID_CREDENTIALS: "Identifiants invalides",
      LOGIN: "Connexion",
      ALREADY_HAVE_ACCOUNT: "Vous avez déjà un compte ?",
      FIRST_TIME_LOGIN: "Première connexion ?",
      ALREADY_Confirm_ACCOUNT:
        "vous avez déjà confirmé votre compte connectez-vous avec votre mot de passe",
    },
    en: {
      SPACE_STUDENT: "Student Space",
      ENTER_NATIONAL_ID: "Please enter your national ID number",
      NATIONAL_ID: "National ID",
      INVALID_CREDENTIALS: "Invalid credentials",
      LOGIN: "Login",
      ALREADY_HAVE_ACCOUNT: "Already have an account ?",
      FIRST_TIME_LOGIN: "First Time Login ?",
      ALREADY_Confirm_ACCOUNT:
        "you are already confirm your account sign in with your password",
    },
  };

  const [signin] = useLoginStudentsMutation();
  const [signinStudentWithPassword] = useSigninStudentWithPasswordMutation();
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dayOfBirth, setDayOfBirth] = useState("");
  const [monthOfBirth, setMonthOfBirth] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [nin, setNin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const isMainButtonDisabled =
    dayOfBirth === "" ||
    monthOfBirth === "" ||
    yearOfBirth === "" ||
    nin === "" ||
    !captcha;
  const isMainButtonLoginDisabled = password === "" || nin === "" || !captcha;
  let dispatch = useDispatch();
  const padWithZero = (num) => {
    return (num < 10 ? "0" : "") + num;
  };
  const signInFirstTimeAction = async () => {
    setIsLoading(true);
    const dateOfBirth =
      padWithZero(dayOfBirth) +
      "/" +
      padWithZero(monthOfBirth) +
      "/" +
      padWithZero(yearOfBirth);
    const dataToSend = {
      nin,
      dateOfBirth,
    };
    try {
      await signin(dataToSend).then((res) => {
        if (res.data.token) {
          setIsLoading(false);
          //check if its his first time to login
          if (res.data.firstLogin) {
            dispatch(
              SIGN_IN({
                token: res.data.token,
                role: res.data.role,
                username: res.data.username,
                email: res.data.email,
                universityName: res.data.universityName,
                numberOfLogins: res.data.lastLoginDate,
                lastLoginDate: res.data.lastLoginDate,
              })
            );

            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            signin(dataToSend);
            setNin(res.data.nin);
            window.location.replace("/student_profile/");
          } else {
            //empty date of birth inputs
            setDayOfBirth("");
            setMonthOfBirth("");
            setYearOfBirth("");
            toast.info(getTranslation(translate, "ALREADY_Confirm_ACCOUNT"));
            //if its not his first time to login
            setFirstTimeLogin(false);
          }
        } else if (res.error.status == 404) {
          setIsLoading(true);
          setError(true);
        } else {
          setIsLoading(false);
          setError(true);
        }
      });
    } catch (err) {
      setIsLoading(false);
      setError(true);
    }
  };
  const signIn = async () => {
    setIsLoading(true);
    const dataToSend = {
      nin,
      password,
    };
    try {
      await signinStudentWithPassword(dataToSend).then((res) => {
        if (res.data.token) {
          setIsLoading(false);
          dispatch(
            SIGN_IN({
              token: res.data.token,
              role: res.data.role,
              username: res.data.username,
              email: res.data.email,
              universityName: res.data.universityName,
              numberOfLogins: res.data.lastLoginDate,
              lastLoginDate: res.data.lastLoginDate,
            })
          );

          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.role);
          signinStudentWithPassword(dataToSend);
          setNin(res.data.nin);
          window.location.replace("/student_profile/");
        } else if (res.error.status == 404) {
          setIsLoading(true);
          setError(true);
        } else {
          setIsLoading(false);
          setError(true);
        }
      });
    } catch (err) {
      setIsLoading(false);
      setError(true);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.replace("/student_profile/");
    }
  }, []);
  return (
    <div className="flex flex-col items-start justify-start p-8 space-y-8 min-h-screen">
      <img src={logo} alt="" className="mb-2" />

      <Link
        to="/"
        className="flex items-center justify-center group  text-white transition-all duration-700 ease-in-out bg-blue-800 p-2 border-2 border-blue-800 rounded-md hover:!text-blue-800 hover:!bg-white"
      >
        <span className="mr-2">
          {" "}
          <IoMdArrowRoundBack />{" "}
        </span>
      </Link>

      <h1 className="text-5xl font-bold text-blue-800">
        {" "}
        {getTranslation(translate, "SPACE_STUDENT")}{" "}
      </h1>

      <p className="text-sm text-gray-500">
        {getTranslation(translate, "ENTER_NATIONAL_ID")}
      </p>

      <Input
        icon={passwordIcon}
        placeholder={getTranslation(translate, "NATIONAL_ID")}
        inputChange={setNin}
        type={"NO_CONTROL"}
        value={nin}
      />
      {firstTimeLogin ? (
        <DateInput
          dayOfBirth={dayOfBirth}
          setDayOfBirth={setDayOfBirth}
          monthOfBirth={monthOfBirth}
          setMonthOfBirth={setMonthOfBirth}
          yearOfBirth={yearOfBirth}
          setYearOfBirth={setYearOfBirth}
        />
      ) : (
        <Input
          icon={passwordIcon}
          placeholder={"password"}
          inputChange={setPassword}
          type={"NO_CONTROL"}
          password
          value={password}
        />
      )}

      <ReCAPTCHA
        sitekey="6LehamEoAAAAALXfDBbL60ioXe1SBu2UuAJIaZRc"
        onChange={() => setCaptcha(true)}
      />
      {error ? (
        <span className="font-bold text-red-500">
          {" "}
          {getTranslation(translate, "INVALID_CREDENTIALS")}
        </span>
      ) : (
        <></>
      )}

      <MainButton
        id="main-button"
        title={getTranslation(translate, "LOGIN")}
        action={firstTimeLogin ? signInFirstTimeAction : signIn}
        isLoading={isLoading}
        disabled={
          firstTimeLogin ? isMainButtonDisabled : isMainButtonLoginDisabled
        }
      />
      <button
        onClick={() => setFirstTimeLogin(!firstTimeLogin)}
        className="text-blue-800"
      >
        {firstTimeLogin
          ? getTranslation(translate, "ALREADY_HAVE_ACCOUNT")
          : getTranslation(translate, "FIRST_TIME_LOGIN")}
      </button>
    </div>
  );
}

export default StudentsLoginPage;
