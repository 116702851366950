import React from "react";
import Logo from "../logo/Logo";
import NavBar from "../nav-bar/NavBar";
import Languageschanger from "../languages-btns/Languageschanger";
import logo from "../../../assets/certified_logo.png";

export default function Header() {
  return (
    <div className="mt-12">
      <header className="fixed top-0 left-0 w-full z-50 bg-[#171aa8] p-3">
        <div className="flex items-center justify-between lg:justify-around lg:mx-0">
          <Logo src={logo} alt={"logo"} />
          <NavBar />
          <Languageschanger />
        </div>
      </header>
    </div>
  );
}
