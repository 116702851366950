import React from "react";

import FeautureItem from "./FeautureItem";
import { BsShieldLock, BsPersonCheck, BsSpeedometer } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import PropTypes from "prop-types";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    feature1Title: "Protection",
    feature1Description:
      "Blockchain technology is one of the most secure technologies in the world. This platform also saves space For students to keep their certificates forever without fear of loss or damage. In addition to the commitment The platform protects users' personal data.",
    feature2Title: "Credibility",
    feature2Description:
      " This platform is considered a reference in Arab countries to check the credibility testimonials. As she resorts Official authorities and institutions go to this platform to verify the authenticity of the certificate that the student has submitted.",
    feature3Title: "Efficiency",
    feature3Description:
      " It became possible to verify the authenticity of the submitted certificate in one click, instead of waiting days and weeks Correspond with official authorities.",
  },
  ar: {
    feature1Title: "الحماية",
    feature1Description:
      "تعتبر تقنية البلوكتشين من أكثر التقنيات أمانا في العالم. كما توفّر هذه المنصّة فضاء خاصا بالطلاب للاحتفاظ بشهاداتهم إلى الأبد دون الخوف عليها من الضياع أو التلف. بالإضافة إلى التزام المنصّة بحماية المعطيات الشخصية للمستعملين.",
    feature2Title: "المصداقية",
    feature2Description:
      "تعتبر هذه المنصّة مرجعا بالدول العربية للتثبت من مدى مصداقية الشهادات. إذ تلجأ الجهات الرّسمية والمؤسسات إلى هذه المنصة للتثبت من صحّة الشهادة التي تقدّم بها الطالب.",
    feature3Title: "الكفاءة",
    feature3Description:
      "أصبح من الممكن التثبت من مداقية الشهادة المقدّمة بكبسة زرّ عوض انتظار أيام وأسابيع لمراسلة الجهات الرّسمية.",
  },
  fr: {
    feature1Title: "Protection",
    feature1Description:
      "La technologie de la blockchain est l'une des technologies les plus sécurisées au monde. Cette plateforme permet également d'économiser de l'espace Pour que les étudiants conservent leurs certificats pour toujours sans craindre de les perdre ou de les endommager. En plus de l'engagement La plateforme protège les données personnelles des utilisateurs.",
    feature2Title: "Crédibilité",
    feature2Description:
      " Cette plateforme est considérée comme une référence dans les pays arabes pour vérifier la crédibilité des témoignages. Comme elle recourt Les autorités officielles et les institutions se rendent sur cette plateforme pour vérifier l'authenticité du certificat que l'étudiant a soumis.",
    feature3Title: "Efficacité",
    feature3Description:
      " Il est devenu possible de vérifier l'authenticité du certificat soumis en un clic, au lieu d'attendre des jours et des semaines Correspondre avec les autorités officielles.",
  },
};
export default function SecondSection({
  sectionTitle,
  sectionDescription,
  sectionImg,
}) {
  return (
    <>
      <div
        className="p-3 bg-white pt-20 grid gap-2 lg:grid-cols-2 lg:px-8"
        id="criteria"
      >
        <div
          className="flex justify-center items-center relative"
          data-aos="fade-right"
          data-aos-delay="50"
          data-aos-once="true"
          data-aos-mirror="false"
          data-aos-duration="1200"
        >
          {/* Image */}
          <img
            src={sectionImg}
            alt="about image"
            className="w-64 md:w-80 lg:w-96 xl:w-2/3"
          />

          {/* Circle and Play Icon */}
          <div className="absolute inset-0 flex justify-center items-center">
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="circle-container group"
            >
              <div className="circle hover-effect group-hover:scale-110 transition-transform duration-300">
                <FaPlay className="play-icon text-white group-hover:animate-ping" />
              </div>
            </a>
          </div>
        </div>
        <div>
          <div
            data-aos="fade-left"
            data-aos-duration="1200"
            data-aos-delay="50"
            data-aos-once="true"
            data-aos-mirror="false"
          >
            <h1 className="text-[#010483] text-3xl font-bold my-4">
              {sectionTitle}
            </h1>
            <p>{sectionDescription}</p>
          </div>
          <div>
            <FeautureItem
              featureTitle={getTranslation(translate, "feature1Title")}
              featureDescription={getTranslation(
                translate,
                "feature1Description"
              )}
              featureIcon={
                <BsShieldLock className="text-4xl font-black text-[#010483]" />
              }
            />
            <FeautureItem
              featureTitle={getTranslation(translate, "feature2Title")}
              featureDescription={getTranslation(
                translate,
                "feature2Description"
              )}
              featureIcon={
                <BsPersonCheck className="text-4xl font-black text-[#010483]" />
              }
            />
            <FeautureItem
              featureTitle={getTranslation(translate, "feature3Title")}
              featureDescription={getTranslation(
                translate,
                "feature3Description"
              )}
              featureIcon={
                <BsSpeedometer className="text-4xl font-black text-[#010483]" />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

SecondSection.propTypes = {
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  sectionImg: PropTypes.string,
};
