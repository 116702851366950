import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import { LiaCoinsSolid } from "react-icons/lia";
import { getTranslation } from "../../../utils/translate/translation";
import { useSendCreditDepositeDemandeMutation } from "../../../store/service/usersService";
import { toastSucces, toastErrorServer } from "../../toast/Toast";
import Spinner from "../../spinner/Spinner";

export default function DepositCreditModal({ onClose, creditBalance }) {
  const translation = {
    en: {
      MODAL_TITLE: "Deposit credit Request",
      MODAL_P: "Enter the amount you wish to deposit into your account",
      DEPOSIT_AMOUNT: "Deposit Amount",
      CURRENNT_BALANCE: "Current Balance",
      SEND_DEPOSIT_REQUEST: "Send Deposit Request",
    },
    fr: {
      MODAL_TITLE: "Envoyer une demande de dépôt de crédit",
      MODAL_P: "Entrez le montant que vous souhaitez déposer sur votre compte",
      DEPOSIT_AMOUNT: "Montant du dépôt",
      CURRENNT_BALANCE: "Solde actuel",
      SEND_DEPOSIT_REQUEST: "Envoyer la demande de dépôt",
    },
    ar: {
      MODAL_TITLE: "إرسال طلب إيداع الرصيد",
      MODAL_P: "أدخل المبلغ الذي ترغب في إيداعه في حسابك",
      DEPOSIT_AMOUNT: "مبلغ الإيداع",
      CURRENNT_BALANCE: "الرصيد الحالي",
      SEND_DEPOSIT_REQUEST: "إرسال طلب الإيداع",
    },
  };
  const [DemandedBalance, setDemandedBalance] = useState(0);
  const [sendCreditDepositeDemande] = useSendCreditDepositeDemandeMutation();
  const [onProcessDepositRequest, setOnProcessDepositRequest] = useState(false);

  const sendDemandeOfDeposit = async () => {
    setOnProcessDepositRequest(true);
    sendCreditDepositeDemande({
      amount: DemandedBalance,
    }).then((res) => {
      if (res.error) {
        setOnProcessDepositRequest(false);
        toastErrorServer(res.error.data.message);
        return;
      }
      if (res.data) {
        setOnProcessDepositRequest(false);
        toastSucces("Deposit Request Sent Successfully");
        onClose();
      }
    });
  };
  return (
    <div>
      <div className="flex items-center  justify-between bg-[#285090] text-white">
        <h2 className="text-xl p-1.5 px-2 font-semibold">
          {getTranslation(translation, "MODAL_TITLE")}
        </h2>
        <span
          onClick={onClose}
          className="cursor-pointer text-xl p-2.5 transition ease-in-out hover:bg-red-600 hover:text-white"
        >
          <IoMdClose />
        </span>
      </div>
      <div className="p-2 px-3">
        <p className="w-full text-center text-gray-600 mb-4 mt-2">
          {getTranslation(translation, "MODAL_P")}
        </p>
        <div>
          <div className="flex items-center gap-2 my-2 mb-4">
            <p className="font-black">
              {getTranslation(translation, "CURRENNT_BALANCE")}
            </p>{" "}
            <div className="bg-gray-200 rounded-md flex p-1 gap-2 border-2 border-gray-400">
              <p className="text-xl font-black">{creditBalance}</p>
              <LiaCoinsSolid className="text-[#285090] text-2xl" />
            </div>
          </div>
          <div className="flex items-center gap-2 my-2">
            <p className="font-black">
              {getTranslation(translation, "DEPOSIT_AMOUNT")}
            </p>
            <input
              lang="en"
              type="number"
              min={0}
              defaultValue={DemandedBalance}
              onChange={(e) => setDemandedBalance(e.target.value)}
              className="w-1/2 p-1 border border-gray-300 rounded-md"
              placeholder="Enter amount"
            />
          </div>
          <div className="flex items-center justify-center w-full py-2">
            <button
              disabled={DemandedBalance <= 0 || onProcessDepositRequest}
              className={`bg-[#285090] text-white p-2 rounded-md w-full  ${
                DemandedBalance <= 0 && "cursor-not-allowed"
              }`}
              onClick={sendDemandeOfDeposit}
            >
              {onProcessDepositRequest ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                  <p>Processing...</p>
                </div>
              ) : (
                getTranslation(translation, "SEND_DEPOSIT_REQUEST")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
DepositCreditModal.propTypes = {
  onClose: PropTypes.func,
  creditBalance: PropTypes.number,
  responsableWallet: PropTypes.string,
};
