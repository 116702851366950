import React, { useState } from "react";

import Spinner from "../spinner/Spinner";
import {
  useGetResponsableBalanceQuery,
  useGetResponsableEtherBalanceQuery,
} from "../../store/service/userService";
import { FaEthereum } from "react-icons/fa";
import { PiHandWithdraw } from "react-icons/pi";
import { Tooltip } from "@material-tailwind/react";
import { getTranslation } from "../../utils/translate/translation";
import DepositBalanceModal from "../modal/balance/DepositBalanceModal";
function BalanceWrapper() {
  const translation = {
    ar: {
      DEPOSITE_MORE_CREDIT: "إيداع المزيد من الرصيد",
      DEPOSITE_MORE_ETH: "إيداع المزيد من الإيثريوم",
    },
    fr: {
      DEPOSITE_MORE_CREDIT: "Déposer plus de crédit",
      DEPOSITE_MORE_ETH: "Déposer plus d'ETH",
    },
    en: {
      DEPOSITE_MORE_CREDIT: "Deposit more credit",
      DEPOSITE_MORE_ETH: "Deposit more ETH",
    },
  };
  const { data, isError, isLoading } = useGetResponsableBalanceQuery();
  const { data: etherBalance } = useGetResponsableEtherBalanceQuery();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const convertWeiToEther = (wei) => {
    //chow only 4 decimal points
    return (wei / Math.pow(10, 18)).toFixed(7);
  };

  const onClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="py-2 sm:flex items-center gap-4">
        <div className="my-1 flex items-center justify-center  w-[auto] h-9  bg-white rounded-3xl shadow-2xl cursor-pointer">
          <div className="p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#285090"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
              />
            </svg>
          </div>
          <div className="flex items-center justify-center w-full px-2">
            {isError && <></>}
            {isLoading ? (
              <div className="ml-2">
                <Spinner />
              </div>
            ) : (
              <span className="text-2xl font-extrabold text-blue-800">
                {data?.balance}
              </span>
            )}
          </div>
          <Tooltip
            content={getTranslation(translation, "DEPOSITE_MORE_CREDIT")}
          >
            <div
              onClick={() => {
                setShowModal(true);
                setModalType("depositCredit");
              }}
              className="flex items-center justify-center px-3 h-9 bg-[#5ddab4] rounded-3xl font-bold text-xl !text-[#285090] hover:bg-[#50ba9a]"
            >
              <PiHandWithdraw className="text-white" />
            </div>
          </Tooltip>
        </div>
        <div className="flex items-center justify-center  w-[auto] h-9  bg-white rounded-3xl shadow-2xl cursor-pointer">
          <div className="p-2 text-2xl">
            <FaEthereum className="text-[#285090]" />
          </div>
          <div className="flex items-center justify-center w-full px-2">
            {isError && <></>}
            {isLoading ? (
              <div className="ml-2">
                <Spinner />
              </div>
            ) : (
              <span
                className={`text-2xl font-extrabold  ${
                  etherBalance?.balance == 0 ? "text-red-500" : "text-blue-800"
                }`}
              >
                {convertWeiToEther(etherBalance?.balance)}
              </span>
            )}
          </div>
          <Tooltip content={getTranslation(translation, "DEPOSITE_MORE_ETH")}>
            <div
              onClick={() => {
                setShowModal(true);
                setModalType("depositEther");
              }}
              className="flex items-center justify-center px-3 h-9 bg-[#9694ff] rounded-3xl font-bold text-xl !text-[#285090] hover:bg-[#7170c2]"
            >
              <PiHandWithdraw className="text-white" />
            </div>
          </Tooltip>
        </div>
      </div>
      <DepositBalanceModal
        showModal={showModal}
        onClose={onClose}
        modalType={modalType}
        creditBalance={data?.balance}
        etherBalance={convertWeiToEther(etherBalance?.balance)}
      />
    </>
  );
}

export default BalanceWrapper;
