import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { SELECT_DIPLOMA } from "../../store/features/diplomasSlice/diplomasSlice";
import { toastSucces } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";
import DiplomaPdfModal from "../../components/modal/Diploma/diplomaPDF/DiplomaPdfModal";
import TruncatedCell from "../../components/truncated-cell/TruncatedCell";

const translate = {
  ar: {
    MESSAGE_TOAST: "تمت العملية بنجاح !",
    MESSAGE_: " قائمة بيانات الشهادات والملفات",
    STUDENT: "الطالب(ة)",
    NIN: "المعرف الوطني",
    TYPE: "نوع الشهادة",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    APPROVAL_DATE: "تاريخ المصادقة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    EDIT: "تعديل",
    DELETE: "حذف",
  },
  en: {
    MESSAGE_TOAST: "Operation accomplished successfully !",
    MESSAGE_: "List of data and diploma files",
    STUDENT: "Student",
    NIN: "The national identifier",
    TYPE: "Type of diploma",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    AVERAGE: "Mark",
    APPROVAL_DATE: "Approval date",
    SIGNATURE_DATE: "Signature date",
    EDIT: "Update",
    DELETE: "Delete",
  },
  fr: {
    MESSAGE_TOAST: "Opération réussie!",
    MESSAGE_: "Liste des données et fichiers de diplomes",
    STUDENT: "Étudiant",
    NIN: "L'identifiant national",
    TYPE: "Type de diplome",
    SPECIALITY: "Spécialité",
    HONORS: "Note",
    AVERAGE: "Moyenne",
    APPROVAL_DATE: "Date d'approbation",
    SIGNATURE_DATE: "Date de la signature",
    EDIT: "Mise à jour",
    DELETE: "Supprimer",
  },
};
function AddDiplomasTable({ setOpenModal, setKey, setDiplomaToDelete }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const updateDiploma = useSelector((state) => state.diploma.updateDiploma);
  const diploma = useSelector((state) => state.diploma);
  const file = useSelector((state) => state.diploma.files);
  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState("");
  const mode = useSelector((state) => state.diploma.modeSelected);
  let data = [];
  if (mode === "modeOne") {
    for (let index = 0; index < diploma?.diplomasToAdd.length; index++) {
      const diplomaData = {
        id: diploma?.diplomasToAdd[index].nin,
      };
      const metadata = diploma?.diplomasToAdd[index];
      let pdfFileName = null;
      for (let index = 0; index < file?.length; index++) {
        if (diplomaData.id === file[index].fileName) {
          pdfFileName = file[index];
        }
      }
      const newData = {
        metadata,
        pdf: pdfFileName,
      };
      data.push(newData);
    }
  } else if (mode === "modeTwo") {
    for (let index = 0; index < diploma?.diplomasToAddTwo.length; index++) {
      const metadata = diploma?.diplomasToAddTwo[index];
      const newData = {
        metadata,
      };
      data.push(newData);
    }
  } else {
    for (let index = 0; index < diploma?.diplomasToAddThree.length; index++) {
      const metadata = diploma?.diplomasToAddThree[index];
      const newData = {
        metadata,
      };
      data.push(newData);
    }
  }

  const openModal = (key, diploma) => {
    setOpenModal((prev) => !prev);
    setKey(key);
    setDiplomaToDelete(diploma);
  };

  const fileOpen = (filename) => {
    setOpen(!open);
    setPdf(filename);
  };

  const checkPdfLength = () => {
    if (
      file?.length > 0 &&
      diploma?.diplomasToAdd.length > 0 &&
      updateDiploma
    ) {
      if (file?.length === diploma?.diplomasToAdd.length - 1) {
        toastSucces(`${getTranslation(translate, "MESSAGE_TOAST")}`);
      }
    }
  };
  useEffect(() => {
    checkPdfLength();
  }, []);
  return (
    <div className="flex flex-col justify-start ">
      <h1 className="p-4 text-md font-semibold text-[#25396f]">
        {getTranslation(translate, "MESSAGE_")}
      </h1>

      <div className="relative p-2 overflow-x-auto  sm:rounded-lg h-[auto]  max-h-[calc(100vh-700px)] overflow-y-auto  min-h-[240px]">
        <table className="w-full text-sm text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-blue-900 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "STUDENT")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "NIN")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "TYPE")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "SPECIALITY")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "HONORS")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "APPROVAL_DATE")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "SIGNATURE_DATE")}
              </th>
              {file?.length == 0 && Object.keys(file?.length - 1) == 0 ? (
                <th scope="col" className="px-6 py-3"></th>
              ) : (
                <th scope="col" className="px-6 py-3">
                  PDF
                </th>
              )}
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "EDIT")}
              </th>
            </tr>
          </thead>

          <tbody className="overflow-x-hidden h-10vh">
            {data &&
              data
                .map((diploma, key) => {
                  return (
                    <tr
                      key={key}
                      className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                        (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
                      }`}
                    >
                      <TruncatedCell
                        content={
                          diploma?.metadata.firstName +
                          " " +
                          diploma?.metadata.lastName
                        }
                      />

                      <TruncatedCell content={diploma?.metadata.nin} />
                      <TruncatedCell content={diploma?.metadata.type} />
                      <TruncatedCell content={diploma?.metadata.speciality} />
                      <TruncatedCell content={diploma?.metadata.honors} />
                      <TruncatedCell content={diploma?.metadata.approvalDate} />
                      <TruncatedCell
                        content={diploma?.metadata.signatureDate}
                      />
                      <TruncatedCell
                        content={
                          diploma?.pdf && diploma?.pdf !== undefined ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 cursor-pointer"
                              onClick={() => fileOpen(diploma?.pdf?.file)}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                              />
                            </svg>
                          ) : (
                            <span title="PDF"></span>
                          )
                        }
                      />

                      <td className="flex items-center justify-center py-2 ">
                        <button
                          title={getTranslation(translate, "EDIT")}
                          onClick={() => {
                            dispatch(
                              SELECT_DIPLOMA({
                                currentDiploma: {
                                  ...diploma?.metadata,
                                  key,
                                  updateDiploma: false,
                                },
                              })
                            );

                            navigate("/update_one_diploma");
                          }}
                          className="p-1 mx-1 text-xs flex items-center gap-1 font-bold text-white bg-[#0dcaf0] rounded w-fi h-fit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>

                        <button
                          title={getTranslation(translate, "DELETE")}
                          onClick={() => {
                            openModal(key, diploma);
                          }}
                          className="p-1 mx-1 text-xs flex gap-1 items-center font-bold text-white bg-[#dc3545] rounded w-fi h-fit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  );
                })
                .filter((student, key) => {
                  return key !== 0;
                })}
          </tbody>
        </table>
        {open ? <DiplomaPdfModal pdf={pdf} setOpen={setOpen} /> : null}
      </div>
    </div>
  );
}
AddDiplomasTable.propTypes = {
  isLoadingData: PropTypes.bool,
  setOpenModal: PropTypes.func,
  setKey: PropTypes.func,
  setDiplomaToDelete: PropTypes.func,
};
export default AddDiplomasTable;
