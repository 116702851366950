import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
export default function Logo({ src, alt }) {
  return (
    <div className="max-w-[200px] lg:hidden xl:block">
      <Link to="/">
        <img src={src} alt={alt} className="logo-svg" />
      </Link>
    </div>
  );
}

// Define prop types
Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
