import React from "react";

import PropTypes from "prop-types";
export default function SectionBody({
  sectionImg,
  sectionImgAlt,
  SectionTitle,
  ParagraphsList,
  itemIcon,
  reverseItem,
  iconStyle,
}) {
  return (
    <div
      className="max-w-[540px]  m-auto md:grid md:grid-cols-2 md:gap-2 md:items-center md:max-w-[100%]"
      data-aos={reverseItem ? "fade-right" : "fade-left"}
      data-aos-delay="50"
      data-aos-duration="1200"
      data-aos-once="true"
      data-aos-mirror="false"
    >
      <div className={`${reverseItem ? "order-2" : ""}`}>
        <img src={sectionImg} alt={sectionImgAlt} className="mt-8" />
      </div>
      <div>
        <h3 className="text-[#010483] font-semibold text-3xl my-4">
          {SectionTitle}
        </h3>
        {ParagraphsList.map((item, index) => (
          <p className="flex items-start my-3  gap-3" key={index}>
            <span className={iconStyle}>{itemIcon} </span> {item}
          </p>
        ))}
      </div>
    </div>
  );
}
SectionBody.propTypes = {
  sectionImg: PropTypes.string.isRequired,
  itemIcon: PropTypes.string.isRequired,
  reverseItem: PropTypes.bool.isRequired,
  iconStyle: PropTypes.string.isRequired,
  SectionTitle: PropTypes.string.isRequired,
  sectionImgAlt: PropTypes.string.isRequired,
  ParagraphsList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
