import React from "react";
import SectionBody from "../section-body/SectionBody";
import detailImg from "../../../assets/00details-1.png";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    sectionTitle: "What is Blockchain Technology?",
    Paragraph1: `Blockchain is a technology that forms the infrastructure for digital currencies distributed across the world, like Bitcoin. And others, which is a database spread across a number of servers and computers, and is characterized by its ability to manage an ever-growing list Stream from records named "Blocks".`,
    Paragraph2: `Each block contains the timestamp and a link to the previous block, forming a string of data. Designed serial Blocks so that they can keep the data stored there and prevent it from being changed, that is, when you store information in The blockchain cannot later modify or falsify this information, which offers a high degree of security.`,
    Paragraph3: `These features make it possible to use the blockchain to record events, addresses, medical records, etc. Activity management logs, identity management, transaction processing and verification of sources.`,
  },
  ar: {
    sectionTitle: "ماهي تقنية البلوكتشين",
    Paragraph1: ` «بلوكتشين» Blockchain هي تقنية تشكل البنية التحتية للعملات الرقمية المنتشرة حول العالم مثل «بتكوين» وغيرها، وهي عبارة عن قاعدة بيانات موزعة على عدد من الخوادم والحواسيب تمتاز بقدرتها على إدارة قائمة متزايدة باستمرار من السجلات المسماة كُتلا "Blocks". `,
    Paragraph2: ` تحتوي كل كتلة على الطابع الزمني ورابط إلى الكتلة السابقة، لتشكل سلسلة متصلة Chain من البيانات. صُممت سلسلة الكتل بحيث يمكنها المحافظة على البيانات المخزنة بها والحيلولة دون تعديلها، أي أنه عندما تخزن معلومة ما في سلسلة الكتل لا يمكن لاحقاً القيام بتعديل أو تزييف هذه المعلومة وهو ما يوفّر درجة عالية من الآمان. `,
    Paragraph3: ` وبالتالي تسمح هذه الميزات باستخدام سلسلة الكتل في تسجيل الأحداث والعناوين والسجلات الطبية وسائر ذلك من سجلات إدارة الأنشطة وإدارة الهوية ومعالجة المعاملات والتحقق من مصدرها. `,
  },
  fr: {
    sectionTitle: "Qu'est-ce que la technologie Blockchain?",
    Paragraph1: `La blockchain est une technologie qui forme l'infrastructure des monnaies numériques distribuées à travers le monde, comme le Bitcoin. Et d'autres, qui est une base de données répartie sur un certain nombre de serveurs et d'ordinateurs, et se caractérise par sa capacité à gérer une liste en constante augmentation Flux d'enregistrements nommés "Blocks".`,
    Paragraph2: `Chaque bloc contient le timestamp et un lien vers le bloc précédent, formant une chaîne de données. Conçu en série Blocs de sorte qu'ils peuvent conserver les données stockées là-bas et les empêcher d'être modifiées, c'est-à-dire lorsque vous stockez des informations dans La blockchain ne peut pas modifier ou falsifier ces informations plus tard, ce qui offre un degré élevé de sécurité.`,
    Paragraph3: `Ces fonctionnalités permettent d'utiliser la blockchain pour enregistrer des événements, des adresses, des dossiers médicaux, etc. Journaux de gestion des activités, gestion de l'identité, traitement des transactions et vérification des sources.`,
  },
};
export default function FourthSection() {
  return (
    <div id="blockchain" className="p-2 py-12 pt-24 bg-white">
      <div className="container mx-auto">
        <SectionBody
          sectionImg={detailImg}
          sectionImgAlt={"section 1"}
          SectionTitle={getTranslation(translate, "sectionTitle")}
          ParagraphsList={[
            getTranslation(translate, "Paragraph1"),

            getTranslation(translate, "Paragraph2"),
            getTranslation(translate, "Paragraph3"),
          ]}
        />
      </div>
    </div>
  );
}
