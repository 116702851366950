import React from "react";
import PropTypes from "prop-types";
import ShowResponsableInfo from "../../components/modal/university/ShowResponsableInfo";
import ChangeResponsable from "../../components/modal/university/ChangeResponsable";
import UpdateUniversity from "../../components/modal/university/UpdateUniversity";
import AssignExistingResponsable from "../../components/modal/university/AssignExistingResponsable";
const Model = ({
  showModal,
  onClose,
  universityInfo,
  modalType,
  reloadData,
  setModalType,
}) => {
  if (!showModal) return null; // If showModal is false, don't display the modal.

  return (
    <>
      {/* Background overlay with blur */}
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm z-40"
        onClick={onClose}
      ></div>

      {/* Modal content */}
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg max-w-md w-full relative transition duration-300 ease-in-out">
          {modalType === "updateUniversity" && (
            <UpdateUniversity
              onClose={onClose}
              universityInfo={universityInfo}
              reloadData={() => {
                reloadData(), console.log("reloadData() called in modal");
              }}
            />
          )}

          {modalType === "showResponsableInfo" && (
            <ShowResponsableInfo
              onClose={onClose}
              setModalType={setModalType}
              universityInfo={universityInfo?.university}
            />
          )}

          {modalType === "changeResponsable" && (
            <ChangeResponsable
              onClose={onClose}
              universityInfo={universityInfo}
              reloadData={reloadData}
            />
          )}
          {modalType === "assignExistingResponsable" && (
            <AssignExistingResponsable
              onClose={onClose}
              universityInfo={universityInfo}
              reloadData={reloadData}
            />
          )}
        </div>
      </div>
    </>
  );
};
Model.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  universityInfo: PropTypes.object,
  modalType: PropTypes.string,
  reloadData: PropTypes.func,
  setModalType: PropTypes.func,
};
export default Model;
