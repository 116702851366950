import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import { getTranslation } from "../../../utils/translate/translation";
import Spinner from "../../spinner/Spinner";
import {
  useGetAllBlockedUsersQuery,
  useAssignBlockedResponsableMutation,
} from "../../../store/service/usersService";
import { toast } from "react-toastify";

export default function AssignExistingResponsable({
  onClose,
  universityInfo,
  reloadData,
}) {
  const translation = {
    en: {
      MODAL_TITLE: "Assign Existing Responsable",
      SUCCESS_MESSAGE: "responsable assigned successfully",
    },
    ar: {
      MODAL_TITLE: "تعيين مسؤول موجود",
      SUCCESS_MESSAGE: "تم تعيين المسؤول بنجاح",
    },
    fr: {
      MODAL_TITLE: "Assigner un responsable existant",
      SUCCESS_MESSAGE: "responsable assigné avec succès",
    },
  };
  const { data, error, isLoading, refetch } = useGetAllBlockedUsersQuery();
  const [assignBlockedResponsable] = useAssignBlockedResponsableMutation();
  const [assignProsessing, setAssignProsessing] = useState(false);
  const AssignExisteResponsable = (university, selectedResponsable) => {
    setAssignProsessing(true);
    assignBlockedResponsable({
      universityId: university.university?._id,
      responsableId: selectedResponsable._id,
    })
      .unwrap()
      .then(() => {
        setAssignProsessing(false);
        toast.success(getTranslation(translation, "SUCCESS_MESSAGE"));
        refetch();
        onClose();
        reloadData();
      });
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Modal Header */}
      <div className="flex items-center justify-between bg-[#285090] text-white p-2">
        <h2 className="text-xl font-semibold">
          {getTranslation(translation, "MODAL_TITLE")}
        </h2>
        <button
          onClick={() => {
            onClose();
          }}
          className="text-2xl hover:bg-red-500 hover:text-white p-2 rounded-sm transition duration-200 ease-in-out"
        >
          <IoMdClose />
        </button>
      </div>

      {/* Modal Body */}
      <div className="p-2 min-h-[200px]">
        {isLoading && <p className="text-center text-gray-500">Loading...</p>}
        {error && <p className="text-center text-red-500">Error: {error}</p>}
        {!isLoading && !error && (
          <div className="overflow-x-auto">
            {assignProsessing ? (
              <div className="flex items-center justify-center min-h-[150px]">
                <Spinner />
              </div>
            ) : (
              <table className="w-full border-collapse bg-white shadow-sm rounded-lg">
                <thead>
                  <tr className="bg-gray-200 text-left text-gray-700 font-medium">
                    <th className="py-2 px-2 border-b">Username</th>
                    <th className="py-2 px-2 border-b">Email</th>
                    <th className="py-2 px-2 border-b">Phone</th>
                    <th className="py-2 px-2 border-b">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data.map((user) => (
                    <tr
                      key={user._id}
                      className="hover:bg-gray-100 transition-colors"
                    >
                      <td className="py-3 px-2 border-b text-gray-800">
                        {user.username}
                      </td>
                      <td className="py-3 px-2 border-b text-gray-800">
                        {user.email}
                      </td>
                      <td className="py-3 px-2 border-b text-gray-800">
                        {user.numero}
                      </td>
                      <td className="py-3 px-2 border-b">
                        <button
                          className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600 transition duration-200"
                          onClick={() =>
                            AssignExisteResponsable(universityInfo, user)
                          }
                        >
                          Assign
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

AssignExistingResponsable.propTypes = {
  onClose: PropTypes.func.isRequired,
  universityInfo: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
};
