import React from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { CiNoWaitingSign } from "react-icons/ci";
import { getTranslation } from "../../utils/translate/translation";
import { Tooltip } from "@material-tailwind/react";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";
import Spinner from "../../components/spinner/Spinner";

const Model = ({
  showModal,
  onClose,
  AcceptAllChanges,
  RejectAllChanges,
  AcceptSpecificChange,
  RejectSpecificChange,
  selectedDemande,
  processingUpdateDemand,
}) => {
  if (!showModal) return null; // If showModal is false, don't display the modal.
  const translate = {
    ar: {
      STUDENT_INFORMATION_UPDATE_REQUEST: "طلب تحديث المعلومات الشخصية",
      PROPOSED_CHANGES: "التغييرات المقترحة:",
      FIELED: "حقل",
      OROGINAL_VALUE: "القيمة الأصلية",
      NEW_VALUE: "القيمة الجديدة",
      ACTION: "العملية",
      NO_CHANGES: "لا توجد تغييرات مقترحة.",
      ACCEPT_CHANGES: "قبول التغييرات",
      REJECT_CHANGES: "رفض التغييرات",
      ACCEPT_CHANGE: "قبول التغيير",
      REJECT_CHANGE: "رفض التغيير",
      EMPTY: "- فارغ -",
      THIS_MAY_TAKE_A_WHILE: "قد يستغرق هذا بعض الوقت",
    },
    fr: {
      STUDENT_INFORMATION_UPDATE_REQUEST:
        "Demande de mise à jour des informations personnelles",
      PROPOSED_CHANGES: "Changements proposés:",
      FIELED: "Champ",
      OROGINAL_VALUE: "Valeur originale",
      NEW_VALUE: "Nouvelle valeur",
      ACTION: "Action",
      NO_CHANGES: "Aucun changement proposé.",
      ACCEPT_CHANGES: "Accepter les changements",
      REJECT_CHANGES: "Rejeter les changements",
      ACCEPT_CHANGE: "Accepter le changement",
      REJECT_CHANGE: "Rejeter le changement",
      EMPTY: "- vide -",
      THIS_MAY_TAKE_A_WHILE: "Cela peut prendre un certain temps",
    },
    en: {
      STUDENT_INFORMATION_UPDATE_REQUEST: "Student Information Update Request",
      PROPOSED_CHANGES: "Proposed changes:",
      FIELED: "Field",
      OROGINAL_VALUE: "Original Value",
      NEW_VALUE: "New Value",
      ACTION: "Action",
      NO_CHANGES: "No changes proposed.",
      ACCEPT_CHANGES: "Accept changes",
      REJECT_CHANGES: "Reject changes",
      ACCEPT_CHANGE: "Accept change",
      REJECT_CHANGE: "Reject change",
      EMPTY: "- empty -",
      THIS_MAY_TAKE_A_WHILE: "This may take a while",
    },
  };
  const language = selectedDemande.language;
  const currentData = selectedDemande?.curentStudentData;
  const newData = selectedDemande?.studentUpdatedFields;

  // Function to compare and find differences between the original and new data
  const getDifferences = (original, updated) => {
    const changes = [];
    Object.keys(updated).forEach((key) => {
      if (updated[key] && original[key] !== updated[key]) {
        changes.push({
          field: key,
          originalValue: original[key],
          newValue: updated[key],
        });
      }
    });

    return changes;
  };

  const changes = getDifferences(currentData, newData);

  return (
    <>
      {/* Background overlay with blur */}
      <div
        className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm z-40"
        onClick={onClose}
      ></div>

      {/* Modal content */}
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg max-w-lg w-full relative transition duration-300 ease-in-out">
          <div className="bg-blue-800 flex items-center justify-between text-white font-bold py-2 p-3">
            <p>
              {getTranslation(translate, "STUDENT_INFORMATION_UPDATE_REQUEST")}
            </p>
            <button
              onClick={onClose}
              className="transition-all ease-in-out delay-50 hover:bg-red-600 p-2 rounded"
            >
              <IoMdClose />
            </button>
          </div>
          {processingUpdateDemand ? (
            <div className="flex items-center justify-center my-8">
              <Spinner />
              <p className="text-blue-800">
                {getTranslation(translate, "THIS_MAY_TAKE_A_WHILE")}
              </p>
            </div>
          ) : (
            <div className="px-4 py-3 overflow-x-scroll">
              {/* User info update request type */}
              <p className="font-bold text-lg mb-4">
                {getTranslation(translate, "PROPOSED_CHANGES")}
              </p>
              {changes.length > 0 ? (
                <table className="w-full mb-4 overflow-x-scroll">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="text-left p-2">
                        {getTranslation(translate, "FIELED")}
                      </th>
                      <th className="text-left p-2">
                        {getTranslation(translate, "OROGINAL_VALUE")}
                      </th>
                      <th className="text-left p-2">
                        {getTranslation(translate, "NEW_VALUE")}
                      </th>
                      <th className="text-left p-2">
                        {getTranslation(translate, "ACTION")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {changes.map((change, index) => (
                      <tr key={index} className="border-t">
                        <td className="p-2">{change?.field}</td>
                        <td className="p-2 text-red-500">
                          {multilanguageReader(change?.originalValue) ||
                            getTranslation(translate, "EMPTY")}
                        </td>
                        <td className="p-2 text-green-500">
                          {change?.newValue}
                        </td>
                        <td className="p-2">
                          <div className="flex gap-2">
                            <Tooltip
                              color="light"
                              content={getTranslation(
                                translate,
                                "ACCEPT_CHANGE"
                              )}
                              placement="left"
                            >
                              <button
                                onClick={() =>
                                  AcceptSpecificChange({
                                    field: change?.field,
                                    newValue: change?.newValue,
                                    language: language,
                                    idStudent: selectedDemande?.idStudent,
                                  })
                                }
                                className="bg-green-500 p-2 text-white rounded-md hover:bg-green-600"
                              >
                                <MdDone />
                              </button>
                            </Tooltip>
                            <Tooltip
                              color="light"
                              content={getTranslation(
                                translate,
                                "REJECT_CHANGE"
                              )}
                              placement="right"
                            >
                              <button
                                onClick={() =>
                                  RejectSpecificChange({
                                    field: change?.field,
                                    language: language,
                                    idStudent: selectedDemande?.idStudent,
                                  })
                                }
                                className="bg-red-500 p-2 text-white rounded-md hover:bg-red-600"
                              >
                                <CiNoWaitingSign />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-gray-500">
                  {getTranslation(translate, "NO_CHANGES")}
                </p>
              )}
              {/* Buttons to accept or reject all changes */}
              {changes.length > 0 && (
                <div className="flex justify-end items-center gap-2 mt-4">
                  <button
                    onClick={() =>
                      AcceptAllChanges({
                        studentUpdatedFields: newData,
                        language: language,
                        idStudent: selectedDemande?.idStudent,
                      })
                    }
                    className="bg-green-500 flex items-center text-white p-2 rounded-md hover:bg-green-700"
                  >
                    <MdDone className="mr-1" />
                    {getTranslation(translate, "ACCEPT_CHANGES")}
                  </button>
                  <button
                    onClick={() =>
                      RejectAllChanges({
                        idStudent: selectedDemande?.idStudent,
                      })
                    }
                    className="bg-red-500 flex items-center text-white p-2 rounded-md hover:bg-red-700"
                  >
                    <CiNoWaitingSign className="mr-1" />
                    {getTranslation(translate, "REJECT_CHANGES")}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Model.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  AcceptAllChanges: PropTypes.func,
  RejectAllChanges: PropTypes.func,
  AcceptSpecificChange: PropTypes.func,
  RejectSpecificChange: PropTypes.func,
  selectedDemande: PropTypes.object,
  processingUpdateDemand: PropTypes.bool,
};

export default Model;
