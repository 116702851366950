/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  universities: [],
};

export const universitySlice = createSlice({
  name: "university",
  initialState,
  reducers: {
    GET_UNIVERSITIES: (state, action) => {
      state.universities = action.payload.universities;
    },
  },
});

// Action creators are generated for each case reducer function
export const { GET_UNIVERSITIES } = universitySlice.actions;

export default universitySlice.reducer;
