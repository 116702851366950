/* eslint-disable no-undef */
import jwtDecode from "jwt-decode";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";

import { useUpdateUserMutation } from "../../store/service/userService";
import { useChangePasswordMutation } from "../../store/service/usersService";
import {
  mailIcon,
  mobileIcon,
  passwordIcon,
  userIcon,
} from "../../assets/svg/Index";
import SelectMode from "../../components/selectMode/SelectMode";
import { LOADING_USER } from "../../store/features/userSlice/userSlice";

import axios from "axios";

import DragAndDropFile from "../../components/input/DragAndDropFile";
import { toastSucces } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    TOAST_TOKEN_EXPIRED: "   فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً ",
    TOAST_ERROR_PASSWORD: "كلمة السر خاطئة ",
    TOAST_ERROR: "هنالك خطأ في السرفر ",
    TOAST_SUCCES_PASSWORD: "   تم تغيير كلمة السر",
    TOAST_SUCCES: "تمت العملية بنجاح",
    TOAST_USER_EXIST: "هذا المستخدم موجود ",
    PAGE_TITLE: "إعدادات الحساب",
    NAME: "الاسم الكامل",
    EMAIL: "البريد الالكتروني",
    PHONE: "الهاتف",
    BUTTON_TITLE: "حفظ التعديلات",
    PASSWORD_: "  تغيير كلمة السّر",
    PASSWORD_OLD: "كلمة السّر الحالية ",
    PASSWORD_NEW: " كلمة السّر الجديدة",
    PASSWORD_NEW_CONFIRM: " إعادة كلمة السّر ",
    MODE_SELECTED: " إختيار طريقة إنشاء الدبلومات",
    ADD_LOGO_SIGNATURE: " إضافة الامضاء و شارة",
    SIGNATURE: "الامضاء",
    LOGO: "شارة",

    PHONE_NUMBER_INPUT_PLACEHOLDER: "أدخل رقم هاتفك",
  },
  en: {
    TOAST_TOKEN_EXPIRED: "Your registration period has expired. Re-enter again",
    TOAST_ERROR_PASSWORD: "The password is wrong",
    TOAST_ERROR: "There is an error in the server",
    TOAST_SUCCES_PASSWORD: "Password has been changed",
    TOAST_SUCCES: "Operation accomplished successfully",
    TOAST_USER_EXIST: "This user exists",
    PAGE_TITLE: "Account settings",
    NAME: "Full Name",
    EMAIL: "Email",
    PHONE: "Phone",
    BUTTON_TITLE: "Save",
    PASSWORD_: "Change Password",
    PASSWORD_OLD: "The current password",
    PASSWORD_NEW: "New password ",
    PASSWORD_NEW_CONFIRM: "Confirm Password",
    MODE_SELECTED: "Choose the method of creating diplomas",
    ADD_LOGO_SIGNATURE: "Add signature and Logo",
    SIGNATURE: "Signature",
    LOGO: "Logo",
    PHONE_NUMBER_INPUT_PLACEHOLDER: "Enter your phone number",
  },
  fr: {
    TOAST_TOKEN_EXPIRED:
      "Votre période d'enregistrement a expiré. Entrez à nouveau",
    TOAST_ERROR_PASSWORD: "Le mot de passe est erroné",
    TOAST_ERROR: "Il y a une erreur dans le serveur",
    TOAST_SUCCES_PASSWORD: "Le mot de passe a été changé",
    TOAST_SUCCES: "Opération réussie",
    TOAST_USER_EXIST: "Cet utilisateur existe",
    PAGE_TITLE: "Paramètres du compte",
    NAME: " Le Nom complet",
    EMAIL: "Email",
    PHONE: "Téléphone",
    BUTTON_TITLE: "Enregistrer ",
    PASSWORD_: "Changer mot de passe",
    PASSWORD_OLD: "Le mot de passe actuel",
    PASSWORD_NEW: "Nouveau mot de passe",
    PASSWORD_NEW_CONFIRM: "Confirmez le mot de passe",
    MODE_SELECTED: " Choisissez la méthode de création des diplômes",
    ADD_LOGO_SIGNATURE: "Ajouter une signature et un logo",
    SIGNATURE: "signature",
    LOGO: "Logo",

    PHONE_NUMBER_INPUT_PLACEHOLDER: "Entrez votre numéro de téléphone",
  },
};
function SettingPage() {
  const user = useSelector((state) => state.user);
  let [changePassword] = useChangePasswordMutation();
  const [updateUser] = useUpdateUserMutation();
  const role = localStorage.getItem("role");
  const setIsNameValid = () => {
    return false;
  };
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [signature, setSignature] = useState(null);
  const [logo, setLogo] = useState(null);
  // const [logo, setLogo] = useState(null);
  const [numero, setNumero] = useState("");

  const setIsNumeroalid = () => {
    return false;
  };
  const setIsOldPasswordValid = () => {
    return false;
  };
  const [oldPassword, setOldPassword] = useState("");

  const [password, setPassword] = useState("");

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [passwordRe, setPasswordRe] = useState("");

  const setIsPasswordReValid = () => {
    return false;
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const ForgotPassword = () => {
    const data = {
      oldPassword,
      password,
      token: localStorage.getItem("token"),
    };
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      toast.error(`${getTranslation(translate, "TOAST_TOKEN_EXPIRED")}`, {
        position: "top-left",
        theme: "colored",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      changePassword(data).then((res) => {
        if (res.error) {
          if (res.error.status === 401) {
            toast.error(`${getTranslation(translate, "TOAST_ERROR_PASSWORD")}`);
          } else {
            toast.error(`${getTranslation(translate, "TOAST_ERROR")}`);
          }
        }
        if (res.data.message == "Password changed.") {
          setOldPassword("");
          setPassword("");
          setPasswordRe("");
          toast.info(`${getTranslation(translate, "TOAST_SUCCES_PASSWORD")}`, {
            position: "top-left",
            theme: "colored",
          });
        }
      });

      setTimeout(() => {
        setOldPassword("");
        setPassword("");
        setPasswordRe("");
      }, 2000);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      toast.error(`${getTranslation(translate, "TOAST_TOKEN_EXPIRED")}`, {
        position: "top-left",
        theme: "colored",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setUsername(user?.username ? user?.username : username);
      setEmail(user?.email ? user?.email : email);
      setNumero(user?.numero ? user?.numero : numero);
      setSignature(user?.signature ? user?.signature : signature);
    }
  }, [isLoading]);
  const dispatch = useDispatch();
  const _updateUser = () => {
    setIsLoading(true);
    updateUser({
      username,
      email,
      numero,
    }).then((res) => {
      if (res.data) {
        dispatch(
          LOADING_USER({
            ...user,
            username: username,
            email: email,
            numero: numero,
          })
        );
        toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
        setIsLoading(false);
      } else {
        if (res.error.status === 401) {
          toast.error(`${getTranslation(translate, "TOAST_USER_EXIST")}`);
        } else {
          toast.error(`${getTranslation(translate, "TOAST_ERROR")}`);
        }
      }
    });
  };

  const onSignatureChange = (event) => {
    if (event) {
      setSignature(URL.createObjectURL(event));
    }
    dispatch(LOADING_USER({ ...user, signature: URL.createObjectURL(event) }));
    let signature = new FormData();
    signature.append("signature", event);

    uploadSignature(signature);
  };

  const onLogoChange = (event) => {
    if (event) {
      setLogo(URL.createObjectURL(event));
    }
    dispatch(LOADING_USER({ ...user, logo: URL.createObjectURL(event) }));
    let logo = new FormData();
    logo.append("logo", event);
    uploadLogo(logo);
  };
  const uploadSignature = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${token}`,
      },
    };
    await axios
      .post(
        process.env.REACT_APP_URL + "/api/user/uploadSignature",
        formData,
        config
      )

      .then((res) => {
        if (res.data) {
          toast.info(`${getTranslation(translate, "TOAST_SUCCES")}`, {
            position: "top-left",
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(`${getTranslation(translate, "TOAST_ERROR")}`, {
            position: "top-left",
            theme: "colored",
          });
          setSignature(null);
        }
      });
  };

  const uploadLogo = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${token}`,
      },
    };
    await axios
      .post(
        process.env.REACT_APP_URL + "/api/user/uploadLogo",
        formData,
        config
      )
      .then((res) => {
        if (res.data) {
          toast.info(`${getTranslation(translate, "TOAST_SUCCES")}`, {
            position: "top-left",
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(`${getTranslation(translate, "TOAST_ERROR")}`, {
            position: "top-left",
            theme: "colored",
          });
          setSignature(null);
        }
      });
  };
  const remove = () => {
    dispatch(LOADING_USER({ ...user, signature: "" }));
    setIsLoading(isLoading);
  };
  const removeLogo = () => {
    dispatch(LOADING_USER({ ...user, logo: "" }));
    setIsLoading(isLoading);
  };

  return (
    <div className="flex flex-col w-full p-8">
      <h1 className="mb-2 text-2xl font-bold text-[#25396f] ">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {getTranslation(translate, "NAME")}
          </p>
          <Input
            icon={userIcon}
            type={"NO_CONTROL"}
            inputChange={setUsername}
            placeholder={user?.username}
            value={username}
            setValid={setIsNameValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "EMAIL")}
          </p>
          <Input
            icon={mailIcon}
            type={"EMAIL"}
            placeholder={user?.email}
            inputChange={setEmail}
            value={email}
            setValid={setEmailValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {getTranslation(translate, "PHONE")}
          </p>
          <Input
            icon={mobileIcon}
            type={"NUMBER"}
            placeholder={
              user?.numero
                ? user?.numero
                : getTranslation(translate, "PHONE_NUMBER_INPUT_PLACEHOLDER")
            }
            inputChange={setNumero}
            value={numero}
            setValid={setIsNumeroalid}
          />
        </div>
        <div className="flex justify-end w-full">
          <div className="w-40 ">
            <MainButton
              title={getTranslation(translate, "BUTTON_TITLE")}
              action={() => {
                _updateUser();
              }}
              isLoading={isLoading}
              disabled={username === "" || email === "" || !emailValid}
            />
          </div>
        </div>
      </div>
      <h1 className="mt-6 mb-2 text-2xl font-bold text-[#25396f] ">
        {getTranslation(translate, "PASSWORD_")}
      </h1>
      <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {getTranslation(translate, "PASSWORD_OLD")}
          </p>
          <Input
            icon={passwordIcon}
            type={"NO_CONTROL"}
            placeholder={getTranslation(translate, "PASSWORD_OLD")}
            password
            inputChange={setOldPassword}
            value={oldPassword}
            setValid={setIsOldPasswordValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {getTranslation(translate, "PASSWORD_NEW")}
          </p>
          <Input
            icon={passwordIcon}
            type="PASSWORD"
            placeholder={getTranslation(translate, "PASSWORD_NEW")}
            password
            inputChange={setPassword}
            value={password}
            setValid={setIsPasswordValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {getTranslation(translate, "PASSWORD_NEW_CONFIRM")}
          </p>
          <Input
            icon={passwordIcon}
            type="PASSWORD"
            placeholder={getTranslation(translate, "PASSWORD_NEW_CONFIRM")}
            password
            inputChange={setPasswordRe}
            value={passwordRe}
            setValid={setIsPasswordReValid}
          />
        </div>

        <div className="flex justify-end w-full">
          <div className="w-40 ">
            <MainButton
              title={getTranslation(translate, "BUTTON_TITLE")}
              action={ForgotPassword}
              disabled={
                !isPasswordValid ||
                (password === "" && passwordRe === "") ||
                password !== passwordRe
              }
              isLoad
            />
          </div>
        </div>
      </div>
      {role === "ROLE_RESP_UNI" ? (
        <>
          <h1 className="mt-6 mb-2 text-2xl font-bold text-[#25396f] ">
            {getTranslation(translate, "MODE_SELECTED")}
          </h1>
          <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
            <SelectMode />
          </div>
        </>
      ) : null}
      {role === "ROLE_RESP_UNI" ? (
        <>
          <h1 className="mt-6 mb-2 text-2xl font-bold text-[#25396f] ">
            {getTranslation(translate, "ADD_LOGO_SIGNATURE")}
          </h1>
          <div className="items-center justify-between w-full gap-5 p-8 pb-5 bg-white rounded ">
            <div className=" lg:h-[636.344px] w-full lg:flex items-center sm:grid  justify-between gap-5 ">
              <div className="relative border-2 w-[50%] h-full md:w-full sm:w-full flex items-center justify-center p-3">
                {user.signature || signature ? (
                  <div className="  p-5 w-[50%] sm:w-full h-full flex items-center">
                    <img
                      src={signature ? signature : user?.signature}
                      className="w-full h-fit"
                    />
                    <button
                      className="  absolute -left-4 -top-4 flex items-center justify-center w-10 h-10 text-blue-900 bg-[#c5c2c379] rounded-full"
                      onClick={remove}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-full h-full"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <DragAndDropFile
                    title={getTranslation(translate, "SIGNATURE")}
                    setSignature={setSignature}
                    signature={signature}
                    onImageChange={onSignatureChange}
                  />
                )}
              </div>

              <div className="relative border-2 w-[50%] h-full md:w-full sm:w-full  flex items-center justify-center p-3">
                {user.logo || logo ? (
                  <div className="flex items-center h-full p-5 sm:w-full">
                    <img
                      src={logo ? logo : user?.logo}
                      className="w-full h-fit"
                    />
                    <button
                      className=" absolute -left-4 -top-4 flex items-center justify-center w-10 h-10 text-blue-900 bg-[#c5c2c379] rounded-full"
                      onClick={removeLogo}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-full h-full"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <DragAndDropFile
                    title={getTranslation(translate, "LOGO")}
                    onImageChange={onLogoChange}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default SettingPage;
