import React from "react";
import Logo from "../../../assets/k2lis_logo.png";
import FooterSection from "./FooterSection";
import FooterList from "./FooterList";
import AlescoLogo from "../../../assets/alecso_logo.png";
import CopyRightSection from "./CopyRightSection";
import { IoIosArrowBack } from "react-icons/io";

import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    /*partner section*/
    partnerSectionTitle: "official partner",
    partnerDescription:
      " A company specializing in software development, technical consulting and training technological blockchain",
    partnerLocation:
      "Shatrana Industrial Zone 2, Al-Ghazala Technology Complex in Ariana Tunisia",
    partnerNumber: "number ",
    partnerEmail: "email",
    /* == end == partner section ==*/
    /*important Links list*/
    ImportantLinksListTitle: "Important links",
    ImportantLinkItem1: "The official website of ALECSO",
    ImportantLinkItem2: "K2lis website",
    ImportantLinkItem3: "Verification site diploma",
    /* navigation links */
    NavLinkListTitle: "The list",
    NavLinkItem1: "Major",
    NavLinkItem2: "Properties",
    NavLinkItem3: "Access to the platform",
    NavLinkItem4: "Blockchain",
    NavLinkItem5: "How it works",
    NavLinkItem6: "Goals",
    NavLinkItem7: "Contact us",
    /**/
    companyName: "ALECSO",
    companyDescription:
      "The Arab Educational, Cultural and Scientific Organization - ALECSO",
  },
  ar: {
    /*partner section*/
    partnerSectionTitle: "الشريك الرسمي",
    partnerDescription:
      "شركة متخصصة في تطوير البرمجيات والاستشارات التقنية وتدريب تكنولوجيا سلسلة الكتل",
    partnerLocation:
      "منطقة شطرانة الصناعية 2، المجمع التكنولوجي الغزالة في أريانة تونس",
    partnerNumber: "رقم",
    partnerEmail: "البريد الإلكتروني",
    /* == end == partner section ==*/
    /*important Links list*/
    ImportantLinksListTitle: "الروابط المهمة",
    ImportantLinkItem1: "الموقع الرسمي للألكسو",
    ImportantLinkItem2: "موقع K2lis",
    ImportantLinkItem3: "موقع التحقق من الشهادة",
    /* navigation links */
    NavLinkListTitle: "القائمة",
    NavLinkItem1: "رئيسي",
    NavLinkItem2: "خصائص",
    NavLinkItem3: "الوصول إلى المنصة",
    NavLinkItem4: "سلسلة كتل",
    NavLinkItem5: "كيف يعمل",
    NavLinkItem6: "أهداف",
    NavLinkItem7: "اتصل بنا",
    /**/
    companyName: "الألكسو",
    companyDescription: "المنظمة العربية للتربية والثقافة والعلوم - الألكسو ",
  },
  fr: {
    /*partner section ==*/
    partnerSectionTitle: "partenaire officiel",
    partnerDescription:
      "Une entreprise spécialisée dans le développement de logiciels, le conseil technique et la formation à la technologie blockchain",
    partnerLocation:
      "Zone industrielle de Shatrana 2, Complexe technologique Al-Ghazala à Ariana Tunisie",
    partnerNumber: "Numéro ",
    partnerEmail: "email",
    /* == end == partner section ==*/
    /*important Links list*/
    ImportantLinksListTitle: "Liens importants ",
    ImportantLinkItem1: "Le site officiel de l'ALECSO",
    ImportantLinkItem2: "Site web de K2lis",
    ImportantLinkItem3: "Site de vérification de diplôme",
    /* navigation links */
    NavLinkListTitle: "La liste",
    NavLinkItem1: "Principal",
    NavLinkItem2: "Propriétés",
    NavLinkItem3: "Accès à la plateforme",
    NavLinkItem4: "Blockchain",
    NavLinkItem5: "Comment ça marche",
    NavLinkItem6: "Objectifs",
    NavLinkItem7: "Contactez-nous",

    /**/
    companyName: "ALECSO",
    companyDescription:
      "L'Organisation arabe pour l'éducation, la culture et les sciences - ALECSO ",
  },
};
export default function Footer() {
  return (
    <div className="bg-[#010351] text-white p-4 lg:p-10 lg:py-14">
      <div className="grid gap-2 lg:grid-cols-2 container mx-auto">
        <div className="grid gap-4 md:grid-cols-2">
          <div className=" border-t-4 border-t-[#1acc8d] bg-[#010246] my-8 p-4 lg:my-0">
            <div className="p-4">
              <h1 className="text-center text-2xl font-bold">
                {getTranslation(translate, "partnerSectionTitle")}
              </h1>
            </div>
            <div className="flex justify-center items-center my-2">
              <img src={Logo} alt="logo" className="max-w-[200px]" />
            </div>
            <div>
              <p className="text-sm my-4">
                {getTranslation(translate, "partnerDescription")}
              </p>
              <p className="text-sm my-4">
                {getTranslation(translate, "partnerLocation")}
              </p>
              <div>
                <p>
                  <span className="font-bold">
                    {" "}
                    {getTranslation(translate, "partnerNumber")} :{" "}
                  </span>
                  +216 23 33 14 14
                </p>
                <p>
                  <span className="font-bold">
                    {" "}
                    {getTranslation(translate, "partnerEmail")} :{" "}
                  </span>
                  tarek.kamoun@k2lis.com
                </p>
              </div>
            </div>
          </div>

          <FooterList
            ListTitle={getTranslation(translate, "NavLinkListTitle")}
            ListItems={[
              {
                text: getTranslation(translate, "NavLinkItem1"),
                Link: "#hero",
                type: "hash",
              },
              {
                text: getTranslation(translate, "NavLinkItem2"),
                Link: "#criteria",
                type: "hash",
              },
              {
                text: getTranslation(translate, "NavLinkItem3"),
                Link: "#login",
                type: "hash",
              },
              {
                text: getTranslation(translate, "NavLinkItem4"),
                Link: "#blockchain",
                type: "hash",
              },
              {
                text: getTranslation(translate, "NavLinkItem5"),
                Link: "#howto",
                type: "hash",
              },
              {
                text: getTranslation(translate, "NavLinkItem6"),
                Link: "#goals",
                type: "hash",
              },
              {
                text: getTranslation(translate, "NavLinkItem7"),
                Link: "#hero",
                type: "hash",
              },
            ]}
          />
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <FooterList
            ListTitle={getTranslation(translate, "ImportantLinksListTitle")}
            itemIcon={<IoIosArrowBack className="text-[#1acc8d] font-black" />}
            ListItems={[
              {
                text: getTranslation(translate, "ImportantLinkItem1"),
                Link: "https://alecso.org/",
                type: "external",
              },
              {
                text: getTranslation(translate, "ImportantLinkItem2"),
                Link: "https://k2lis.com/en/",
                type: "external",
              },
              {
                text: getTranslation(translate, "ImportantLinkItem3"),
                Link: "https://check-cert.alecso.org/",
                type: "external",
              },
            ]}
          />
          <FooterSection
            title={getTranslation(translate, "companyDescription")}
            companyLogo={AlescoLogo}
            companyName={getTranslation(translate, "companyName")}
          />
        </div>
      </div>
      <CopyRightSection
        companyName={getTranslation(translate, "companyName")}
      />
    </div>
  );
}
