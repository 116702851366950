import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SELECTED_MODE } from "../../store/features/diplomasSlice/diplomasSlice";
import { getTranslation } from "../../utils/translate/translation";

import AddDiplomasPage from "./AddDiplomasPage";
import AddDiplomasWithExcel from "./AddDiplomasWithExcel";
import DiplomasDigital from "./DiplomasDigital";

const translate = {
  ar: {
    MODE_ONE: "نمط الأول",
    MODE_ONE_DESC: "( رفع ملف اكسل مرفق بملفات pdfs)",
    MODE_TWO: "نمط الثاني",
    MODE_TWO_DESC: "(رفع ملف اكسل)",
    MODE_THREE: "  نمط الثالث",
    MODE_THREE_DESC: "(شهادة رقمية)",
    NO_MODE: "لم يتم تحديد أي نوع للإضافة",
    PAGE_TITLE: "اختر نوع الإضافة ",
  },
  en: {
    MODE_ONE: "Mode One",
    MODE_ONE_DESC: "(Upload an Excel file with attached PDFs)",
    MODE_TWO: "Mode Two",
    MODE_TWO_DESC: "(Upload an Excel file)",
    MODE_THREE: "Mode Three",
    MODE_THREE_DESC: "(Digital Certificate)",
    NO_MODE: "No plugin type specified",
    PAGE_TITLE: "Choose the type of extension",
  },
  fr: {
    MODE_ONE: "Mode Un",
    MODE_ONE_DESC: "(Téléchargez un fichier Excel avec des PDFs attachés)",
    MODE_TWO: "Mode Deux",
    MODE_TWO_DESC: "(Téléchargez un fichier Excel)",
    MODE_THREE: "Mode Trois",
    MODE_THREE_DESC: "(Certificat numérique)",
    NO_MODE: "Aucun type de plug-in spécifié",
    PAGE_TITLE: "Choisissez le type d'extension",
  },
};
function AddDiplomasMain() {
  const mode = useSelector((state) => state?.user?.mode);
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const modeSelected = useSelector((state) => state?.diploma?.modeSelected);

  useEffect(() => {
    const checkMode = () => {
      const modes = mode && Object.keys(mode);
      const selectedMode = modes?.find((m) => mode[m] === true);
      const selectedIndex = menuOptions?.findIndex(
        (option) => option?.mode === selectedMode
      );
      setSelectedMenu(selectedIndex + 1);
      dispatch(SELECTED_MODE({ modeSelected: selectedMode || null }));
    };

    if (modeSelected === null) {
      checkMode();
    }
  }, [mode, modeSelected, dispatch]);

  const checkMenu = (menuId, mode) => {
    setSelectedMenu(menuId);
    dispatch(SELECTED_MODE({ modeSelected: mode }));
  };

  const ModeSelect = () => {
    switch (modeSelected) {
      case "modeOne":
        return (
          <AddDiplomasPage selectedMenu={1} setSelectedMenu={setSelectedMenu} />
        );
      case "modeTwo":
        return (
          <AddDiplomasWithExcel
            selectedMenu={2}
            setSelectedMenu={setSelectedMenu}
          />
        );
      case "modeThree":
        return (
          <DiplomasDigital selectedMenu={3} setSelectedMenu={setSelectedMenu} />
        );
      default:
        return (
          <div className="p-[3%]">
            <h1>{getTranslation(translate, "NO_MODE")}</h1>
          </div>
        );
    }
  };

  const menuOptions = [
    {
      id: 1,
      text: `${getTranslation(translate, "MODE_ONE")}`,
      description: `${getTranslation(translate, "MODE_ONE_DESC")}`,
      mode: "modeOne",
    },
    {
      id: 2,
      text: `${getTranslation(translate, "MODE_TWO")}`,
      description: `${getTranslation(translate, "MODE_TWO_DESC")}`,
      mode: "modeTwo",
    },
    {
      id: 3,
      text: `${getTranslation(translate, "MODE_THREE")}`,
      description: `${getTranslation(translate, "MODE_THREE_DESC")}`,
      mode: "modeThree",
    },
  ];
  return (
    <>
      <div className="flex flex-col justify-end mt-10 px-[3%] ">
        <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
          {getTranslation(translate, "PAGE_TITLE")}
        </h1>
        <div className="flex flex-col items-center justify-center w-full p-1 bg-white rounded h-fit">
          <div className="flex justify-between w-full gap-2 ">
            {menuOptions &&
              menuOptions?.map((option) =>
                mode && mode[option?.mode] ? (
                  <div
                    key={option?.id}
                    onClick={() => checkMenu(option.id, option?.mode)}
                    className={`cursor-pointer w-1/3 p-2  text-blue-gray-800 text-lg font-[500]  rounded-sm text-center hover:bg-blue-50 ${
                      selectedMenu === option?.id &&
                      mode[option?.mode] &&
                      `border-b-2 border-b-blue-900 bg-blue-100`
                    }`}
                  >
                    <p>{option?.text}</p>
                    <span>{option?.description}</span>
                  </div>
                ) : (
                  <button
                    key={option?.id}
                    disabled
                    className="w-72 p-2  text-blue-gray-800 text-lg font-[500] bg-blue-gray-100 rounded-sm text-center"
                  >
                    {option?.text}
                  </button>
                )
              )}
          </div>
        </div>
      </div>
      <ModeSelect />
    </>
  );
}

export default AddDiplomasMain;
