import React from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../utils/translate/translation";
import "/node_modules/flag-icons/css/flag-icons.min.css";
// ISO 3166-1 alpha-2 country codes
export const countryCodes = {
  PALESTINE: "ps",
  UNITED_ARAB_EMIRATES: "ae",
  BAHRAIN: "bh",
  KUWAIT: "kw",
  SAUDI_ARABIA: "sa",
  YEMEN: "ye",
  QATAR: "qa",
  OMAN: "om",
  JORDAN: "jo",
  SYRIA: "sy",
  LEBANON: "lb",
  IRAQ: "iq",
  EGYPT: "eg",
  MOROCCO: "ma",
  LIBYA: "ly",
  TUNISIA: "tn",
  ALGERIA: "dz",
  SUDAN: "sd",
  MAURITANIA: "mr",
  DJIBOUTI: "dj",
  SOMALIA: "so",
};
const translate = {
  ar: {
    PALESTINE: "دولة فلسطين",
    UNITED_ARAB_EMIRATES: "الإمارات العربية المتحدة",
    BAHRAIN: "البحرين",
    KUWAIT: "الكويت",
    SAUDI_ARABIA: "السعودية",
    YEMEN: "اليمن",
    QATAR: "قطر",
    OMAN: "سلطنة عمان",
    JORDAN: "الأردن",
    SYRIA: "سوريا",
    LEBANON: "لبنان",
    IRAQ: "العراق",
    EGYPT: "مصر",
    MOROCCO: "المغرب",
    LIBYA: "ليبيا",
    TUNISIA: "تونس",
    ALGERIA: "الجزائر",
    SUDAN: "السودان",
    MAURITANIA: "موريتانيا",
    DJIBOUTI: "جيبوتي",
    SOMALIA: "الصومال",
    NUMBER_OF_UNIVERSITIES_BY_COUNTRY: " عدد الجامعات حسب البلد ",
  },
  en: {
    PALESTINE: "Palestine",
    UNITED_ARAB_EMIRATES: "United Arab Emirates",
    BAHRAIN: "Bahrain",
    KUWAIT: "Kuwait",
    SAUDI_ARABIA: "Saudi Arabia",
    YEMEN: "Yemen",
    QATAR: "Qatar",
    OMAN: "Oman",
    JORDAN: "Jordan",
    SYRIA: "Syria",
    LEBANON: "Lebanon",
    IRAQ: "Iraq",
    EGYPT: "Egypt",
    MOROCCO: "Morocco",
    LIBYA: "Libya",
    TUNISIA: "Tunisia",
    ALGERIA: "Algeria",
    SUDAN: "Sudan",
    MAURITANIA: "Mauritania",
    DJIBOUTI: "Djibouti",
    SOMALIA: "Somalia",
    NUMBER_OF_UNIVERSITIES_BY_COUNTRY: "Number of universities by country",
  },
  fr: {
    PALESTINE: "Palestine",
    UNITED_ARAB_EMIRATES: "Émirats arabes unis",
    BAHRAIN: "Bahreïn",
    KUWAIT: "Koweït",
    SAUDI_ARABIA: "Arabie saoudite",
    YEMEN: "Yémen",
    QATAR: "Qatar",
    OMAN: "Oman",
    JORDAN: "Jordanie",
    SYRIA: "Syrie",
    LEBANON: "Liban",
    IRAQ: "Irak",
    EGYPT: "Égypte",
    MOROCCO: "Maroc",
    LIBYA: "Libye",
    TUNISIA: "Tunisie",
    ALGERIA: "Algérie",
    SUDAN: "Soudan",
    MAURITANIA: "Mauritanie",
    DJIBOUTI: "Djibouti",
    SOMALIA: "Somalie",
    NUMBER_OF_UNIVERSITIES_BY_COUNTRY: "Nombre d'universités par pays",
  },
};
const CountryNumber = ({ country }) => {
  return (
    <div>
      <div className="w-full font-bold text-white pt-2 text-1xl bg-[#285090] rounded-t-md px-3 py-1.5">
        {getTranslation(translate, "NUMBER_OF_UNIVERSITIES_BY_COUNTRY")}
      </div>
      <div className="grid gap-6 p-4" data-testid="country">
        {country?.map(([countryName, numberOfUniversities]) => {
          const countryCode = countryCodes[countryName];
          return (
            <div className="flex items-center" key={countryName}>
              {countryCode && (
                <span
                  className={`fi fi-${countryCode} mr-2`}
                  title={getTranslation(translate, countryName)}
                ></span>
              )}
              <span className="font-bold text-black">
                {getTranslation(translate, countryName)} :{" "}
                <span>{numberOfUniversities}</span>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CountryNumber.propTypes = {
  country: PropTypes.array.isRequired,
};

export default CountryNumber;
