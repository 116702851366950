import React, { useState } from "react";
import PropTypes from "prop-types";

import { getTranslation } from "../../../utils/translate/translation";
import { IoMdClose } from "react-icons/io";

export default function ShowResponsableInfo({
  universityInfo,
  onClose,
  setModalType,
}) {
  const translation = {
    en: {
      MODAL_TITLE: "responsible informations",
      RESPONSABLE_USERNAME: "Username",
      RESPONSABLE_FIRSTNAME: "First Name",
      RESPONSABLE_LASTNAME: "Last Name",
      RESPONSABLE_EMAIL: "Email",
      RESPONSABLE_TELEPHONE: "Telephone",
      RESPONSABLE_COUNTRY: "Country",
      RESPONSABLE_IS_BLOCKED: "Is Blocked",
      RESPONSABLE_INFO_NOT_FOUND: "No responsible information found",
      RESPONSABLE_ACCOUNT_STATUS: "Account Status",
      ACCOUNT_BLOCKED: "Blocked",
      ACCOUNT_ACTIVE: "Active",

      /* update university modal translate */
      UPDATE_UNIVERSITY_MODAL_TITLE: "Update University",
      UNIVERSITY_NAME: "University Name",
      UNIVERSITY_COUNTRY: "Country",
      UNIVERSITY_CREDIT: "Credit",

      /* change responsable modal translate */
      CHANGE_RESPONSABLE_MODAL_TITLE: "Change Responsable",
      CHANGE_RESPONSABLE_BUTTON: "Change Responsable",
      ASSIGN_New_RESPONSABLE: "Assign New Responsable",
      CREATE_NEW_RESPONSABLE: "Create New Responsable",
      ASSIGNT_EXISTING_RESPONSABLE: "Assign Existing Responsable",
    },
    ar: {
      MODAL_TITLE: "معلومات المسؤول",
      RESPONSABLE_USERNAME: "اسم المستخدم",
      RESPONSABLE_FIRSTNAME: "الاسم الأول",
      RESPONSABLE_LASTNAME: "الكنية",
      RESPONSABLE_EMAIL: "البريد الإلكتروني",
      RESPONSABLE_TELEPHONE: "الهاتف",
      RESPONSABLE_COUNTRY: "البلد",
      RESPONSABLE_IS_BLOCKED: "محظور",
      RESPONSABLE_INFO_NOT_FOUND: "لم يتم العثور على معلومات المسؤول",
      RESPONSABLE_ACCOUNT_STATUS: "حالة الحساب",
      ACCOUNT_BLOCKED: "محظور",
      ACCOUNT_ACTIVE: "نشط",
      /* update university modal translate */
      UPDATE_UNIVERSITY_MODAL_TITLE: "تحديث الجامعة",
      UNIVERSITY_NAME: "اسم الجامعة",
      UNIVERSITY_COUNTRY: "البلد",
      UNIVERSITY_CREDIT: "الرصيد",

      /* change responsable modal translate */
      CHANGE_RESPONSABLE_MODAL_TITLE: "تغيير المسؤول",
      CHANGE_RESPONSABLE_BUTTON: "تغيير المسؤول",
      ASSIGN_New_RESPONSABLE: "تعيين مسؤول جديد",
      CREATE_NEW_RESPONSABLE: "إنشاء مسؤول جديد",
      ASSIGNT_EXISTING_RESPONSABLE: "تعيين مسؤول موجود",
    },
    fr: {
      MODAL_TITLE: "informations de responsable",
      RESPONSABLE_USERNAME: "Nom d'utilisateur",
      RESPONSABLE_FIRSTNAME: "Prénom",
      RESPONSABLE_LASTNAME: "Nom de famille",
      RESPONSABLE_EMAIL: "Email",
      RESPONSABLE_TELEPHONE: "Téléphone",
      RESPONSABLE_COUNTRY: "Pays",
      RESPONSABLE_IS_BLOCKED: "Est bloqué",
      RESPONSABLE_INFO_NOT_FOUND: "Aucune information responsable trouvée",
      RESPONSABLE_ACCOUNT_STATUS: "Statut du compte",
      ACCOUNT_BLOCKED: "Bloqué",
      ACCOUNT_ACTIVE: "Actif",
      /* update university modal translate */
      UPDATE_UNIVERSITY_MODAL_TITLE: "Mettre à jour l'université",
      UNIVERSITY_NAME: "Nom de l'université",
      UNIVERSITY_COUNTRY: "Pays",
      UNIVERSITY_CREDIT: "Crédit",
      ASSIGN_New_RESPONSABLE: "Assigner un nouveau responsable",

      /* change responsable modal translate */
      CHANGE_RESPONSABLE_MODAL_TITLE: "Changer le responsable",
      CHANGE_RESPONSABLE_BUTTON: "Changer le responsable",
      ADD_NEW_RESPONSABLE: "Ajouter un nouveau responsable",
      CREATE_NEW_RESPONSABLE: "Créer un nouveau responsable",
      ASSIGNT_EXISTING_RESPONSABLE: "Assigner un responsable existant",
    },
  };
  const [assignRespo, setAssignRespo] = useState(false);
  return (
    <div>
      {" "}
      <div>
        <div className="flex items-center  justify-between bg-[#285090] text-white">
          <h2 className="text-xl p-1.5 px-2 font-semibold">
            {getTranslation(translation, "MODAL_TITLE")}
          </h2>
          <span
            onClick={onClose}
            className="cursor-pointer text-xl p-2.5 transition ease-in-out hover:bg-red-600 hover:text-white"
          >
            <IoMdClose />
          </span>
        </div>
        {universityInfo?.responsable ? (
          <div className="p-3 px-2">
            <p className="py-1.5">
              <span className="font-bold mx-1">
                {getTranslation(translation, "RESPONSABLE_USERNAME")}:
              </span>
              {universityInfo?.responsable?.username}
            </p>

            <p className="py-1.5">
              <span className="font-bold mx-1">
                {getTranslation(translation, "RESPONSABLE_EMAIL")}:
              </span>

              {universityInfo?.responsable?.email}
            </p>
            <p className="py-1.5">
              <span className="font-bold mx-1">
                {getTranslation(translation, "RESPONSABLE_TELEPHONE")}:
              </span>
              {universityInfo?.responsable?.numero}
            </p>

            {/*   <div className="flex items-center">
              <span className="font-bold mx-1">
                {getTranslation(translation, "RESPONSABLE_ACCOUNT_STATUS")}:
              </span>
              <span
                className={`${
                  universityInfo?.responsable?.isBlocked
                    ? "bg-red-900"
                    : "bg-green-900"
                } text-white px-2 py-1.5 rounded-md ml-1`}
              >
                {universityInfo?.responsable?.block
                  ? getTranslation(translation, "ACCOUNT_BLOCKED")
                  : getTranslation(translation, "ACCOUNT_ACTIVE")}
              </span>
            </div> */}
          </div>
        ) : (
          <div className="py-4">
            <div className="text-center py-4">
              <span className="text-red-600">
                {getTranslation(translation, "RESPONSABLE_INFO_NOT_FOUND")}
              </span>
            </div>
            <div className="flex justify-center">
              {!assignRespo && (
                <button
                  className="bg-[#285090] text-white p-2.5 rounded-md mx-2"
                  onClick={() => setAssignRespo(true)}
                >
                  {getTranslation(translation, "ASSIGN_New_RESPONSABLE")}
                </button>
              )}
              {assignRespo && (
                <>
                  <button
                    className="bg-[#285090] text-white p-2.5 rounded-md mx-2"
                    onClick={() => setModalType("changeResponsable")}
                  >
                    {getTranslation(translation, "CREATE_NEW_RESPONSABLE")}
                  </button>
                  <button
                    className="bg-[#285090] text-white p-2.5 rounded-md mx-2"
                    onClick={() => setModalType("assignExistingResponsable")}
                  >
                    {getTranslation(
                      translation,
                      "ASSIGNT_EXISTING_RESPONSABLE"
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ShowResponsableInfo.propTypes = {
  onClose: PropTypes.func,
  universityInfo: PropTypes.object,
  setModalType: PropTypes.func,
};
