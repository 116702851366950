import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table";
import {
  useGetStudentDataUpdateDemandesMutation,
  useAcceptStudentDataUpdateDemandeMutation,
  useRejectStudentDataUpdateDemandeMutation,
  useAcceptSpecificStudentDataUpdateDemandMutation,
  useRejectSpecificStudentDataUpdateDemandMutation,
} from "../../store/service/studentsService";
import { GET_STUDENTS_UPDATE_DEMANDES } from "../../store/features/studentsSlice/studentsSlice";
import Model from "./Model";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Pagination from "../../components/pagination/Pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../components/spinner/Spinner";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastSucces, toastErrorServer } from "../../components/toast/Toast";
import { filterList } from "../../utils/filter/filterStudentBySearchCriteria";
import { getTranslation } from "../../utils/translate/translation";

const animatedComponents = makeAnimated();

export default function DemandesTable() {
  const translation = {
    en: {
      SEARCH_PLACEHOLDER: "Search...!",
      CHOOSE_PLACEHOLDER: "Choose...!",
      STUDENT_FULL_NAME: "Student Full Name",
      SERVER_ERROR: "Server error!",
      TOKEN_EXPIRED: "Your registration period has ended. Please log in again.",
    },
    ar: {
      SEARCH_PLACEHOLDER: "ابحث...!",
      CHOOSE_PLACEHOLDER: "اختر...!",
      STUDENT_FULL_NAME: "اسم الطالب",

      SERVER_ERROR: "خطأ في الخادم!",
      TOKEN_EXPIRED: "تم إنهاء الصلاحية. يرجى تسجيل الدخول مرة اخرى.",
    },
    fr: {
      SEARCH_PLACEHOLDER: "Recherche...!",
      CHOOSE_PLACEHOLDER: "Choisir...!",
      STUDENT_FULL_NAME: "Nom complet de l'étudiant",
      SERVER_ERROR: "Erreur du serveur!",
      TOKEN_EXPIRED:
        "Votre période d'inscription a expiré. Veuillez vous reconnecter.",
    },
  };
  const options = [
    {
      value: "fullName",
      label: getTranslation(translation, "STUDENT_FULL_NAME"),
    },
  ];
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getStudentDataUpdateDemandes] =
    useGetStudentDataUpdateDemandesMutation();
  const [acceptStudentDataUpdateDemande] =
    useAcceptStudentDataUpdateDemandeMutation();
  const [rejectStudentDataUpdateDemande] =
    useRejectStudentDataUpdateDemandeMutation();
  const [acceptSpecificStudentDataUpdateDemand] =
    useAcceptSpecificStudentDataUpdateDemandMutation();
  const [rejectSpecificStudentDataUpdateDemand] =
    useRejectSpecificStudentDataUpdateDemandMutation();

  const StudentUpdateDemandesList = useSelector(
    (state) => state.students.studentUpdateDemandes
  );
  const [processingUpdateDemand, setProcessingUpdateDemand] = useState(false);

  const [demandes, setDmandes] = useState([]);
  const [demande, setDemande] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [modalType, setModalType] = useState("");
  const [selectedFilterInputs, setSelectedFilterInputs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [loading, setLoading] = useState(false);
  const [selectedDemande, setSelectedDemande] = useState(false);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const initializePagination = () => {
    let currentPerPage = perPage;
    setPerPage(currentPerPage);
    setCurrentPage(1);
  };
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    initializePagination();
  };
  const handleChangeSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSelectedFilterInputs(array);
    });

    if (e?.length == []) {
      array.push("");
      setSelectedFilterInputs([]);
    }
    setCurrentPage(1);
  };
  const fetchALLDemandes = async () => {
    //decode Token and get the agent id from it
    const agentID = jwtDecode(token).user._id;
    getStudentDataUpdateDemandes(agentID).then((res) => {
      dispatch(
        GET_STUDENTS_UPDATE_DEMANDES({
          studentUpdateDemandes: res.data.dataToSend,
        })
      );
      if (searchInput === "" && selectedFilterInputs.length == 0) {
        setLoading(true);
        setDmandes(res.data.dataToSend);
        /* console.log("les demande de update student ", res.data.dataToSend); */
      }
    });
  };

  useEffect(() => {
    if (role === "ROLE_AGENT_UNI") {
      if (!VerifyToken()) {
        logout();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      fetchALLDemandes();
    }
  }, [loading]);

  useEffect(() => {
    handleChangeSearch();
    filterList(
      selectedFilterInputs,
      setDmandes,
      StudentUpdateDemandesList,
      searchInput,
      setSearchInput
    );
  }, [searchInput, selectedFilterInputs]);

  const AcceptAllChanges = (data) => {
    setProcessingUpdateDemand(true);
    acceptStudentDataUpdateDemande(data).then((res) => {
      if (res.data.message) {
        setProcessingUpdateDemand(false);
        toastSucces(res.data.message);
        fetchALLDemandes();
        //close the modal
        setOpen(false);
      }
      if (res.data.error) {
        setProcessingUpdateDemand(false);
        toastErrorServer(res.data.error);
      }
    });
  };
  const RejectAllChanges = (data) => {
    try {
      setProcessingUpdateDemand(true);
      rejectStudentDataUpdateDemande(data).then((res) => {
        if (res.data.message) {
          setProcessingUpdateDemand(false);
          toastSucces(res.data.message);
          fetchALLDemandes();
          //close the modal
          setOpen(false);
        }
        if (res.data.error) {
          setProcessingUpdateDemand(false);
          toastErrorServer(res.data.error);
        }
      });
    } catch (error) {
      console.log("error", error);
      setProcessingUpdateDemand(false);
    }
  };
  const AcceptSpecificChange = (data) => {
    setProcessingUpdateDemand(true);
    acceptSpecificStudentDataUpdateDemand(data).then((res) => {
      if (res.data.message) {
        setProcessingUpdateDemand(false);
        toastSucces(res.data.message);
        fetchALLDemandes();
        //close the modal
        setOpen(false);
      }
      if (res.data.error) {
        setProcessingUpdateDemand(false);
        toastErrorServer(res.data.error);
      }
    });
  };
  const RejectSpecificChange = (data) => {
    setProcessingUpdateDemand(true);
    rejectSpecificStudentDataUpdateDemand(data).then((res) => {
      if (res.data.message) {
        setProcessingUpdateDemand(false);
        toastSucces(res.data.message);
        fetchALLDemandes();
        //close the modal
        setOpen(false);
      }
      if (res.data.error) {
        setProcessingUpdateDemand(false);
        toastErrorServer(res.data.error);
      }
    });
  };

  return (
    <div className="flex flex-col justify-start ">
      <div className="w-full gap-3 px-3 p-4 lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col">
        <input
          type={"text"}
          className="h-10 transition ease-in-out delay-200 border rounded lg:w-1/4 md:w-1/4 focus:outline-blue-600 m-1"
          placeholder={getTranslation(translation, "SEARCH_PLACEHOLDER")}
          value={searchInput}
          onChange={handleChange}
        />
        <Select
          options={options}
          className="lg:w-2/3 md:w-2/3 m-1"
          closeMenuOnSelect={false}
          placeholder={getTranslation(translation, "CHOOSE_PLACEHOLDER")}
          components={animatedComponents}
          onChange={handleChangeSearch}
          styles={{ height: "3rem", borderWidth: "2px" }}
          isMulti
        />
        {demandes.length > 5 ? (
          <select
            className="h-10 border rounded lg:w-1/12 md:w-1/12 focus:outline-blue-600 focus:border-none m-1"
            onChange={handleChangeSelect}
            value={perPage}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option defaultValue={30}>30</option>
            <option value={50}>50</option>
          </select>
        ) : null}
      </div>
      <Model
        showModal={open}
        onClose={() => setOpen(false)}
        universityInfo={demande}
        modalType={modalType}
        reloadData={fetchALLDemandes}
        AcceptAllChanges={AcceptAllChanges}
        RejectAllChanges={RejectAllChanges}
        AcceptSpecificChange={AcceptSpecificChange}
        RejectSpecificChange={RejectSpecificChange}
        selectedDemande={selectedDemande}
        processingUpdateDemand={processingUpdateDemand}
      />
      <div className="relative pt-10 overflow-x-auto  sm:rounded-lg h-[auto] ">
        {loading ? (
          <Table
            setOpen={setOpen}
            demandes={demandes}
            setDemandes={setDmandes}
            setDemande={setDemande}
            currentPage={currentPage}
            perPage={perPage}
            setModalType={setModalType}
            selectedDemande={setSelectedDemande}
          />
        ) : (
          <div className="h-[300px] flex items-center justify-center ">
            <Spinner />
          </div>
        )}
      </div>
      <div className="flex justify-start">
        {demandes?.length > 5 && perPage < demandes?.length ? (
          <Pagination
            perPage={perPage}
            total={demandes?.length}
            paginate={paginate}
          />
        ) : null}
      </div>
    </div>
  );
}

DemandesTable.propTypes = {
  setPerPage: PropTypes.func,
};
