import React from "react";
import SectionBody from "../section-body/SectionBody";
import detailImg from "../../../assets/details-2.png";
import { MdCheck } from "react-icons/md";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    sectionTitle: "Operation of the platform",
    operationDescription1:
      " First: The Ministry of Education creates an account for each university. This account is authorized to admin The university or dean establish accounts for student affairs assistants and monitor all certificate issuance processes. ",
    operationDescription2:
      "  Second: The assistant in charge of student affairs at the university enters the data Students, their majors and certificates obtained. It also scans paper certificates and upload to the platform. ",
    operationDescription3:
      "  Third: The student connects to his account to consult his certificates. And even To confirm the validity of the data, the student can send his electronic certificate or extract a unique identifier in the form of a QR-CODE To be submitted to the competent authority in the event of a job search or the end of studies in another university or another country. And this identifier, when By reading it on the phone, it transfers the user directly to the electronic certificate on the platform. ",
    operationDescription4:
      " Fourth: When the student applies for a job or completes his studies at another university or another country What, the latter extracts the unique identifier of the certificate from his account on the platform in the form of QR-CODE and adds it to his file In order for the authority concerned to be able to compare the paper certificate submitted by the student with the electronic certificate on the site Platform. ",
  },
  ar: {
    sectionTitle: "كيفية عمل المنصّة",
    operationDescription1: ` أوّلا : تقوم وزارة التعليم بإنشاء حساب لكل جامعة. هذا الحساب يسمح لمدير الجامعة أو العميد بإنشاء حسابات لأعوان شؤون الطلبة ومراقبة جميع عمليات إسناد الشهادات.`,
    operationDescription2: ` ثانيا : يقوم العون المسؤول عن شؤون الطلبة بالجامعة بإدخال بيانات الطلبة واختصاصاتهم والشهادات المتحصّل عليها. كما يقوم بإجراء مسح للشهادات الورقية ورفعها على المنصّة. `,
    operationDescription3: ` ثالثا : يقوم الطالب بالدخول إلى حسابه الخاص للاطلاع على شهاداته. وبعد تأكيد صحّة البيانات، يستطيع الطالب ارسال شهادته الالكترونية أو استخراج معرّف وحيد على شكل QR-CODE لتسليمه للجهة المعنية في حال البحث عن توظيف أو إكمال الدراسة بجامعة أخرى أو بلد آخر. وهذا المعرّف، عند قراءته بالهاتف، يقوم بتحويل المستعمل مباشرة إلى الشهادة الالكترونية على المنصّة.`,
    operationDescription4: ` رابعا : عندما يتقدّم الطالب في وظيفة أو إكمال دراسته بجامعة أخرى أو بلد ما، يقوم هذا الأخير باستخراج المعرّف الوحيد للشهادة من حسابه على المنصّة على شكل QR-CODE ويضيفه إلى ملفه حتى تتمكّن الجهة المعنية بمقارنة الشهادة الورقية المقدّمة من الطالب مع الشاهدة الالكترونية الموجودة على المنصّة.`,
  },
  fr: {
    sectionTitle: "Fonctionnement de la plateforme",
    operationDescription1:
      " Premièrement : Le ministère de l'Éducation crée un compte pour chaque université. Ce compte est autorisé à administrer L'université ou le doyen établissent des comptes pour les assistants aux affaires étudiantes et surveillent tous les processus d'émission de certificats. ",
    operationDescription2:
      " Deuxièmement : L'assistant en charge des affaires étudiantes à l'université saisit les données Étudiants, leurs spécialités et certificats obtenus. Il scanne également les certificats papier et les télécharge sur la plateforme. ",
    operationDescription3:
      " Troisièmement : L'étudiant se connecte à son compte pour consulter ses certificats. Et même Pour confirmer la validité des données, l'étudiant peut envoyer son certificat électronique ou extraire un identifiant unique sous forme de QR-CODE Pour être soumis à l'autorité compétente en cas de recherche d'emploi ou de fin d'études dans une autre université ou un autre pays. Et cet identifiant, lors de Sa lecture sur le téléphone, transfère l'utilisateur directement au certificat électronique sur la plateforme. ",
    operationDescription4:
      " Quatrièmement : Lorsque l'étudiant postule à un emploi ou termine ses études dans une autre université ou un autre pays Quoi, ce dernier extrait l'identifiant unique du certificat de son compte sur la plateforme sous forme de QR-CODE et l'ajoute à son dossier Afin que l'autorité concernée puisse comparer le certificat papier soumis par l'étudiant avec le certificat électronique sur le site Plateforme. ",
  },
};
export default function FifthSection() {
  return (
    <div id="howto" className="p-2 py-8 pt-20 bg-white">
      <div className="container mx-auto">
        <SectionBody
          sectionImg={detailImg}
          sectionImgAlt={"section 2"}
          SectionTitle={getTranslation(translate, "sectionTitle")}
          ParagraphsList={[
            getTranslation(translate, "operationDescription1"),
            getTranslation(translate, "operationDescription2"),
            getTranslation(translate, "operationDescription3"),
            getTranslation(translate, "operationDescription4"),
          ]}
          itemIcon={<MdCheck className="text-lg font-black text-[#1acc8d]" />}
          reverseItem={true}
        />
      </div>
    </div>
  );
}
