import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function NavBarItem({ text, to, Menustyle, toggleMenu }) {
  const [isActive, setIsActive] = useState(false);
  const language = localStorage.getItem("language");

  useEffect(() => {
    const sectionId = to.replace("#", "");
    const section = document.getElementById(sectionId);
    //make to deferent condition to check is the screen active or not because we have the mobile and the desktop

    if (section) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsActive(entry.isIntersecting);
        },
        { rootMargin: "10px", threshold: 0.8 }
      );
      observer.observe(section);
    }

    return () => {
      if (section) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIsActive(entry.isIntersecting);
          },
          { rootMargin: "20px", threshold: 0.1 }
        );
        observer.unobserve(section);
      }
    };
  }, [to]);

  const handleScroll = () => {
    const sectionId = to.replace("#", "");
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    // Close the menu after clicking and scrolling
    if (Menustyle) {
      toggleMenu();
    }
  };

  return (
    <div
      className={`relative ${Menustyle ? "block my-4 pb-1" : "inline-block"}`}
    >
      <div
        onClick={handleScroll}
        className={`
          ${isActive && Menustyle === false && "nav-link-active"} 
          ${isActive && Menustyle === true && "nav-menu-link-active"}
          ${Menustyle ? "nav-menu-link" : "nav-link"} 
          ${language === "ar" ? "before:right-0" : ""} 
          cursor-pointer p-0`}
      >
        {text}
      </div>
    </div>
  );
}

NavBarItem.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  Menustyle: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired, // Pass the toggleMenu prop
};
