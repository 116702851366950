import React from "react";
import { getTranslation } from "../../utils/translate/translation";
import PropTypes from "prop-types";

import { digitalDiplomaIcon, excelIcon, pdfIcon } from "../../assets/svg/Index";

const translate = {
  ar: {
    TOAST_SUCCES: "تمت العملية بنجاح",
    MODE_ONE: "  رفع ملف اكسل مرفق بملفات pdfs",
    MODE_TWO: "رفع ملف اكسل",
    MODE_THREE: "   إنشاء دبلومة رقمية",
    BUTTON_TITLE: "حفظ التعديلات",
  },
  en: {
    TOAST_SUCCES: "Operation accomplished successfully",
    MODE_ONE: "Upload an excel file attached to pdfs",
    MODE_TWO: "Upload excel file",
    MODE_THREE: " Create a digital diploma",
    BUTTON_TITLE: "Save",
  },
  fr: {
    TOAST_SUCCES: "Opération réussie",
    MODE_ONE: " Télécharger un fichier excel joint aux pdfs",
    MODE_TWO: "Téléchargez un fichier excel",
    MODE_THREE: " Créer un diplôme numérique",
    BUTTON_TITLE: "Enregistrer ",
  },
};
const AddUniversityModeSelector = ({ selectedMode, setSelectedMode }) => {
  // eslint-disable-next-line no-unused-vars

  const handleModeClick = (modeName) => {
    setSelectedMode({
      ...selectedMode,
      [modeName]: !selectedMode[modeName],
    });
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-[auto] flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md text-white">
        <div className="w-full">
          <div className="grid-flow-row gap-2 lg:flex lg:justify-between lg:w-full sm:grid auto-rows-max">
            <button
              onClick={() => handleModeClick("modeOne")}
              className={`sm:p-4 px-10 rounded-md lg:w-72  cursor-pointer ${
                selectedMode.modeOne
                  ? "bg-[#435ebe] border-white"
                  : "bg-[#f1f0ef] border text-[#607080]"
              }`}
            >
              {getTranslation(translate, "MODE_ONE")}
              <div className="flex items-center justify-center w-full gap-2">
                {pdfIcon} + {excelIcon}
              </div>
            </button>
            <button
              onClick={() => handleModeClick("modeTwo")}
              className={`sm:p-4 px-10 rounded-md lg:w-72 cursor-pointer ${
                selectedMode.modeTwo
                  ? "bg-[#435ebe] border-white"
                  : "bg-[#f1f0ef] border text-[#607080]"
              }`}
            >
              {getTranslation(translate, "MODE_TWO")}
              <div className="flex items-center justify-center w-full ">
                {excelIcon}
              </div>
            </button>
            <button
              onClick={() => handleModeClick("modeThree")}
              className={`sm:p-4 px-10 rounded-md lg:w-72 cursor-pointer ${
                selectedMode.modeThree
                  ? "bg-[#435ebe] border-white"
                  : "bg-[#f1f0ef] border text-[#607080]"
              }`}
            >
              {getTranslation(translate, "MODE_THREE")}
              <div className="flex items-center justify-center w-full ">
                {digitalDiplomaIcon}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddUniversityModeSelector.propTypes = {
  selectedMode: PropTypes.shape({
    modeOne: PropTypes.bool,
    modeTwo: PropTypes.bool,
    modeThree: PropTypes.bool,
  }).isRequired,
  setSelectedMode: PropTypes.func.isRequired,
};

export default AddUniversityModeSelector;
