import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import RegisterLayout from "./layouts/RegisterLayout";
import AddOneStudent from "./pages/AddStudentsPage/AddOneStudent";
import AddStudentsPage from "./pages/AddStudentsPage/AddStudentsPage";
import UpdateOneStudent from "./pages/AddStudentsPage/UpdateOneStudent";
import AddUserPage from "./pages/AddUserPage/AddUserPage";
import AgentList from "./pages/AgentList/AgentList";
import DiplomasList from "./pages/DiplomasList/DiplomasList";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import SettingPage from "./pages/SettingsPage/SettingPage";
import StudentsLoginPage from "./pages/StudentsLogin/StudentsLoginPage";
import ThirdPartyPage from "./pages/ThirdPartyPage/ThirdPartyPage";
import StudentsList from "./pages/StudentsList/StudentList";
import StudentDemandesList from "./pages/StudentDemandesList/StudentDemandesList";
import DiplomaDemandesList from "./pages/DiplomaDemandeList/DiplomaDemandesList";
import Dashboard from "./pages/Dashboard/Dashboard";
import VerificationPage from "./pages/VerificationPage/VerificationPage";
import UpdateOneDiploma from "./pages/AddDiplomasPage/UpdateOneDiploma";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/NotFound/NotFound";
import UsersList from "./pages/UsersList/UsersList";
import { LOADING_USER } from "./store/features/userSlice/userSlice";
import { useDispatch } from "react-redux";
import { useGetUserMutation } from "./store/service/userService";
import "./index.css";
import DiplomaList from "./pages/DiplomaAccepted/DiplomaList";

import jwtDecode from "jwt-decode";
import DiplomaPendingList from "./pages/DiplomaPending/DiplomaPendingList";
import UpdateUser from "./pages/UsersList/UpdateUser";
import ErrorBoundary from "./utils/ErrorBoundary";
import DiplomasRejectedList from "./pages/DiplomasRejected/DiplomasRejectedList";
import AddDiplomasMain from "./pages/AddDiplomasPage/AddDiplomasMain";
import DigitalDiplomaPage from "./pages/DigitalDiplomaPage/DigitalDiplomaPage";
import LandingPageV1 from "./pages/LandingPageV1/LandingPageV1";
import { useGetRejectedDiplomaByAgentMutation } from "./store/service/diplomasService";
import { GET_AGENTS } from "./store/features/diplomasSlice/diplomasSlice";
import { useState } from "react";
import Update from "./pages/StudentsList/UpdateStudent/Update";
import AddUniversityPage from "./pages/AddUniversityPage/AddUniversityPage";
import UniversitiesList from "./pages/UniversitiesList/UniversitiesList";
const StudentsProfile = React.lazy(() =>
  import("./pages/StudentsProfile/StudentsProfile")
);

function App() {
  const [getUser] = useGetUserMutation();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  let dispatch = useDispatch();
  useEffect(() => {
    // Function to handle the localStorage change event
    const handleStorageChange = (event) => {
      // Check if the changed item is the one you're interested in
      if (event.key === "language") {
        // Handle the change here
        console.log('localStorage "language" changed:', event.newValue);

        // Perform any actions based on the new value of the "language" item
        // For example, update the state or trigger a component re-render
        // ...
        // navigate("/");
        console.log(event.newValue);
        window.location.reload(false);
      }
    };

    // Listen to the "storage" event
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  });
  const getCourentUser = () => {
    getUser().then((res) => {
      dispatch(
        LOADING_USER({
          role: res?.data?.data?.role?.name,
          username: res?.data?.data?.username,
          email: res.data.data.email,
          university: res?.data?.data?.university?.name,
          mode: res?.data?.data?.university?.mode,
          speciality: res?.data?.data?.speciality,
          numero: res?.data?.data?.numero,
          isAccepted: res?.data?.data?.IsAccepted,
          signature: res?.data?.data?.signature,
          logo: res?.data?.data?.university?.logo,
          responsableWallet: res?.data?.data?.blockchainAddress,
        })
      );
    });
  };
  const [count, setCount] = useState(0);
  const [getDiplomaRejected] = useGetRejectedDiplomaByAgentMutation();
  React.useEffect(() => {
    if (token && role !== "student") {
      getCourentUser();
    }
    if (role === "ROLE_AGENT_UNI") {
      getDiplomaRejected().then((res) => {
        setCount(res.data.data.length);
        dispatch(GET_AGENTS({ countDiploma: count, agent: res.data.data }));
      });
    }
    if (token) {
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
      }
    }
  }, [token, count]);
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/Login"
            element={
              <RegisterLayout>
                <Suspense fallback={<h1>Loading ...</h1>}>
                  <LoginPage />
                </Suspense>
              </RegisterLayout>
            }
          />
          <Route
            path="/Login_Student"
            element={
              <RegisterLayout>
                <Suspense fallback={<h1>Loading ...</h1>}>
                  <ErrorBoundary>
                    <StudentsLoginPage />
                  </ErrorBoundary>
                </Suspense>
              </RegisterLayout>
            }
          />
          <Route
            path="/register"
            element={
              <RegisterLayout>
                <Suspense fallback={<h1>Loading ...</h1>}>
                  <RegisterPage />
                </Suspense>
              </RegisterLayout>
            }
          />
          <Route
            path="/forget_password/:token"
            element={
              <RegisterLayout>
                <Suspense fallback={<h1>Loading ...</h1>}>
                  <ForgotPasswordPage />
                </Suspense>
              </RegisterLayout>
            }
          />
          <Route
            path="/register/:token"
            element={
              <RegisterLayout>
                <Suspense fallback={<h1>Loading ...</h1>}>
                  <RegisterPage />
                </Suspense>
              </RegisterLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/student"
            element={
              <Layout>
                <StudentsList />
              </Layout>
            }
          />
          <Route
            path="/student-demandes"
            element={
              <Layout>
                <StudentDemandesList />
              </Layout>
            }
          />
          <Route
            path="/diploma-demandes"
            element={
              <Layout>
                <DiplomaDemandesList />
              </Layout>
            }
          />

          <Route
            path="/add_Student"
            element={
              <Layout>
                <AddStudentsPage />
              </Layout>
            }
          />
          <Route
            path="/update_one_student"
            element={
              <Layout>
                <UpdateOneStudent />
              </Layout>
            }
          />
          <Route
            path="/add_user"
            element={
              <Layout>
                <AddUserPage />
              </Layout>
            }
          />
          <Route
            path="/add_user"
            element={
              <Layout>
                <AddUserPage />
              </Layout>
            }
          />
          <Route
            path="/add_university"
            element={
              <Layout>
                <AddUniversityPage />
              </Layout>
            }
          />
          <Route
            path="/universities_list"
            element={
              <Layout>
                <UniversitiesList />
              </Layout>
            }
          />
          <Route
            path="/diplomasList"
            element={
              <Layout>
                <DiplomaList />
              </Layout>
            }
          />
          <Route
            path="/update_one_diploma"
            element={
              <Layout>
                <UpdateOneDiploma />
              </Layout>
            }
          />
          <Route
            path="/listsAgent"
            element={
              <Layout>
                <AgentList />
              </Layout>
            }
          />
          <Route
            path="/updateStudent/:id"
            element={
              <Layout>
                <Update />
              </Layout>
            }
          />

          <Route
            path="/add_one_student"
            element={
              <Layout>
                <AddOneStudent />
              </Layout>
            }
          />
          <Route
            path="/add_diplomas"
            element={
              <Layout>
                <AddDiplomasMain />
              </Layout>
            }
          />
          <Route
            path="/diplomas_not_confirm/:id"
            element={
              <Layout>
                <DiplomasList />
              </Layout>
            }
          />
          <Route
            path="/diplomas_not_confirm"
            element={
              <Layout>
                <DiplomaPendingList />
              </Layout>
            }
          />
          <Route
            path="/diplomasRejected"
            element={
              <Layout>
                <DiplomasRejectedList />
              </Layout>
            }
          />

          <Route
            path="/settings"
            element={
              <Layout>
                <SettingPage />
              </Layout>
            }
          />
          <Route
            path="/usersList"
            element={
              <Layout>
                <UsersList />
              </Layout>
            }
          />
          <Route
            path="/updateUser/:id"
            element={
              <Layout>
                <UpdateUser />
              </Layout>
            }
          />
          <Route path="/student_profile/" element={<StudentsProfile />} />

          <Route path="/digital_diploma/:id" element={<DigitalDiplomaPage />} />
          <Route path="/third_party" element={<ThirdPartyPage />} />
          <Route path="/" element={<LandingPageV1 />} />
          <Route path="/verification/:id" element={<VerificationPage />} />
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
