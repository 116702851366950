import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useUpdateDiplomasMutation } from "../../store/service/diplomasService";
import { useDispatch, useSelector } from "react-redux";
import SpinnerSmal from "../../components/spinner/SpinnerSmal";

import {
  GET_AGENTS,
  LOADING,
} from "../../store/features/diplomasSlice/diplomasSlice";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";
import { useGetResponsableBalanceQuery } from "../../store/service/userService";
import jwtDecode from "jwt-decode";

const translate = {
  ar: {
    TOAST_SUCCESS:
      "هذا العملية تحت المعالجة، قد تستغرق وقتًا، سوف نعيد توجيهك إلى لوحة القيادة.",
    TOAST_ERROR_SERVER: "هنالك خطأ من السرفر",
    AGENT: " الموظف",
    YEAR: " السنة الجامعية",
    TYPE: "نوع الشهادة",
    SPECIALITY: "الاختصاص",
    MESSAGE_ONE: "عرض إجمالي",
    NB_DIPLOMAS: "عدد الشهادات",
    DATE: "تاريخ الإضافة",
    ACCEPT_ALL: "اعتماد الجميع",
    REJECT_ALL: "إلغاء الجميع",
    INSUFFICIENT_BALANCE: "رصيد غير كاف ، يرجى الاتصال بنا  ",
  },
  en: {
    TOAST_SUCCESS:
      "This process is under processing, it may take time, we will redirect you to the dashboard.",
    TOAST_ERROR_SERVER: "There is an error from the server",
    AGENT: " Agent",
    YEAR: "Year",
    TYPE: "Type Diploma",
    SPECIALITY: "Speciality",
    MESSAGE_ONE: "View all ",
    NB_DIPLOMAS: "The number of Diplomas",
    DATE: "Added date",
    ACCEPT_ALL: "Accept all ",
    REJECT_ALL: "Reject all ",
    INSUFFICIENT_BALANCE: "insufficient balance , please contact us",
  },
  fr: {
    TOAST_SUCCESS:
      "Ce processus est en cours de traitement, cela peut prendre du temps, nous vous redirigerons vers le tableau de bord.",
    TOAST_ERROR_SERVER: "Il y a une erreur du serveur ",
    AGENT: " Agent",
    TYPE: "Type de diplome",
    YEAR: " L'année universitaire",
    SPECIALITY: "Spécialité",
    MESSAGE_ONE: "Voir tout",
    NB_DIPLOMAS: "Le nombre de Diplomes",
    DATE: "Date ajoutée",
    ACCEPT_ALL: "Accepter tout",
    REJECT_ALL: " Tout rejeter",
    INSUFFICIENT_BALANCE: "solde insuffisant, merci de nous contacter",
  },
};
const AgentDetails = ({ agent, setFiltered }) => {
  const navigate = useNavigate();
  const [rejectOrAcceptDiplomas] = useUpdateDiplomasMutation();
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const diploma = useSelector((state) => state.diploma.diplomas);

  const agentDetails = useSelector((state) => state.diploma.agent);
  const { data } = useGetResponsableBalanceQuery();
  const token = localStorage.getItem("token");
  const responsableBlockchainAddress = jwtDecode(token).user?.blockchainAddress;
  const countDiploma = useSelector((state) => state.diploma.countDiploma);
  useEffect(() => {
    if (agent.username === undefined || agentDetails?.createdBy?.length === 0) {
      navigate("/listsAgent");
    }
  }, []);

  const dispatch = useDispatch();
  const _RejectedOrAcceptedDiplomas = (diploma, decision) => {
    if (!data?.balance) {
      toastErrorServer(`${getTranslation(translate, "INSUFFICIENT_BALANCE")}`);
    }
    if (decision === "accept") {
      if (
        diploma.length > parseInt(data.balance) ||
        parseInt(data.balance) === 0
      ) {
        toastErrorServer(
          `${getTranslation(translate, "INSUFFICIENT_BALANCE")}`
        );
      } else {
        setIsLoadingAccept(true);
        rejectOrAcceptDiplomas({
          decision: "accept",
          diplomas: diploma,
          createdBy: agentDetails.createdBy,
          mailLanguage: localStorage.getItem("language"),

          responsableAddress: responsableBlockchainAddress,
        }).then((res, error) => {
          console.log(res);
          console.log(error);
          if (!res.error) {
            setFiltered([]);
            setIsLoadingAccept(false);
            dispatch(
              GET_AGENTS({
                agent: { agentDetails },
                countDiploma: countDiploma - diploma.length,
              })
            );
            dispatch(LOADING({ loading: false }));
            setTimeout(() => {
              toastSucces(`${getTranslation(translate, "TOAST_SUCCESS")}`);
              navigate("/dashboard");
              dispatch(LOADING({ loading: true }));
            }, 1000);
          } else {
            toastErrorServer(
              `${getTranslation(translate, "TOAST_ERROR_SERVER")}`
            );
          }
        });
      }
    } else {
      rejectOrAcceptDiplomas({
        decision: "rejected",
        diplomas: diploma,
        createdBy: agentDetails.createdBy,
        mailLanguage: localStorage.getItem("language"),
      }).then((res) => {
        if (!res.error) {
          setFiltered([]);
          dispatch(
            GET_AGENTS({
              agent: {},
              countDiploma: countDiploma - diploma.length,
            })
          );
          navigate("/listsAgent");

          setIsLoading(false);
        } else {
          toastErrorServer(
            `${getTranslation(translate, "TOAST_ERROR_SERVER")}`
          );
        }
      });
    }
  };
  const language = localStorage.getItem("language");
  /*  console.log("responsableBlockchainAddress", responsableBlockchainAddress); */
  return (
    <div className="h-auto p-4 overflow-x-auto sm:rounded-lg">
      <table
        className={
          language === "ar"
            ? "w-full text-sm text-right text-gray-500 dark:text-gray-400 "
            : "w-full text-sm text-left text-gray-500 dark:text-gray-400 "
        }
      >
        <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className=" text-[#607080]">
              {translate.ar.AGENT}
            </th>
            <th scope="col" className=" text-[#607080]">
              {getTranslation(translate, "YEAR")}
            </th>
            <th scope="col" className=" text-[#607080]">
              {getTranslation(translate, "TYPE")}
            </th>
            <th scope="col" className=" text-[#607080]">
              {getTranslation(translate, "SPECIALITY")}
            </th>
            <th scope="col" className=" text-[#607080]">
              {getTranslation(translate, "NB_DIPLOMAS")}
            </th>
            <th scope="col" className=" text-[#607080]">
              {getTranslation(translate, "DATE")}
            </th>
            <th scope="col" className=" text-[#607080]"></th>
          </tr>
        </thead>
        <tbody className="overflow-x-hidden h-10vh ">
          <tr className="border-b dark:bg-gray-900 dark:border-gray-700 bg-[#f2f2f2] ">
            <td className=" text-[#607080]">{agent?.username}</td>
            <td className=" text-[#607080]">{agent?.year}</td>
            <td className="px-2 py-2">{multilanguageReader(agent?.type)}</td>
            <td className="px-2 py-2">
              {multilanguageReader(agent?.speciality)}
            </td>
            <td className=" text-[#607080]">{diploma.length}</td>
            <td className=" text-[#607080]">
              {agent?.insertionDate === "Invalid date"
                ? null
                : agent?.insertionDate?.substr(0, 10)}
            </td>

            <td className="flex items-center justify-center px-6 py-2">
              <button
                className="p-1 px-4 mx-1 text-xs font-semibold text-white bg-[#198754] rounded w-fi h-fit"
                onClick={() => {
                  _RejectedOrAcceptedDiplomas(diploma, "accept");
                }}
              >
                {isLoadingAccept ? (
                  <SpinnerSmal />
                ) : (
                  <span> {getTranslation(translate, "ACCEPT_ALL")}</span>
                )}
              </button>
              <button
                className="p-1 px-4 mx-1 text-xs font-semibold text-white bg-[#dc3545] rounded w-fi h-fit"
                onClick={() => {
                  _RejectedOrAcceptedDiplomas(diploma, "rejected");
                }}
              >
                {isLoading ? (
                  <SpinnerSmal />
                ) : (
                  <span> {getTranslation(translate, "REJECT_ALL")}</span>
                )}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
AgentDetails.propTypes = {
  agent: PropTypes.object,
  setFiltered: PropTypes.func,
};
export default AgentDetails;
