/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  diplomas: [],
  diplomasToAdd: [],
  diplomasToAddTwo: [],
  diplomasToAddThree: [],
  currentDiploma: {},
  pdfs: null,
  files: [],
  selectedDipl: {},
  agent: {},
  loading: false,
  modeSelected: null,
  updateDiploma: true,
  countDiploma: 0,
  activeBadge: true,
  diplomaUpdateDemandes: [],
};

export const diplomaSlice = createSlice({
  name: "diplomas",
  initialState,
  reducers: {
    GET_DIPLOMAS: (state, action) => {
      state.diplomas = action.payload.diplomas;
    },
    SET_SELECTED_DIPL: (state, action) => {
      state.selectedDipl = action.payload.selectedDipl;
    },
    ACCEPT_DIPLOMA: (state, action) => {
      state.selectedDipl = action.payload.selectedDipl;
    },
    GET_AGENTS: (state, action) => {
      state.agent = action.payload.agent;
      state.countDiploma = action.payload.countDiploma;
    },
    LOADING: (state, action) => {
      state.loading = action.payload.loading;
    },
    ADD_FROM_CSV_DIPLOMAS: (state, action) => {
      switch (state.modeSelected) {
        case "modeOne":
          state.diplomasToAdd = action.payload.diplomasToAdd;
          break;
        case "modeTwo":
          state.diplomasToAddTwo = action.payload.diplomasToAddTwo;
          break;
        case "modeThree":
          state.diplomasToAddThree = action.payload.diplomasToAddThree;
          break;
      }
    },
    ADD_DIPLOMAS_FROM_CSV: (state, action) => {
      switch (state.modeSelected) {
        case "modeOne":
          state.diplomasToAdd = action.payload.diplomasToAdd;
          break;
        case "modeTwo":
          state.diplomasToAddTwo = action.payload.diplomasToAddTwo;
          break;
        case "modeThree":
          state.diplomasToAddThree = action.payload.diplomasToAddThree;
          break;
      }
    },
    SELECTED_MODE: (state, action) => {
      state.modeSelected = action.payload.modeSelected;
    },
    SELECT_DIPLOMA: (state, action) => {
      state.currentDiploma = action.payload.currentDiploma;
      state.updateDiploma = action.payload.updateDiploma;
    },
    ADD_PDFS: (state, action) => {
      state.pdfs = action.payload.pdfs;
    },
    ADD_FILES: (state, action) => {
      state.files = action.payload.files;
    },
    GET_DIPLOMAS_UPDATE_DEMANDES: (state, action) => {
      state.diplomaUpdateDemandes = action.payload.diplomaUpdateDemandes;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  GET_DIPLOMAS,
  SET_SELECTED_DIPL,
  SELECT_DIPLOMA,
  ACCEPT_DIPLOMA,
  ADD_PDFS,
  ADD_FILES,
  GET_AGENTS,
  LOADING,
  ADD_DIPLOMAS_FROM_CSV,
  ADD_FROM_CSV_DIPLOMAS,
  SELECTED_MODE,
  GET_DIPLOMAS_UPDATE_DEMANDES,
} = diplomaSlice.actions;

export default diplomaSlice.reducer;
