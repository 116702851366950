import PropTypes from "prop-types";

export default function SelectInvitationLanguage({
  setSelectedInvitationLanguage,
  selectedInvitationLanguage,
}) {
  const languages = [
    { value: "ar", label: "العربية" },
    { value: "fr", label: "Français" },
    { value: "en", label: "English" },
  ];

  const handleLanguageChange = (value) => {
    setSelectedInvitationLanguage(value);
  };

  return (
    <div className="p-4">
      <div className="flex border rounded-lg overflow-hidden">
        {languages.map((lang, index) => (
          <div key={lang.value} className="flex-1">
            <input
              type="radio"
              value={lang.value}
              id={lang.value}
              checked={selectedInvitationLanguage === lang.value}
              onChange={() => handleLanguageChange(lang.value)}
              className="hidden peer"
            />
            <label
              htmlFor={lang.value}
              className={`flex items-center justify-center font-bold px-3 py-2 text-sm  cursor-pointer transition-all ${
                selectedInvitationLanguage === lang.value
                  ? "bg-[#285090] text-white"
                  : "bg-background text-foreground hover:bg-accent hover:text-accent-foreground"
              } ${index !== 0 ? "border-l" : ""}`}
            >
              {lang.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

SelectInvitationLanguage.propTypes = {
  setSelectedInvitationLanguage: PropTypes.func,
  selectedInvitationLanguage: PropTypes.string,
};
