import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function GoalItem({ icon, text, count }) {
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    const targetCount = parseInt(count, 10);
    let startCount = 0;

    // Set a maximum duration of 3 seconds (3000 ms)
    const maxDuration = 1000;
    const stepTime = Math.max(1, Math.floor(maxDuration / targetCount));

    const timer = setInterval(() => {
      startCount += Math.ceil(targetCount / (maxDuration / stepTime)); // Increase in larger steps
      if (startCount >= targetCount) {
        setCurrentCount(targetCount);
        clearInterval(timer);
      } else {
        setCurrentCount(startCount);
      }
    }, stepTime);

    return () => clearInterval(timer);
  }, [count]);

  return (
    <div>
      <div>
        <div className="text-white flex justify-center items-center relative top-8">
          <span className="p-3 bg-[#1acc8d] border-white rounded-full text-xl">
            {icon}
          </span>
        </div>
        <div className="p-8 bg-white">
          <div className="text-center p-2">
            <h1 className="text-[#010483] text-4xl font-bold">
              {currentCount}
            </h1>
          </div>
          <div className="text-start">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

GoalItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired, // icon is a node (JSX element)
  count: PropTypes.string.isRequired,
};
