import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputErrorMessage from "./InputErrorMessage";
import {
  isNotEmpty,
  isNotEmptyAndContainsOnlyLetters,
  isNotEmptyAndContainsOnlyLettersAndNumbers,
  isValidDate,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
  containsOnlyDigits,
} from "../../utils/Validators/Validators";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Input({
  placeholder,
  icon,
  disabled,
  password,
  inputChange,
  value,
  type,
  setValid,
}) {
  const isInputValid = () => {
    switch (type) {
      case "EMAIL":
        return isValidEmail(value);
      case "PASSWORD":
        return isValidPassword(value);
      case "NAME":
        return isNotEmptyAndContainsOnlyLetters(value);
      case "University":
        return isNotEmpty(value);
      case "PHONE_NUMBER":
        return isValidPhoneNumber(value);
      case "NUMBER":
        return isNotEmpty(value) && containsOnlyDigits(value); // Added validation for numbers
      case "SPECIALITY":
        return isNotEmpty(value);
      case "NIN":
        return !isNotEmptyAndContainsOnlyLettersAndNumbers(value);
      case "NO_CONTROL":
        return isNotEmpty(value);
      case "DATE_FORMAT":
        return isValidDate(value);
      case "DISABLED":
        return true;
      default:
        return false;
    }
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const inputValue = event.target.value;

    // Prevent non-numeric input for "NUMBER" type
    if (
      type === "NUMBER" &&
      !containsOnlyDigits(inputValue) &&
      inputValue !== ""
    ) {
      return; // Do not update the value if it contains non-numeric characters
    }

    inputChange(inputValue);
    setValid(isInputValid());
  };

  useEffect(() => {
    inputChange(value);
    setValid(isInputValid());
  }, [value]);

  const [showPassword, setShowPassword] = useState(false);
  const language = localStorage.getItem("language");

  let inputType =
    type === "NUMBER" || type === "PHONE_NUMBER" ? "text" : "text"; // Set input to "text" to manually handle numeric validation
  if (password) {
    inputType = showPassword ? "text" : "password";
  }

  const getInputClassName = () => {
    const baseClass =
      "disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden outline-blue-300/70 text-gray-900 text-sm rounded-md block w-full";
    const rtlClass = language === "ar" ? "pr-10" : "pl-10";
    const padding = "p-2.5";

    if (isInputValid() || value === "") {
      return `${baseClass} bg-gray-50 border border-gray-300 ${rtlClass} ${padding}`;
    } else {
      return `${baseClass} hover:outline-blue-400 border-red-400 focus:border-2 bg-white border-2 ${rtlClass} ${padding}`;
    }
  };

  return (
    <div className="w-full ">
      <div className="relative mb-6">
        {password && (
          <div
            className={`absolute h-[45px] ${
              language === "ar" ? "left-0 pl-3" : "right-0 pl-3"
            } flex items-center justify-center pr-3 cursor-pointer `}
          >
            {showPassword ? (
              <FaEyeSlash onClick={() => setShowPassword(false)} />
            ) : (
              <FaEye onClick={() => setShowPassword(true)} />
            )}
          </div>
        )}

        <input
          data-testid="input-component"
          disabled={disabled}
          type={inputType}
          className={getInputClassName()}
          placeholder={placeholder || ""}
          onChange={handleInputChange}
          value={value}
          autoComplete="on"
          lang="en"
        />

        <div
          className={`absolute inset-y-0 ${
            language === "ar" ? "right-0 pr-3" : "left-0   pl-3"
          } flex items-center justify-center pr-3 pointer-events-none ${
            !isInputValid() && value.length > 0 && "mb-4"
          }`}
        >
          {icon}
        </div>
        {!isInputValid() && value && (
          <div data-testid="input-component-error-message">
            <InputErrorMessage type={type} />
          </div>
        )}
      </div>
    </div>
  );
}

Input.defaultProps = {
  setValid: () => {
    return true;
  },
  inputChange: () => {},
};
Input.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.object,
  value: PropTypes.string,
  inputChange: PropTypes.func,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  setValid: PropTypes.func,
};

export default Input;
