import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { GET_DIPLOMAS_UPDATE_DEMANDES } from "../../store/features/diplomasSlice/diplomasSlice";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
import { FaCircleInfo } from "react-icons/fa6";
import { Tooltip } from "@material-tailwind/react";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";

const Table = ({
  demandes,
  currentPage,
  perPage,
  setDemandes,
  setOpen,
  selectedDemande,
}) => {
  const translation = {
    ar: {
      STUDENT_FULL_NAME: "اسم الطالب",
      DEMANDE_INFORMATIONS: "معلومات الطلب",
      DEMANDE_TYPE: "نوع الطلب",
      ACTION: "العملية",
      CHOOSE: "اختر...",
      NO_DATA: "لم يتم العثور على بيانات",
      REJECT_DEMANDE_BTN: "رفض الطلب",
      ACCEPT_DEMANDE_BTN: "قبول الطلب",
      DEMANDE_INFORMATION_BTN: "معلومات الطلب",
      STUDENT_NIN: "الرقم الوطني للتلميذ",
    },
    en: {
      STUDENT_FULL_NAME: "Student Full Name",
      DEMANDE_INFORMATIONS: "Demande Informations",
      DEMANDE_TYPE: "Demande Type",
      NO_DATA: "No data found",
      ACTION: "Action",
      CHOOSE: "Choose...",
      REJECT_DEMANDE_BTN: "Reject Demande",
      ACCEPT_DEMANDE_BTN: "Accept Demande",
      DEMANDE_INFORMATION_BTN: "Information",
      STUDENT_NIN: "NIN",
    },

    fr: {
      STUDENT_FULL_NAME: "Nom complet de l'étudiant",
      DEMANDE_INFORMATIONS: "Informations sur la demande",
      DEMANDE_TYPE: "Type de demande",
      NO_DATA: "Aucune donnée trouvée",
      ACTION: "Action",
      CHOOSE: "Choisir...",
      REJECT_DEMANDE_BTN: "Rejeter la demande",
      ACCEPT_DEMANDE_BTN: "Accepter la demande",
      DEMANDE_INFORMATION_BTN: "Informations",
      STUDENT_NIN: "NIN",
    },
  };

  const [StudentNameClicked, setStudentNameClicked] = useState(false);
  const dispatch = useDispatch();
  console.log(demandes);
  const sortByFullName = (status) => {
    return (a, b) => {
      const fullNameA =
        multilanguageReader(a.StudentData?.firstName) +
        " " +
        multilanguageReader(a.StudentData?.lastName);
      const fullNameB =
        multilanguageReader(b.StudentData?.firstName) +
        " " +
        multilanguageReader(b.StudentData?.lastName);

      if (fullNameA > fullNameB) {
        return status ? -1 : 1;
      }
      if (fullNameB > fullNameA) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };

  // Toggle for sorting by student full name
  const onStudentNameClick = () => {
    setStudentNameClicked(!StudentNameClicked);
    const sorted = [...demandes].sort(sortByFullName(StudentNameClicked));
    setDemandes(sorted);
    dispatch(GET_DIPLOMAS_UPDATE_DEMANDES({ studentUpdateDemandes: sorted }));
  };
  const language = localStorage.getItem("language");
  return (
    <div className="max-h-[calc(100vh-500px)] overflow-y-auto">
      <table
        className={
          language === "ar"
            ? "z-10 w-full text-sm text-right  text-gray-500 dark:text-gray-400 "
            : "z-10 w-full text-sm  text-left text-gray-500 dark:text-gray-400 "
        }
      >
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th
              scope="col"
              className="px-4 py-2 cursor-pointer"
              onClick={onStudentNameClick}
            >
              <div className="flex items-center ">
                <span>{getTranslation(translation, "STUDENT_FULL_NAME")}</span>
                {StudentNameClicked ? (
                  <div className="cursor-pointer ">
                    <DownArrow />
                  </div>
                ) : (
                  <div className="cursor-pointer ">
                    <UpArrow />
                  </div>
                )}
              </div>
            </th>
            <th scope="col" className="px-4 py-2 cursor-pointer">
              {getTranslation(translation, "STUDENT_NIN")}
            </th>
            <th scope="col" className="px-4 py-2 cursor-pointer">
              {getTranslation(translation, "DEMANDE_INFORMATIONS")}
            </th>
          </tr>
        </thead>
        <tbody className="overflow-x-hidden h-10vh">
          {demandes?.length === 0 && (
            <tr className="border-b dark:bg-gray-900 dark:border-gray-700">
              <td
                colSpan="4"
                className="text-center py-2 dark:text-gray-400 text-gray-500"
              >
                {getTranslation(translation, "NO_DATA")}
              </td>
            </tr>
          )}
          {demandes
            ?.slice(currentPage * perPage - perPage, currentPage * perPage)
            .map((demande, key) => (
              <tr
                key={key}
                className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                  (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
                }`}
              >
                <td className="px-6 py-2">
                  {multilanguageReader(
                    demande?.StudentData?.firstName,
                    language
                  )}{" "}
                  {multilanguageReader(
                    demande?.StudentData?.lastName,
                    language
                  )}
                </td>
                <td className="px-6 py-2">{demande?.StudentData?.nin}</td>

                <td className="px-6 py-2">
                  <button
                    className="m-1 bg-green-800 flex items-center justify-center text-white rounded-md p-2 gap-2"
                    onClick={() => {
                      setOpen(true);
                      selectedDemande(demande);
                    }}
                  >
                    <span className="hidden sm:inline-block">
                      {getTranslation(translation, "DEMANDE_INFORMATION_BTN")}
                    </span>
                    <Tooltip
                      content={getTranslation(
                        translation,
                        "DEMANDE_INFORMATION_BTN"
                      )}
                      placement="top"
                      color="light"
                    >
                      <span className="sm:hidden">
                        <FaCircleInfo />
                      </span>
                    </Tooltip>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
Table.propTypes = {
  demandes: PropTypes.array,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  setDemandes: PropTypes.func,
  setOpen: PropTypes.func,
  selectedDemande: PropTypes.func,
};
export default Table;
