import { convertNumbers2English } from "../converts/convert";

/**
 * @function filterByCriteria
 * @description This function searches universities by criteria: name, country, and credit
 *
 * @param {*} universitiesList
 * @param {*} searchBy
 * @param {*} searchInput
 *
 * @returns List filtered by criteria
 */
const filterByCriteria = (universitiesList, searchBy, searchInput) => {
  const _filtered = universitiesList.filter(
    (university) =>
      (searchBy.includes("name") &&
        university?.university.name
          ?.toLowerCase()
          .includes(searchInput.toLowerCase())) ||
      (searchBy.includes("country") &&
        university?.university.country
          ?.toLowerCase()
          .includes(searchInput.toLowerCase())) ||
      (searchBy.includes("credit") &&
        university?.university.credit
          ?.toString()
          .includes(convertNumbers2English(searchInput))) ||
      (searchBy.includes("statusDemande") &&
        (university?.university?.CreditDepositeDemand?.status ||
          university?.university?.EtherDepositeDemand?.status))
  );

  return _filtered;
};

/**
 * @function filterList
 * @description This function filters the list of universities by the selected filters
 *
 * @param {*} selectedFilterInputs
 * @param {*} setUniversities
 * @param {*} universitiesList
 * @param {*} searchInput
 * @param {*} setSearchInput
 *
 * @returns Filtered list
 */
const filterList = (
  selectedFilterInputs,
  setUniversities,
  universitiesList,
  searchInput,
  setSearchInput
) => {
  if (selectedFilterInputs.length > 0) {
    for (let i = 0; i < selectedFilterInputs.length; i++) {
      if (
        selectedFilterInputs[i].includes("name") ||
        selectedFilterInputs[i].includes("country") ||
        selectedFilterInputs[i].includes("credit") ||
        selectedFilterInputs[i].includes("statusDemande")
      ) {
        const filteredSearchInputs = filterByCriteria(
          universitiesList,
          selectedFilterInputs,
          searchInput
        );
        setUniversities(filteredSearchInputs);
      }
    }
  } else if (setSearchInput !== "") {
    const _filtered = universitiesList?.filter((university) =>
      university.university?.name
        ?.toLowerCase()
        .includes(searchInput.toLowerCase())
    );
    setUniversities(_filtered);
  }
};

export { filterList, filterByCriteria };
