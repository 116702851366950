import React, { useState } from "react";
import { IoMenu, IoClose } from "react-icons/io5";
import LanguageButton from "../button/LanguageButton";
import NavMenu from "../menu/NavMenu";

export default function Languageschanger() {
  const [isOpen, setIsOpen] = useState(false); // State to track menu open/close

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu state
  };

  return (
    <div>
      <div className="hidden lg:flex lg:gap-8">
        <LanguageButton openDirection={"top"} />
      </div>
      <div className="lg:hidden">
        <button onClick={toggleMenu}>
          {isOpen ? (
            <IoClose className="w-8 text-white text-3xl" />
          ) : (
            <IoMenu className="w-8 text-white text-3xl" />
          )}
        </button>
      </div>
      <NavMenu isOpen={isOpen} toggleMenu={toggleMenu} />
    </div>
  );
}
