/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  studentsToAdd: [],
  studentUpdateDemandes: [],
  studentSelected: null,
  agent: [],
};

export const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    GET_STUDENTS: (state, action) => {
      state.students = action.payload.students;
    },
    DELETE_STUDENT: (state, action) => {
      state.students = action.payload.students;
    },
    GET_AGENTS: (state, action) => {
      state.agent = action.payload.agent;
    },
    GET_USERS: (state, action) => {
      state.agent = action.payload.agent;
    },
    ADD_FROM_CSV: (state, action) => {
      state.studentsToAdd = action.payload.studentsToAdd;
    },
    SELECT_STUDENT: (state, action) => {
      state.studentSelected = action.payload.studentSelected;
    },
    GET_STUDENTS_UPDATE_DEMANDES: (state, action) => {
      state.studentUpdateDemandes = action.payload.studentUpdateDemandes;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  GET_STUDENTS,
  DELETE_STUDENT,
  GET_AGENTS,
  GET_USERS,
  ADD_FROM_CSV,
  SELECT_STUDENT,
  GET_STUDENTS_UPDATE_DEMANDES,
} = studentSlice.actions;

export default studentSlice.reducer;
