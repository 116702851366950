import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../../utils/translate/translation";
import { IoMdClose } from "react-icons/io";
import { toastErrorServer, toastSucces } from "../../toast/Toast";
import { useSelector } from "react-redux";
import { useChangeUniveristyResponsableMutation } from "../../../store/service/universityService";
import Spinner from "../../spinner/Spinner";
export default function ChangeResponsable({
  onClose,
  universityInfo,
  reloadData,
}) {
  const translation = {
    en: {
      CHANGE_RESPONSABLE_MODAL_TITLE: "Change Responsable",
      CHANGE_RESPONSABLE_BUTTON: "Change Responsable",
      RESPONSABLE_USERNAME: "Responsable Username",
      RESPONSABLE_EMAIL: "Responsable Email",
      RESPONSABLE_PHONE_NUMBER: "Phone Number",
      CONFIRM_CHANGE_MODAL_TITLE: "Confirm Change",
      CONFIRM_CHANGE_ALERT: "Are you sure you want to change the responsable?",
      YES_BUTTON: "Yes",
      NO_BUTTON: "No",
      RESPONSABLE_INFO_NOT_FOUND: "No responsible information found",
      EMAIL_RESPONSABLE_ALREADY_EXIST: "Email already exists",
      UPDATED_SUCESSFULLY: "Responsable updated successfully",
      INVALID_EMAIL: "Invalid email address",
      INVALID_PHONE_NUMBER: "Phone number must contain only numbers",
      INVALID_USERNAME: "Username cannot be empty",
    },
    ar: {
      CHANGE_RESPONSABLE_MODAL_TITLE: "تغيير المسؤول",
      CHANGE_RESPONSABLE_BUTTON: "تغيير المسؤول",
      RESPONSABLE_USERNAME: "اسم المسؤول",
      RESPONSABLE_EMAIL: "البريد الإلكتروني للمسؤول",
      RESPONSABLE_PHONE_NUMBER: "رقم الهاتف",
      CONFIRM_CHANGE_MODAL_TITLE: "تأكيد التغيير",
      CONFIRM_CHANGE_ALERT: "هل أنت متأكد أنك تريد تغيير   المسؤول؟",
      YES_BUTTON: "نعم",
      NO_BUTTON: "لا",
      RESPONSABLE_INFO_NOT_FOUND: "لم يتم العثور على معلومات المسؤول",
      EMAIL_RESPONSABLE_ALREADY_EXIST: "البريد الإلكتروني موجود بالفعل",
      UPDATED_SUCESSFULLY: "تم تحديث المسؤول بنجاح",
      INVALID_EMAIL: "عنوان البريد الإلكتروني غير صالح",
      INVALID_PHONE_NUMBER: "يجب أن يحتوي رقم الهاتف على أرقام فقط",
      INVALID_USERNAME: "اسم المستخدم لا يمكن أن يكون فارغًا",
    },
    fr: {
      CHANGE_RESPONSABLE_MODAL_TITLE: "Changer le responsable",
      CHANGE_RESPONSABLE_BUTTON: "Changer le responsable",
      RESPONSABLE_USERNAME: "Nom d'utilisateur du responsable",
      RESPONSABLE_EMAIL: "Email du responsable",
      RESPONSABLE_PHONE_NUMBER: "Numéro de téléphone",
      CONFIRM_CHANGE_MODAL_TITLE: "Confirmer le changement",
      CONFIRM_CHANGE_ALERT: "Êtes-vous sûr de vouloir changer le responsable?",
      YES_BUTTON: "Oui",
      EMAIL_RESPONSABLE_ALREADY_EXIST: "Cet email existe déjà",
      NO_BUTTON: "Non",
      RESPONSABLE_INFO_NOT_FOUND:
        "Aucune information sur le responsable trouvée",
      UPDATED_SUCESSFULLY: "Responsable mis à jour avec succès",
      INVALID_EMAIL: "Adresse e-mail invalide",
      INVALID_PHONE_NUMBER:
        "Le numéro de téléphone doit contenir uniquement des chiffres",
      INVALID_USERNAME: "Le nom d'utilisateur ne peut pas être vide",
    },
  };
  const user = useSelector((state) => state.user);
  const [saveDataLoading, setSaveDataLoading] = useState(false);
  const [changeUniveristyResponsable] =
    useChangeUniveristyResponsableMutation();
  const [responsableInfo, setResponsableInfo] = useState({
    username: "",
    email: "",
    numero: "",
    universityId: universityInfo?.university?._id,
    responsableId: universityInfo?.university?.responsable?._id,
    adminName: user?.username,
    adminMail: user?.email,
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    email: "",
    numero: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const numbersOnlyRegex = /^[0-9]+$/;
  // Validate fields on change
  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setResponsableInfo({ ...responsableInfo, [name]: value });

    let error = "";

    if (name === "email" && value && !emailRegex.test(value)) {
      error = getTranslation(translation, "INVALID_EMAIL");
    }

    if (name === "numero" && value && !numbersOnlyRegex.test(value)) {
      error = getTranslation(translation, "INVALID_PHONE_NUMBER");
    }

    if (name === "username" && value.trim() === "") {
      error = getTranslation(translation, "INVALID_USERNAME");
    }

    setFormErrors((prev) => ({ ...prev, [name]: error }));
  };

  // Check if all fields are valid and not empty
  useEffect(() => {
    const isValid =
      responsableInfo.username.trim() !== "" &&
      emailRegex.test(responsableInfo.email) &&
      numbersOnlyRegex.test(responsableInfo.numero) &&
      !formErrors.username &&
      !formErrors.email &&
      !formErrors.numero;

    setIsFormValid(isValid);
  }, [responsableInfo, formErrors]);

  const handleConfirm = () => {
    if (isFormValid) {
      setConfirm(true); // Show confirmation modal
    }
  };
  const UpdateResponsable = () => {
    setSaveDataLoading(true);
    changeUniveristyResponsable(responsableInfo)
      .unwrap()
      .then(() => {
        toastSucces(getTranslation(translation, "UPDATED_SUCESSFULLY"));
        setSaveDataLoading(false);
        reloadData();
        setConfirm(false);
        onClose();
      })
      .catch((error) => {
        if (error.data.error === "Responsable email already exist") {
          toastErrorServer(
            getTranslation(translation, "EMAIL_RESPONSABLE_ALREADY_EXIST")
          );
        } else {
          toastErrorServer(error.data.error);
        }
        setSaveDataLoading(false);
      });
  };
  /*   console.log(universityInfo); */
  return (
    <div>
      <div>
        <div className="flex items-center justify-between bg-[#285090] text-white">
          <h2 className="text-xl p-1.5 px-2 font-semibold">
            {confirm
              ? getTranslation(translation, "CONFIRM_CHANGE_MODAL_TITLE")
              : getTranslation(translation, "CHANGE_RESPONSABLE_MODAL_TITLE")}
          </h2>
          <span
            onClick={onClose}
            className="cursor-pointer text-xl p-2.5 transition ease-in-out hover:bg-red-600 hover:text-white"
          >
            <IoMdClose />
          </span>
        </div>
        {confirm ? (
          <>
            {saveDataLoading ? (
              <div className="flex justify-center items-center p-4 text-xl font-black">
                <Spinner /> <p>Updating...</p>
              </div>
            ) : (
              <>
                <div className="text-center flex justify-center items-center p-4 text-xl font-black">
                  <p>{getTranslation(translation, "CONFIRM_CHANGE_ALERT")}</p>
                </div>
                <div className="flex justify-around p-2 gap-2">
                  <button
                    className="p-2 rounded-md bg-green-600 text-white font-bold min-w-[90px] transition-all ease-in-out hover:bg-green-900"
                    onClick={UpdateResponsable}
                  >
                    {getTranslation(translation, "YES_BUTTON")}
                  </button>
                  <button
                    className="p-2 rounded-md bg-red-600 text-white font-bold min-w-[90px] transition-all ease-in-out hover:bg-red-900"
                    onClick={() => setConfirm(false)}
                  >
                    {getTranslation(translation, "NO_BUTTON")}
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="p-4 px-3 space-y-3">
              <div>
                <label htmlFor="username" className="block font-semibold">
                  {getTranslation(translation, "RESPONSABLE_USERNAME")}
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={responsableInfo?.username}
                  onChange={handleOnChange}
                  className={`w-full border ${
                    formErrors.username ? "border-red-500" : "border-gray-300"
                  } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200`}
                  placeholder={getTranslation(
                    translation,
                    "RESPONSABLE_USERNAME"
                  )}
                />
                {formErrors.username && (
                  <span className="text-red-500 text-sm">
                    {formErrors.username}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="email" className="block font-semibold">
                  {getTranslation(translation, "RESPONSABLE_EMAIL")}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={responsableInfo?.email}
                  onChange={handleOnChange}
                  className={`w-full border ${
                    formErrors.email ? "border-red-500" : "border-gray-300"
                  } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200`}
                  placeholder={getTranslation(translation, "RESPONSABLE_EMAIL")}
                />
                {formErrors.email && (
                  <span className="text-red-500 text-sm">
                    {formErrors.email}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="phone" className="block font-semibold">
                  {getTranslation(translation, "RESPONSABLE_PHONE_NUMBER")}
                </label>
                <input
                  type="text"
                  id="numero"
                  name="numero"
                  value={responsableInfo?.numero}
                  onChange={handleOnChange}
                  className={`w-full border ${
                    formErrors.numero ? "border-red-500" : "border-gray-300"
                  } rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200`}
                  placeholder={getTranslation(
                    translation,
                    "RESPONSABLE_PHONE_NUMBER"
                  )}
                />
                {formErrors.numero && (
                  <span className="text-red-500 text-sm">
                    {formErrors.numero}
                  </span>
                )}
              </div>
            </div>

            {/* Action Button */}
            <div className="flex justify-end p-3">
              <button
                onClick={handleConfirm}
                disabled={responsableInfo.email === "" || !isFormValid}
                className={`py-2 px-4 rounded-md transition ${
                  isFormValid
                    ? "bg-[#285090] text-white hover:bg-[#1c3c73]"
                    : "bg-gray-400 text-gray-700 cursor-not-allowed"
                }`}
              >
                {getTranslation(translation, "CHANGE_RESPONSABLE_BUTTON")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ChangeResponsable.propTypes = {
  onClose: PropTypes.func.isRequired,
  universityInfo: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
};
