import React from "react";

import PropTypes from "prop-types";

export default function ContactItem({ title, description, icon }) {
  return (
    <div className="p-2 group">
      <div>
        <div className="flex items-center justify-between text-[#010483] gap-2">
          <div>
            <h1 className="text-2xl font-bold">{title}</h1>
            <p>{description}</p>
          </div>
          <div className="flex justify-center items-center my-4">
            <span className="p-3 text-xl bg-[#f0f0ff] text-[#3f43fd] font-black border-white rounded-full  ease-in-out delay-150 transition-all duration-500 group-hover:bg-[#1acc8d] group-hover:text-white">
              {icon}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
ContactItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
