import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import React Icons for pagination arrows
import { PiStudentFill } from "react-icons/pi";
import { GiDiploma } from "react-icons/gi";
import { FaUniversity } from "react-icons/fa";
import { getTranslation } from "../../utils/translate/translation";
import Spinner from "../../components/spinner/Spinner";
export default function StudentAndDiplomasNumber({
  studentsAndDiplomas,
  loading,
}) {
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(0); // Track the current page

  // Calculate the start and end index for the items to display
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current items for the current page
  const currentItems = studentsAndDiplomas.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(studentsAndDiplomas.length / itemsPerPage);
  const translate = {
    ar: {
      NUMBER_OF_DIPLOMAS_AND_STUDENTS_BY_UNIVERSITY:
        "عدد الشهادات والطلاب حسب الجامعة",
      UNIVERSITY: "الجامعة",
      DIPLOMAS: "الشهادات",
      STUDENTS: "الطلاب",
      NO_ITEMS_TO_DISPLAY: "لا توجد عناصر لعرضها",
      PREVIOUS: "السابق",
      NEXT: "التالي",
    },
    en: {
      NUMBER_OF_DIPLOMAS_AND_STUDENTS_BY_UNIVERSITY:
        "Number of diplomas and students by University",
      UNIVERSITY: "University",
      DIPLOMAS: "Diplomas",
      STUDENTS: "Students",
      NO_ITEMS_TO_DISPLAY: "No items to display",
      PREVIOUS: "Previous",
      NEXT: "Next",
    },
    fr: {
      NUMBER_OF_DIPLOMAS_AND_STUDENTS_BY_UNIVERSITY:
        "Nombre de diplômes et d'étudiants par université",
      UNIVERSITY: "Université",
      DIPLOMAS: "Diplômes",
      STUDENTS: "Étudiants",
      NO_ITEMS_TO_DISPLAY: "Aucun élément à afficher",
      PREVIOUS: "Précédent",
      NEXT: "Suivant",
    },
  };
  return (
    <div className="mx-2 my-2">
      <div className="bg-[#285090] border-b-2 border-gray-100/50 text-center text-white font-bold p-2 px-3 mt-4 rounded-t-md">
        <p>
          {getTranslation(
            translate,
            "NUMBER_OF_DIPLOMAS_AND_STUDENTS_BY_UNIVERSITY"
          )}
        </p>
      </div>
      <div>
        <table className="w-full">
          <thead>
            <tr className="bg-[#285090] text-white">
              <th className="p-2 text-center">
                {getTranslation(translate, "UNIVERSITY")}
              </th>
              <th className="p-2 text-center">
                {getTranslation(translate, "DIPLOMAS")}
              </th>
              <th className="p-2 text-center">
                {getTranslation(translate, "STUDENTS")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="3" className="p-2 text-center bg-white">
                  <div className="flex w-full items-center justify-center my-4">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
            {
              // Display a message if there are no items
              studentsAndDiplomas.length === 0 && loading === false && (
                <tr>
                  <td colSpan="3" className="p-2 text-center bg-white">
                    {getTranslation(translate, "NO_ITEMS_TO_DISPLAY")}
                  </td>
                </tr>
              )
            }
            {currentItems &&
              currentItems.map((item, index) => (
                <tr
                  key={startIndex + index}
                  className={
                    index % 2 === 0
                      ? "bg-[#f1f1f1] text-[#285090]"
                      : "bg-white text-[#285090]"
                  }
                >
                  <td className="p-2 text-center">
                    <div className="flex items-center justify-start gap-2">
                      <FaUniversity className="inline-block" />
                      {item.universityName}
                    </div>
                  </td>
                  <td className="p-2 text-center">
                    <div className="flex items-center justify-center gap-2">
                      <GiDiploma className="inline-block" />
                      {item.numberOfDiplomas}
                    </div>
                  </td>
                  <td className="p-2 text-center">
                    <div className="flex items-center justify-center gap-2">
                      <PiStudentFill className="inline-block" />
                      {item.numberOfStudents}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-2">
        <button
          className="flex items-center bg-[#1a237e] text-white rounded px-2 py-1 disabled:opacity-50"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          <FaChevronLeft className="mr-1" />
          {getTranslation(translate, "PREVIOUS")}
        </button>
        <span>
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          className="flex items-center bg-[#1a237e] text-white rounded px-2 py-1 disabled:opacity-50"
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
          }
          disabled={currentPage === totalPages - 1}
        >
          {getTranslation(translate, "NEXT")}
          <FaChevronRight className="ml-1" />
        </button>
      </div>
    </div>
  );
}

StudentAndDiplomasNumber.propTypes = {
  studentsAndDiplomas: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
