/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import QRCode from "react-qr-code";
// import QRCode from "react-qr-code";
// import QRCode from "react-qr-code";
import {
  cameraIcon,
  codeIcon,
  qrCodeIcond,
  searchIcon,
} from "../../assets/svg/Index";
import { CiLink } from "react-icons/ci";
import Input from "../../components/input/Input";
import Spinner from "../../components/spinner/Spinner";
import { useVerifyDiplomaMutation } from "../../store/service/diplomasService";
import certifiedLogo from "../../assets/certified.png";
import ErrorBoundary from "../../utils/ErrorBoundary";
import QRScan from "./qr-reder.component";
import { getTranslation } from "../../utils/translate/translation";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../utils/translate/multilanguage-reader";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
function ThirdPartyPage() {
  let [verify] = useVerifyDiplomaMutation();
  const [spinnerActive, setSpinnerActive] = useState(false);

  const [result, setResult] = useState("");
  const [idDiploma, setIdDiploma] = useState("");
  const [hashIpfs, setHashIpfs] = useState("");
  const [diploma, setDiploma] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [diplomaNotExist, setDiplomaNotExist] = useState(false);
  const [revoke, setRevoke] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [responsableAddress, setResponsableAddress] = useState("");
  const searchDiploma = () => {
    setSpinnerActive(true);
    let data = {
      diplomaId: idDiploma,
    };
    verify(data).then((res) => {
      try {
        setResponsableAddress(res.data.responsable);

        setRevoke(res.data.revoked);
        setDiploma(res.data.data);
        setResult("yes");
        setDiplomaNotExist(true);
        if (res.data.data.hashIpfs) {
          setHashIpfs(res.data.data.hashIpfs);
          setSpinnerActive(false);
        } else {
          setHashIpfs(null);
          setSpinnerActive(false);
        }
      } catch (error) {
        setDiplomaNotExist(true);
      }
    });
    setHashIpfs(null);

    setResult("");
    setIdDiploma("");
  };
  useEffect(() => {
    if (idDiploma !== "") {
      searchDiploma();
    }
  }, [diploma]);
  const diplomasDigital = (id) => {
    window.open(`https://saddakni.alecso.org/digital_diploma/${id}`);
  };
  const fantomLink = () => {
    window.open(`https://testnet.ftmscan.com/address/${responsableAddress}`);
  };
  // eslint-disable-next-line no-unused-vars
  const translations = {
    en: {
      VERIFICATION_TITLE: "Verify Diploma:",
      WRITE_DIPLOMA_ID: "Enter the Diploma ID",
      TYPE: "Type Diploma:",
      SEARCH: "Search",
      CAMERA_OPEN: "Open Camera",
      CAMERA_CLOSE: "Close Camera",
      METADATA: "Metadata",
      ACADEMIC_YEAR: "Academic Year:",
      SPECIALITY: "Speciality:",
      AVERAGE: "Average / Total:",
      REMARK: "Remark:",
      QR_CODE: "QR Code",
      VERIFY_LINK: "Verification Link",
      CERTIFIED_MESSAGE: "The diploma has been verified",
      CERTIFIED_IMAGE_ALT: "Certified",
      ORG_SPACE: "Organization Space",
      EXPLICATION:
        "You can write the certificate identifier located above the QR-CODE image, and the system will search for it.",
      DIPLOMA_ID: "Diploma ID",
      SCAN_QR_CODE: "Scan the QR-Code using the camera.",
      OPEN_CAMERA_METHOD:
        "You can open the camera on your phone or computer and capture the QR-Code displayed on the certificate",
      SEARCH_RESULTS: "Search results",
      DIPLOMA_PHOTO_ON_BLOCKCHAIN: "Diploma Photo on Blockchain :",
      THIS_DIPLOMA_IS_NOT_FOUND: "This diploma is not found ",
      DIPLOMA_IS_VALID: "Diploma is valid ",
      DIPLOMA_NOT_EXIST: "Diploma not exist",
      DIPLOMA_IS_CONFIRM_BY: "Confirmed by",
      DIPLOMA_IS_REVOKE: "This certificate has been withdrawn",
    },
    ar: {
      VERIFICATION_TITLE: "التحقق من الشهادة",
      WRITE_DIPLOMA_ID: "أدخل معرّف الشهادة",
      SEARCH: "بحث",
      CAMERA_OPEN: "افتح الكاميرا",
      CAMERA_CLOSE: "أغلق الكاميرا",
      METADATA: "البيانات الوصفية",
      ACADEMIC_YEAR: "السنة الجامعية:",
      SPECIALITY: "الاختصاص:",
      AVERAGE: "المعدّل / المجموع:",
      REMARK: "الملاحظة:",
      TYPE: "نوع الشهادة:",
      QR_CODE: "رمز الاستجابة السريعة (QR)",
      VERIFY_LINK: "رابط التحقق",
      CERTIFIED_MESSAGE: "تم التثبت من صحة الشهادة",
      CERTIFIED_IMAGE_ALT: "معتمد",
      ORG_SPACE: "فضاء المؤسسة",
      EXPLICATION:
        "   يمكنك كتابة معرّف الشهادة الموجود فوق رسم الـ QR-CODE وسيقوم  النظام بالبحث عليه.",
      DIPLOMA_ID: "معرّف الشهادة",
      SCAN_QR_CODE: "  قراءة الQR-Code بواسطة الكاميرا",
      OPEN_CAMERA_METHOD:
        " يمكنك فتح كاميرا الهاتف أو الحاسوب وتصوير رسم الـQR-CODE  الموجود بالشهادة.",
      SEARCH_RESULTS: "نتائج البحث",
      DIPLOMA_PHOTO_ON_BLOCKCHAIN: "صورة الشهادة على البلوكتشين :",
      THIS_DIPLOMA_IS_NOT_FOUND: "هذه الشهادات غير موجودة ",
      DIPLOMA_IS_VALID: "تم التثبت من صحة الشهادة ",
      DIPLOMA_NOT_EXIST: "الشهادة غير موجودة",
      DIPLOMA_IS_CONFIRM_BY: "مصادقة من طرف",
      DIPLOMA_IS_REVOKE: "هذه الشهادة سحبت",
    },
    fr: {
      VERIFICATION_TITLE: "Vérifier le Diplôme :",
      WRITE_DIPLOMA_ID: "Entrez l'identifiant du diplôme",
      SEARCH: "Rechercher",
      CAMERA_OPEN: "Ouvrir la caméra",
      CAMERA_CLOSE: "Fermer la caméra",
      METADATA: "Métadonnées",
      ACADEMIC_YEAR: "Année académique :",
      SPECIALITY: "Spécialité :",
      AVERAGE: "Moyenne / Total :",
      REMARK: "Remarque :",
      QR_CODE: "Code QR",
      VERIFY_LINK: "Lien de vérification",
      CERTIFIED_MESSAGE: "Le diplôme a été vérifié",
      CERTIFIED_IMAGE_ALT: "Certifié",
      ORG_SPACE: "Espace de l'organisation",
      EXPLICATION:
        "Vous pouvez écrire l'identifiant du certificat situé au-dessus de l'image QR-CODE, et le système le recherchera.",
      DIPLOMA_ID: "Identifiant du diplôme",
      SCAN_QR_CODE: "Scanner le QR-Code à l'aide de l'appareil photo.",
      OPEN_CAMERA_METHOD:
        "Vous pouvez ouvrir l'appareil photo de votre téléphone ou ordinateur et capturer le QR-Code affiché sur le certificat.",
      SEARCH_RESULTS: "Résultats de recherche",
      DIPLOMA_PHOTO_ON_BLOCKCHAIN: "Photo du diplôme sur la blockchain :",
      THIS_DIPLOMA_IS_NOT_FOUND: "Ce diplôme n'est pas trouvé ",
      DIPLOMA_IS_VALID: "Le diplôme est valide ",
      DIPLOMA_NOT_EXIST: "Diplôme inexistant",
      TYPE: "Type Diplome:",
      DIPLOMA_IS_CONFIRM_BY: "Confirmé de la part",
      DIPLOMA_IS_REVOKE: "Ce certificat a été retiré",
    },
  };

  return (
    <div className="flex flex-col lg:justify-start w-full xl:h-[100vh] sm:h-screen bg-[#f2f7ff] ">
      <div className="flex gap-2 items-center bg-white p-4">
        <Link
          to="/"
          className="flex items-center justify-center group  text-white transition-all duration-700 ease-in-out bg-blue-800 p-2 border-2 border-blue-800 rounded-md hover:!text-blue-800 hover:!bg-white"
        >
          <span className="mr-2">
            {" "}
            <IoMdArrowRoundBack />{" "}
          </span>
        </Link>
        <h1 className="text-2xl font-bold text-[#25396f]">
          {" "}
          {getTranslation(translations, "ORG_SPACE")}
        </h1>
      </div>
      <div className="p-4">
        <p className="text-xl font-bold text-[#607080] my-2">
          {getTranslation(translations, "VERIFICATION_TITLE")}
        </p>
        <div className="grid grid-cols-1 justify-between items-center gap-4 p-2 lg:grid-cols-2">
          <div className="rounded-xl bg-white w-full md:w-[auto] p-4 sm:h-[auto] lg:h-56 md:h-60 flex items-start justify-end">
            <div className="mx-2 ml-4 mr-10 bg-[#57caeb] w-30 h-30 rounded-md">
              {codeIcon}
            </div>
            <div className="flex flex-col ">
              <p className="text-[#7c8db5]">
                {getTranslation(translations, "WRITE_DIPLOMA_ID")}
              </p>
              <p>{getTranslation(translations, "EXPLICATION")}</p>
              <div className="flex flex-col items-end justify-end mt-2">
                <div className="w-full">
                  <Input
                    placeholder={getTranslation(translations, "DIPLOMA_ID")}
                    value={idDiploma}
                    inputChange={setIdDiploma}
                  />
                </div>
                <button
                  onClick={searchDiploma}
                  className="flex items-center justify-center w-full h-8 space-x-2 text-white bg-[#0dcaf0] rounded-md "
                >
                  {searchIcon}
                  <p> {getTranslation(translations, "SEARCH")}</p>
                </button>
              </div>
            </div>
          </div>
          <div className="rounded-xl bg-white w-full md:w-[auto] p-4 lg:h-56 sm:h-[auto] md:h-60 flex items-start justify-start">
            <div className="mx-2 ml-4 mr-10 bg-[#9694ff] w-30 h-30 rounded-md">
              {qrCodeIcond}
            </div>
            <ErrorBoundary>
              <div className="flex flex-col ">
                {showCamera ? (
                  <QRScan
                    result={result}
                    setResult={setResult}
                    setShowCamera={setShowCamera}
                  />
                ) : (
                  <div>
                    <p className="text-[#7c8db5]">
                      {getTranslation(translations, "SCAN_QR_CODE")}
                    </p>
                    <p>{getTranslation(translations, "OPEN_CAMERA_METHOD")}</p>
                  </div>
                )}

                <div className="flex flex-col items-end justify-end mt-2">
                  <div className="w-full"></div>
                  {showCamera ? (
                    <button
                      onClick={() => {
                        setShowCamera(false);
                      }}
                      className="flex items-center justify-center w-full h-8 space-x-2 text-white rounded-sm bg-deep-purple-300 "
                    >
                      {cameraIcon}
                      <p> {getTranslation(translations, "CAMERA_CLOSE")}</p>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setShowCamera(true);
                      }}
                      className="flex items-center justify-center w-full h-8 space-x-2 text-white bg-[#9694ff] rounded-md "
                    >
                      {cameraIcon}
                      <p> {getTranslation(translations, "CAMERA_OPEN")}</p>
                    </button>
                  )}
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex md:flex-row  items-start justify-between w-full p-4">
        <div className="flex flex-col justify-start w-full h-full mt-8 md:gap-4 sm:gap-5">
          {diploma &&
          result === "yes" &&
          isThereCommunValues(
            Object.keys(diploma?.type),
            Object.keys(diploma?.student?.firstName)
          ) ? (
            <div className="flex flex-col items-start  min-h-[500px] h-fit w-full bg-white rounded-xl ">
              <h2 className="mb-8 text-xl font-bold bg-[#25396f] text-white w-full p-3 rounded-t-lg">
                {getTranslation(translations, "SEARCH_RESULTS")}
              </h2>
              {!revoke && (
                <div className="flex w-full justify-center items-center p-4">
                  <div className="flex items-center border-2 px-3 p-2 rounded-full">
                    <img
                      src={certifiedLogo}
                      alt="certified"
                      className="w-10 h-10"
                    />
                    <span className="font-bold text-green-500 lg:text-4xl md:text-xl sm:text-sm">
                      {getTranslation(translations, "DIPLOMA_IS_VALID")}
                    </span>
                  </div>
                </div>
              )}
              {!revoke && (
                <div className="pt-2 w-full  text-center">
                  <span className="text-2xl font-medium text-[#25396f]">
                    {getTranslation(translations, "DIPLOMA_IS_CONFIRM_BY")}{" "}
                    <span
                      className="text-lg font-semibold cursor-pointer text-[#25396f]"
                      onClick={() => fantomLink()}
                    >
                      {responsableAddress}
                    </span>
                  </span>
                </div>
              )}
              <div className="h-full p-4 sm:justify-center xl:flex xl:w-full xl:justify-between lg:w-full lg:flex md:flex sm:grid sm:gap-2 sm:w-full">
                <div className="flex flex-col items-center justify-between w-full border-2 rounded-md">
                  <span className="w-full text-md text-center font-medium text-white bg-black p-2 rounded-t-md">
                    {getTranslation(translations, "METADATA")}
                  </span>

                  <div className="w-full p-2 mb-4">
                    <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          {" "}
                          {getTranslation(translations, "SPECIALITY")}{" "}
                        </span>
                        <span className="mr-2 text-black">
                          {" "}
                          {multilanguageReader(diploma?.speciality)}
                        </span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          {" "}
                          {getTranslation(translations, "TYPE")}{" "}
                        </span>
                        <span className="mr-2 text-black">
                          {" "}
                          {multilanguageReader(diploma?.type)}
                        </span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          {getTranslation(translations, "ACADEMIC_YEAR")}
                        </span>
                        <span className="mr-2 text-black">{diploma?.year}</span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          {getTranslation(translations, "AVERAGE")}
                        </span>
                        <span className="mr-2 text-black">
                          {diploma?.average}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="flex w-full py-4 space-x-2">
                      <div className="flex items-start text-sm font-bold ">
                        <span className="mr-4 text-gray-700">
                          {getTranslation(translations, "REMARK")}
                        </span>
                        <span className="mr-2 text-black">
                          {" "}
                          {multilanguageReader(diploma?.honors)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center w-full ">
                  {diploma?.mode === "modeTwo" && (
                    <>
                      <span className="text-xl font-semibold text-blue-900">
                        Code QR
                      </span>
                      <QRCode
                        id="qr-gen"
                        size={300}
                        // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`https://saddakni.alecso.org/verification/${diploma?._id}`}
                        viewBox={`0 0 256 256`}
                      />
                      <span
                        className="text-md font-semibold cursor-pointer text-[#25396f]"
                        onClick={() =>
                          window.open(
                            `https://saddakni.alecso.org/verification/${diploma._id}`
                          )
                        }
                      >
                        {getTranslation(translations, "VERIFY_LINK")}
                      </span>
                    </>
                  )}
                  {diploma?.mode === "modeOne" && (
                    <>
                      <span className="text-lg text-blue-900">
                        {getTranslation(
                          translations,
                          "DIPLOMA_PHOTO_ON_BLOCKCHAIN"
                        )}
                      </span>
                      {spinnerActive && <Spinner />}

                      {hashIpfs && (
                        <div className="flex items-center justify-center w-full max-w-[20px] rounded-t-xl thirdParty">
                          <Document
                            file={
                              process.env.REACT_APP_URL + "/ipfs/" + hashIpfs
                            }
                            className="flex items-center justify-center"
                          >
                            <Page pageNumber={1} />
                          </Document>
                        </div>
                      )}

                      {hashIpfs === null && (
                        <span>
                          {getTranslation(
                            translations,
                            "THIS_DIPLOMA_IS_NOT_FOUND"
                          )}{" "}
                        </span>
                      )}
                    </>
                  )}
                </div>
                {diploma?.mode === "modeThree" &&
                  diploma?.status === "accepted" && (
                    <div className="flex flex-col items-center justify-center w-full gap-2">
                      <>
                        <span className="text-xl font-semibold text-blue-900">
                          Code QR
                        </span>
                        <div className="border-4 p-1">
                          <QRCode
                            id="qr-gen"
                            size={300}
                            // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`https://saddakni.alecso.org/digital_diploma/${diploma?._id}`}
                            viewBox={`0 0 300 310`}
                          />
                        </div>
                        <span
                          className="flex items-center text-md font-semibold cursor-pointer text-white bg-green-600 p-2 rounded-full hover:bg-green-800"
                          onClick={() => diplomasDigital(diploma._id)}
                        >
                          <CiLink className="text-2xl" />
                          {getTranslation(translations, "VERIFY_LINK")}{" "}
                        </span>
                      </>
                    </div>
                  )}
              </div>
              {revoke ? (
                <div className="flex flex-col items-center justify-center w-full mt-20">
                  <span className="font-bold text-red-500 lg:text-4xl md:text-xl sm:text-sm">
                    {getTranslation(translations, "DIPLOMA_IS_REVOKE")}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10 bg-red-500 rounded-full"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                    />
                  </svg>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <>
              {diplomaNotExist && (
                <span className="text-3xl font-semibold">
                  {getTranslation(translations, "DIPLOMA_NOT_EXIST")}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ThirdPartyPage;
