import React from "react";
import PropTypes from "prop-types";

export default function SectionTitle({ title, p }) {
  return (
    <div
      className="p-2 py-8 container mx-auto"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-once="true"
      data-aos-duration="1200"
      data-aos-mirror="false"
    >
      <div className="relative flex gap-8">
        <h3 className="text-start uppercase font-medium tracking-[2px] text-[14px] leading-[1px] m-0 mb-[5px] text-[#aaaaaa] font-tajawal-poppins relative">
          {title}
        </h3>
        <div className="max-w-[150px] w-4/5 m-0 mb-[5px] relative before:content-[''] before:absolute before:w-1/2 before:h-[1px] before:bg-[#1acc8d] before:bottom-[0] before:left-0 before:z-[-1]"></div>
      </div>

      <h1 className="uppercase text-blue text-4xl text-[#010483] font-bold my-4">
        {p}
      </h1>
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  p: PropTypes.string.isRequired,
};
