import React, { useState } from "react";
import PropTypes from "prop-types";
import NavigationBar from "../coreUI/NavigationBar/NavigationBar";
import ProtectedRoute from "../utils/ProtectedRouters/ProtectedRouter";
import Header from "./Header";

function Layout({ children }) {
  const [show, setShow] = useState(false);
  return (
    <ProtectedRoute>
      <div className="flex items-start justify-between w-full h-full ">
        <NavigationBar show={show} setShow={setShow} />

        <main className="w-full h-full min-h-screen bg-[#f2f7ff] !overflow-auto">
          <Header show={show} setShow={setShow} />
          {children}
        </main>
      </div>
    </ProtectedRoute>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
