import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";

import {
  creditIcon,
  mailIcon,
  mobileIcon,
  univestyIcon,
  userIcon,
} from "../../assets/svg/Index";
import SelectCountry from "../../components/input/SelectCountry";
import { clearLocalStorage } from "../../utils/logout";
import { toastErrorServer } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";
import SelectInvitationLanguage from "../../components/input/SelectInvitationLanguage";
import translate from "./translation";
import AddUniversityModeSelector from "./AddUniversityModeSelector";
import { useCreateUniversityMutation } from "../../store/service/universityService";

function AddUniversityPage() {
  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [credit, setCredit] = useState("");
  const [isCreditValid, setIsCreditValid] = useState(false);
  const [universityName, setUniversityName] = useState("");
  const [country, setCountry] = useState("");
  const [isUniversityNameValid, setIsUniversityNameValid] = useState(false);
  const [selectedInvitationLanguage, setSelectedInvitationLanguage] =
    useState("en");

  const [isLoading, setIsLoading] = useState(false);
  const [createUniversity] = useCreateUniversityMutation();

  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [selectedMode, setSelectedMode] = useState({
    modeOne: true,
    modeTwo: true,
    modeThree: true,
  });
  const notify = (msg, type) => {
    if (type === "info") {
      toast.info(msg, {
        position: "top-left",
      });
    } else {
      toast.error(msg, {
        position: "top-left",
      });
    }
  };

  const addUser = () => {
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      clearLocalStorage();
      toastErrorServer(
        `${getTranslation(translate, "TOAST_ERROR_TOKEN_EXPIRED")}`
      );
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setIsLoading(true);
      if (name === "" || email === "" || phoneNumber === "" || credit === "") {
        toastErrorServer(
          `${getTranslation(translate, "TOAST_ERROR_METADATA")}`
        );
      } else {
        let data = {
          username: name,
          email: email,
          adminName: user.username,
          adminMail: user.email,
          universityName: universityName,
          numero: phoneNumber,
          country,
          credit,
          mode: selectedMode,
          invitationLanguage: selectedInvitationLanguage,
        };
        return createUniversity(data).then((res) => {
          if (res.data) {
            setIsLoading(false);
            setEmail("");
            setName("");
            setPhoneNumber("");
            setUniversityName("");
            setCountry("");
            setCredit("");
            notify(`${getTranslation(translate, "TOAST_SUCCESS")}`, "info");
          }
          if (res.error.status === 400) {
            setIsLoading(false);
            console.log(res.error);
            if (res.error.data.error === "Responsable email already exist") {
              notify(
                `${getTranslation(
                  translate,
                  "EMAIL_RESPONSABLE_ALREADY_EXIST"
                )}`,
                "info"
              );
            }
            if (res.error.data.error === "University name already exist") {
              notify(
                `${getTranslation(translate, "UNIVERSITY_NAME_ALREADY_EXIST")}`,
                "info"
              );
            }
          } else {
            setIsLoading(false);
            notify(
              `${getTranslation(translate, "TOAST_ERROR_SERVER_")}`,
              "error"
            );
          }
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full p-4 space-y-2">
      <h1 className="mt-10 text-2xl font-bold text-[#25396f]">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <p className="text-gray-600">{getTranslation(translate, "MESSAGE_")}</p>
      <div className="flex flex-col items-start justify-center w-full p-8 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "NAME")}
          </p>
          <Input
            icon={userIcon}
            type={"NAME"}
            placeholder={getTranslation(translate, "PLACEHOLDER_NAME")}
            inputChange={setName}
            value={name}
            setValid={setIsNameValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "EMAIL")}
          </p>
          <Input
            icon={mailIcon}
            type={"EMAIL"}
            placeholder={getTranslation(translate, "PLACEHOLDER_EMAIL")}
            inputChange={setEmail}
            value={email}
            setValid={setIsEmailValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "PHONE")}
          </p>
          <Input
            icon={mobileIcon}
            type={"PHONE_NUMBER"}
            placeholder={getTranslation(translate, "PLACEHOLDER_PHONE")}
            inputChange={setPhoneNumber}
            value={phoneNumber}
            setValid={setIsPhoneNumberValid}
          />
        </div>

        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "PLACEHOLDER_UNIVERSITY")}{" "}
          </p>
          <Input
            icon={univestyIcon}
            type={"University"}
            placeholder={getTranslation(translate, "PLACEHOLDER_UNIVERSITY")}
            inputChange={setUniversityName}
            value={universityName}
            setValid={setIsUniversityNameValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "CREDIT")}{" "}
          </p>
          <Input
            icon={creditIcon}
            type={"NUMBER"}
            placeholder={getTranslation(translate, "PLACEHOLDER_CREDIT")}
            inputChange={setCredit}
            value={credit}
            setValid={setIsCreditValid}
          />
        </div>

        <div className="w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(
              translate,
              "SELECT_INVITATION_EMAIL_TEMPLATE_LANGUAGE"
            )}{" "}
          </p>
          <SelectInvitationLanguage
            setSelectedInvitationLanguage={setSelectedInvitationLanguage}
            selectedInvitationLanguage={selectedInvitationLanguage}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "COUNTRY")}{" "}
          </p>
          <SelectCountry country={country} setCountry={setCountry} />
        </div>
        <div className="flex flex-col justify-center w-full items-CENTER">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "MODE_SELECTED")}{" "}
          </p>
          <AddUniversityModeSelector
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
          />
        </div>
        <div className="flex items-center justify-center w-full">
          <div className="lg:w-1/3 sm:w-full md:w-1/3">
            <MainButton
              isLoading={isLoading}
              action={addUser}
              title={getTranslation(translate, "BUTTON_TITLE")}
              disabled={
                !isNameValid ||
                !isEmailValid ||
                !isPhoneNumberValid ||
                !isCreditValid ||
                !isUniversityNameValid ||
                country === ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUniversityPage;
