import React from "react";
import PropTypes from "prop-types";

export default function FooterList({
  ListItems,
  ListTitle,
  itemIcon,
  navigate,
}) {
  // Scroll handler to scroll to a section without using the hash in the URL
  const handleScrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <h2 className="font-bold">{ListTitle}</h2>
      <ul className="p-4">
        {ListItems.map((item, index) => (
          <li className="my-4 flex items-center gap-2 text-sm" key={index}>
            {itemIcon}{" "}
            {item.type === "external" ? (
              // External link opens in a new tab
              <a
                href={item.Link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:!text-[#198754]" // Green on hover
              >
                {item.text}
              </a>
            ) : item.type === "hash" ? (
              // Custom scroll to section without the # in the URL
              <a
                href="/"
                onClick={(e) =>
                  handleScrollToSection(e, item.Link.replace("#", ""))
                } // Remove the # for ID matching
                className="text-white hover:!text-[#198754]" // Green on hover
              >
                {item.text}
              </a>
            ) : (
              // Internal navigation using navigate prop
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(item.Link); // Use navigate function
                }}
                className="text-white transition-all ease-in-out hover:!text-[#198754]" // Green on hover
              >
                {item.text}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

FooterList.propTypes = {
  ListTitle: PropTypes.string.isRequired,
  itemIcon: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired, // Navigate function passed as a prop
  ListItems: PropTypes.arrayOf(
    PropTypes.shape({
      Link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["internal", "external", "hash"]).isRequired, // Specify the type of link
    })
  ).isRequired,
};
