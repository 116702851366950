import { multilanguageReader } from "../translate/multilanguage-reader";
/**
 * @function filterByCriteria
 * @description this function search agent by criteria
 *
 * @param {*} demandes
 * @param {*} searchBy
 * @param {*} searchInput
 *
 * @returns List Filter by criteria
 */
const filterByCriteria = (demandes, searchBy, searchInput) => {
  const _filtered = demandes.filter(
    (demande) =>
      (searchBy.includes("fullName") &&
        multilanguageReader(demande?.curentStudentData?.firstName)
          .toLowerCase()
          .includes(searchInput.toLowerCase())) ||
      multilanguageReader(demande?.curentStudentData?.lastName)
        .toLowerCase()
        .includes(searchInput.toLowerCase())
  );
  return _filtered;
};

/**
 * @function filterList
 * @description this function is to filter the list User by 2 method
 *
 * @param {*} selectedFilterInputs
 * @param {*} setDemande
 * @param {*} demandes
 * @param {*} searchInput
 * @param {*} setSearchInput
 *
 * @returns list filter
 */

const filterList = (
  selectedFilterInputs,
  setDemande,
  demandes,
  searchInput,
  setSearchInput
) => {
  if (selectedFilterInputs.length > 0) {
    for (let i = 0; i < selectedFilterInputs.length; i++) {
      if (selectedFilterInputs[i].includes("fullName")) {
        const filteredSearchInputs = filterByCriteria(
          demandes,
          selectedFilterInputs,
          searchInput
        );
        setDemande(filteredSearchInputs);
      }
    }
  } else if (setSearchInput !== "") {
    const _filtered = demandes?.filter((demande) =>
      multilanguageReader(demande?.curentStudentData?.firstName)
        ?.toLowerCase()
        .includes(searchInput.toLowerCase())
    );
    setDemande(_filtered);
  }
};

export { filterList, filterByCriteria };
