import React from "react";

import PropTypes from "prop-types";

function TruncatedCell({ content }) {
  return (
    <td
      className="px-6 py-2 max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis"
      title={typeof content === "string" ? content : ""}
    >
      {content}
    </td>
  );
}

TruncatedCell.propTypes = {
  content: PropTypes.node.isRequired,
};

export default TruncatedCell;
