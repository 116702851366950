import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Badge from "../badge/Badge";
import { Tooltip } from "@material-tailwind/react";
function NavigationBarButton({
  title,
  action,
  path,
  icon,
  language,
  isBadgeActive,
  showCompletesidebar,
  logoutItem,
}) {
  const nbDiploma = useSelector((state) => state.diploma.countDiploma);
  const buttonContainerStyles = `relative ${
    logoutItem && "hover:bg-[#FF0000] hover:text-white"
  } cursor-pointer w-77 h-12 flex rounded font-medium ${
    !showCompletesidebar && "justify-center"
  } ${
    location.pathname === path
      ? "bg-[#435ebe] text-white"
      : "hover:bg-[#f0f1f5] text-[#25396f]"
  }`;
  const buttonStyles = `flex items-center gap-1 ${
    language === "ar" ? "pr-1" : "pl-1"
  }`;
  const titleStyles = `flex w-full items-center ${
    showCompletesidebar ? "gap-4 p-2" : ""
  }`;
  return (
    <>
      {showCompletesidebar ? (
        <div onClick={() => action(path)} className={buttonContainerStyles}>
          <div className={buttonStyles}>
            <span className="text-2xl">{icon}</span>
            <h1 className={titleStyles}>
              <span>{title}</span>
              {isBadgeActive && <Badge activeNumber={nbDiploma} />}
            </h1>
          </div>
        </div>
      ) : (
        <Tooltip
          placement="right"
          color="light"
          content={title}
          className="h-8"
        >
          <div onClick={() => action(path)} className={buttonContainerStyles}>
            <div className={buttonStyles}>
              <span className="text-2xl">{icon}</span>
              <h1 className={titleStyles}>
                {isBadgeActive && <Badge activeNumber={nbDiploma} />}
              </h1>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
}

NavigationBarButton.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
  path: PropTypes.string,
  icon: PropTypes.element,
  language: PropTypes.string,
  isBadgeActive: PropTypes.bool,
  showCompletesidebar: PropTypes.bool,
  logoutItem: PropTypes.bool,
};

export default NavigationBarButton;
