/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    ERROR_PASSWORD: "6 أحرف كحد أدنى مع رقم واحد على الأقل.",
    ERROR_NUMBER: " الرجاء إدخال رقم صحيح ",
    ERROR_EMAIL: "الرجاء إدخال عنوان صحيح",
    ERROR_NAME: " الرجاء إدخال حرفين على الأقل و عدم إدخال أرقام",
    ERROR_DATE: "الرجاء إدخال تاريخ صحيح",
    ERROR_NIN: "الرجاء إدخال حروف وأرقام ",
    ERROR_PHONE_NUMBER: "الرجاء إدخال رقم هاتف صحيح",
  },
  en: {
    ERROR_PASSWORD: "Minimum 6 characters with at least one number.",
    ERROR_NUMBER: " Please enter a valid number",
    ERROR_EMAIL: "Please enter a valid email.",
    ERROR_NAME: " Please enter at least two letters and no numbers",
    ERROR_DATE: "Please enter a valid date",
    ERROR_NIN: "Please enter letters and numbers",
    ERROR_PHONE_NUMBER: "Please enter a valid phone number",
  },
  fr: {
    ERROR_PASSWORD: "Minimum 6 caractères avec au moins un chiffre.",
    ERROR_NUMBER: " S'il vous plait, entrez un nombre valide",
    ERROR_EMAIL: "Veuillez entrer au moins deux lettres et aucun chiffre",
    ERROR_NAME: " Veuillez entrer au moins deux lettres et aucun chiffre",
    ERROR_DATE: "veuillez entrer une date valide",
    ERROR_NIN: "Veuillez entrer des lettres et des chiffres",
    ERROR_PHONE_NUMBER: "Veuillez entrer un numéro de téléphone valide",
  },
};
function InputErrorMessage({ type }) {
  const errorMessages = {
    // eslint-disable-next-line no-undef
    password: getTranslation(translate, "ERROR_PASSWORD"),
    // eslint-disable-next-line no-undef
    number: getTranslation(translate, "ERROR_NUMBER"),
    // eslint-disable-next-line no-undef
    email: getTranslation(translate, "ERROR_EMAIL"),
    // eslint-disable-next-line no-undef
    name: getTranslation(translate, "ERROR_NAME"),
    // eslint-disable-next-line no-undef
    date: getTranslation(translate, "ERROR_DATE"),
    // eslint-disable-next-line no-undef
    nin: getTranslation(translate, "ERROR_NIN"),
    // eslint-disable-next-line no-undef
    phoneNumber: getTranslation(translate, "ERROR_PHONE_NUMBER"),
  };

  return (
    <>
      {type === "PASSWORD" && (
        <div data-testid="PASSWORD">
          <span className="text-red-400">{errorMessages.password}</span>
        </div>
      )}

      {type === "NUMBER" && (
        <div data-testid="NUMBER">
          <span className="text-red-400"> {errorMessages.number}</span>
        </div>
      )}

      {type === "EMAIL" && (
        <div data-testid="EMAIL">
          <span className="text-red-400">{errorMessages.email}</span>
        </div>
      )}
      {(type === "NAME" || type === "University" || type === "SPECIALITY") && (
        <div data-testid="NAME">
          <span className="text-red-400">{errorMessages.name}</span>
        </div>
      )}
      {type === "DATE_FORMAT" && (
        <div data-testid="DATE_FORMAT">
          <span className="text-red-400">{errorMessages.date}</span>
        </div>
      )}
      {type === "NIN" && (
        <div data-testid="NIN">
          <span className="text-red-400">{errorMessages.nin}</span>
        </div>
      )}
      {type === "PHONE_NUMBER" && (
        <div data-testid="PHONE_NUMBER">
          <span className="text-red-400">{errorMessages.phoneNumber}</span>
        </div>
      )}
    </>
  );
}
InputErrorMessage.propTypes = {
  type: PropTypes.string,
};

export default InputErrorMessage;
