import React from "react";
import floatImg from "../../../assets/hero-img.png";
import FirstSectionBg from "../../../assets/hero-bg.jpg";
import { getTranslation } from "../../../utils/translate/translation";

const translate = {
  ar: {
    sectionTitlePart1: "نظام",
    sectionTitlePart2: "عربي",
    sectionTitlePart3: "موحد",
    sectionTitle2: "للتحقق من مصداقية الشهادات العلمية",
  },
  en: {
    sectionTitlePart1: "Unified",
    sectionTitlePart2: "arab",
    sectionTitlePart3: "system",
    sectionTitle2: "To verify the credibility of academic certificates",
  },
  fr: {
    sectionTitlePart1: "Système",
    sectionTitlePart2: "arabe",
    sectionTitlePart3: "unifié",
    sectionTitle2: "Pour vérifier la crédibilité des certificats académiques",
  },
};
export default function FirstSection() {
  return (
    <div
      id="hero"
      className="relative min-h-[500px] h-[90vh] p-4 bg-[#1419a795] bg-no-repeat bg-right-bottom bg-cover md:max-h-[80vh] lg:flex lg:justify-around lg:bg-right lg:items-center lg:gap-2 overflow-hidden"
      style={{ backgroundImage: `url(${FirstSectionBg})` }}
    >
      {/* Transparent blue overlay */}
      <div className="absolute inset-0 bg-[#16189003] bg-opacity-95"></div>

      <div className="flex justify-center items-center relative z-10 lg:order-2">
        <img
          src={floatImg}
          data-aos="zoom-out"
          data-aos-delay="50"
          data-aos-duration="1200"
          data-aos-once="true"
          data-aos-mirror="false"
          alt="img"
          className="text-center max-w-[340px] lg:max-w-[500px]"
          style={{
            animation: "up-down 2s ease-in-out infinite alternate-reverse both",
          }}
        />
      </div>
      <div
        className="text-start mt-4 p-4 relative z-10 py-8"
        data-aos-delay="50"
        data-aos="zoom-out"
        data-aos-once="true"
        data-aos-duration="1200"
      >
        <h3 className="text-3xl text-white font-bold mb-4 lg:text-5xl">
          {getTranslation(translate, "sectionTitlePart1")}{" "}
          <span className="border-b-4 border-[#1acc8d]">
            {getTranslation(translate, "sectionTitlePart2")}
          </span>{" "}
          {getTranslation(translate, "sectionTitlePart3")}
        </h3>
        <h2
          className="text-lg text-gray-100 font-semibold lg:text-xl"
          data-aos-delay="50"
          data-aos-once="true"
          data-aos-duration="1200"
          data-aos="zoom-out"
        >
          {getTranslation(translate, "sectionTitle2")}
        </h2>
      </div>

      {/* SVG Wave Animation */}
      <div className="absolute bottom-0 left-0 right-0 overflow-hidden">
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="wave1">
            <use
              xlinkHref="#wave-path"
              x="50"
              y="3"
              fill="rgba(255,255,255, .1)"
            />
          </g>
          <g className="wave2">
            <use
              xlinkHref="#wave-path"
              x="50"
              y="0"
              fill="rgba(255,255,255, .2)"
            />
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </div>
    </div>
  );
}
