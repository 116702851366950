import React from "react";
import SectionTitle from "../section-title/SectionTitle";
import ContactItem from "./ContactItem";
import { BsGeoAlt } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa";
import { IoMdPhonePortrait } from "react-icons/io";
import Input from "../input/Input";
import TextArea from "../input/TextArea";
import Button from "../button/Button";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    SectionTitle: "contact us ",
    SectionDescription: "for more information ",
    contactAdress: "Address :",
    contactPhone: "Number :",
    contactEmail: "Email :",
    /*contact form inputs*/
    FullName: "full name",
    country: "country",
    LegalStatus: "legal status",
    email: "email",
    subject: "subject",
    TextCorrespondance: "Correspondence text",
    SendBtn: "Send",
  },
  ar: {
    SectionTitle: "اتصل بنا",
    SectionDescription: "لمزيد من المعلومات",
    contactAdress: "العنوان",
    contactPhone: "الهاتف",
    contactEmail: "البريد الإلكتروني",
    /*contact form inputs*/
    FullName: "الاسم الكامل",
    country: "البلد",
    LegalStatus: "الوضع القانوني",
    email: "البريد الإلكتروني",
    subject: "الموضوع",
    TextCorrespondance: "نص المراسلة",
    SendBtn: "إرسال",
  },
  fr: {
    sectionTitle: "Contactez-nous",
    sectionDescription: "Pour plus d'informations",
    contactAdress: "Adresse  :",
    contactPhone: "Téléphone  :",
    contactEmail: "Email  :",
    /*contact form inputs*/
    FullName: "Nom complet",
    country: "Pays",
    LegalStatus: "Statut juridique",
    email: "Email",
    subject: "Sujet",
    TextCorrespondance: "Texte de correspondance",
    SendBtn: "Envoyer",
  },
};
export default function SeventhSection() {
  return (
    <div id="Contact-us" className="bg-white py-8 pt-12 md:p-8">
      <div className="container mx-auto">
        <SectionTitle
          title={getTranslation(translate, "SectionDescription")}
          p={getTranslation(translate, "SectionTitle")}
        />
        <div className="grid gap-2 lg:grid-cols-2">
          <div
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-once="true"
            data-aos-mirror="false"
          >
            <ContactItem
              title={getTranslation(translate, "contactAdress")}
              description={
                " Rue Mohamed Ali Aqid - Centre Urbain Nord BP 1120 - Quartier Al Khadra 1003 - République Tunisienne "
              }
              icon={<BsGeoAlt />}
            />
            <ContactItem
              title={getTranslation(translate, "contactEmail")}
              description={"alecso@alecso.org.tn"}
              icon={<FaRegEnvelope />}
            />
            <ContactItem
              title={getTranslation(translate, "contactPhone")}
              description={"(+216) 70 013 900"}
              icon={<IoMdPhonePortrait />}
            />
          </div>
          <div
            className="p-2.5"
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-once="true"
            data-aos-mirror="false"
            data-aos-duration="1200"
          >
            <div className="my-4 grid grid-cols-1 gap-4 md:grid-cols-2">
              <Input
                className="w-full border h-12 p-4  border-black/20 placeholder-gray-600"
                type={"text"}
                placeholder={getTranslation(translate, "FullName")}
                name={"FullName"}
              />
              <Input
                className="w-full border h-12 p-4  border-black/20 placeholder-gray-600"
                type={"text"}
                placeholder={getTranslation(translate, "country")}
                name={"country"}
              />
              <Input
                className="w-full border h-12 p-4 border-black/20 placeholder-gray-600"
                type={"text"}
                placeholder={getTranslation(translate, "LegalStatus")}
                name={"LegalStatus"}
              />
              <Input
                className="w-full border border-black/20 h-12 p-4 placeholder-gray-600"
                type={"email"}
                placeholder={getTranslation(translate, "email")}
                name={"email"}
              />
            </div>
            <Input
              className="w-full border border-black/20 h-12 p-4 placeholder-gray-600"
              type={"text"}
              placeholder={getTranslation(translate, "subject")}
              name={"subject"}
            />
            <TextArea
              className="w-full border p-4 my-4 border-black/20 placeholder-gray-600"
              type={"text"}
              placeholder={getTranslation(translate, "TextCorrespondance")}
              name={"TextCorrespondance"}
            />
            <div className="flex justify-center">
              <Button
                text={getTranslation(translate, "SendBtn")}
                isLink={false}
                btnColor={"#1acc8d"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
