/* eslint-disable no-useless-escape */
import moment from "moment";
import { convertNumbers2English } from "../converts/convert";
import readDateFromLeftToRight from "../dateUtils/readDateFromLeftToRight";
// import { excelSerialNumberToDate } from "./dateUtils/convertDateTimeStambyFormatDate";

/**
 *
 * @param {*} value
 * @returns
 */
const isNotEmptyAndContainsOnlyLetters = (value) => {
  const string = /[\u0600-\u06FF]/;
  const letters = /^[A-Za-z\s]*$/;
  return !!((letters?.test(value) || string.test(value)) && value?.length > 1);
};

/**
 *
 * @param {*} value
 * @returns
 */
const isNotEmptyAndContainsOnlyLettersAndNumbers = (value) => {
  const letters = /^[A-Za-z0-9]*$/;

  return !letters?.test(value) && value?.length > 1;
};

/**
 *
 * @param {*} value
 * @returns
 */
const isValidNumber = (value) => {
  const hasNumber = /^[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

  return !!hasNumber.test(convertNumbers2English(value));
};

/**
 *
 * @param {*} value
 * @returns
 */
const isValidPhoneNumber = (value) => {
  const hasNumber = /^[0-9]*$/; // Only digits allowed
  return hasNumber.test(value) && value.length >= 6;
};
/**
 *
 * @param {*} value
 * @returns
 */
const isNumberValid = (value) => {
  let hasNumber = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
  return !!hasNumber.test(value);
};

/**
 *
 * @param {*} value
 * @returns
 */
const isValidDate = (value) => {
  return (
    moment(
      convertNumbers2English(value),
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-DD-MM",
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY") !== "Invalid date" ||
    moment(
      convertNumbers2English(readDateFromLeftToRight(value)),
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-DD-MM",
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY") !== "Invalid date"
  );
};

/**
 *
 * @param {*} field
 * @param {*} index
 * @returns
 */
function isNinValid(field) {
  return (
    field === undefined ||
    field === "" ||
    field === "\n" ||
    isNotEmptyAndContainsOnlyLettersAndNumbers(field)
  );
}

/**
 *
 * @param {*} value
 * @returns
 */
const isValidEmail = (value) => {
  let emailRegEx =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegEx.test(String(value).toLowerCase());
};

/**
 *
 * @param {*} value
 * @returns
 */
const isValidPassword = (value) => {
  if (value !== "") {
    const hasNumber = /\d/;
    const arabicNumber = /[٠-٩]/g;
    const string = /[\u0600-\u06FF]/;
    const str = /[a-zA-Z]/;
    if (
      (hasNumber.test(value) || arabicNumber.test(value)) &&
      value.length > 6 &&
      (str.test(value) || string.test(value))
    )
      return true;
  } else {
    return false;
  }
};

/**
 *
 * @param {*} value
 * @returns
 */
const isNotEmpty = (value) => {
  return !!value;
};

/**
 *
 * @param {*} value
 * @returns
 */
const containsOnlyDigits = (value) => /^\d+$/.test(value);

/**
 *
 * @param {*} field
 * @returns
 */
const isXlsFieldNotValid = (field) => {
  return field === undefined || field === "" || field === "\n";
};

/**
 *
 * @param {*} field
 * @returns
 */
function isDateNotValid(field) {
  if (typeof field === "string") {
    // const dateFormats = [
    //   "D/M/YYYY",
    //   "DD/MM/YYYY",
    //   "D/M/YY",
    //   "DD/MM/YY",
    //   "MM/DD/YYYY",
    //   "YYYY-MM-DD",
    //   "DD-MM-YYYY",
    // ];

    // Parse the date string
    const parsedDate = moment(field);

    return !parsedDate.isValid(); // Return true if date is invalid
  } else if (typeof field === "number") {
    // Check if it's an Excel serial number date
    return convertSerialNumberToDate(field) === "Invalid date";
  } else {
    // Handle other cases
    return convertDateToString(field?.toString()) === "Invalid date";
  }
}

/**
 *
 * @param {*} serialNumber
 * @returns
 */
function convertSerialNumberToDate(serialNumber) {
  const millisecondsPerDay = 86400000;
  const excelEpoch = new Date("1900-01-01T00:00:00Z");
  const offset = (serialNumber - 2) * millisecondsPerDay; // Excel has a bug, 1900 is considered a leap year

  const date = new Date(excelEpoch.getTime() + offset);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}-${month}-${year}`; // Convert to desired format
}

/**
 *
 * @param {*} dateString
 * @returns
 */
function convertDateToString(dateString) {
  const date = moment(dateString);
  return date.format("DD-MM-YYYY");
}
function isOnlyNumbers(inputString) {
  const regex = /^[0-9]+$/;
  return regex.test(inputString);
}
export {
  isValidDate,
  isNotEmptyAndContainsOnlyLetters,
  isValidNumber,
  isValidEmail,
  isValidPhoneNumber,
  isValidPassword,
  isNotEmpty,
  isXlsFieldNotValid,
  isDateNotValid,
  isNotEmptyAndContainsOnlyLettersAndNumbers,
  isNumberValid,
  isNinValid,
  isOnlyNumbers,
  containsOnlyDigits,
};
