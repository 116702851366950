import React from "react";
import Header from "../../components/landing-page/header/Header";
import FirstSection from "../../components/landing-page/first-section/FirstSectionV1";
import SecondSection from "../../components/landing-page/second-section/SecondSection";
import ThirdSection from "../../components/landing-page/third-section/ThirdSection";
import FourthSection from "../../components/landing-page/fourth-section/FourthSection";
import FifthSection from "../../components/landing-page/fifth-section/FifthSection";
import SixthSection from "../../components/landing-page/sixth-section/SixthSection";
import SeventhSection from "../../components/landing-page/seventh-section/SeventhSection";
import ScrollToTop from "../../components/landing-page/fifth-section/ScrollToTop";
import Footer from "../../components/landing-page/footer/Footer";
import aboutImg from "../../assets/about.png";
import AOS from "aos";
import "aos/dist/aos.css";

import { getTranslation } from "../../utils/translate/translation";
const translate = {
  en: {
    sectionTitle: "Tamper-proof or falsifiable certificates ",
    sectionDescription:
      " As part of the efforts of the Arab Educational, Cultural and Scientific Organization (ALECSO) to build digital capabilities and support societies technology, and to get the most out of digital services, the organization has launched a project to unified arab system to check credibility Academic certificates using blockchain technology. This will enable the project to provide a faster, more precise and safer mechanism To verify the authenticity of official certificates, authenticate documents and confirm identity of their holders, which will be provided to the ministries Universities, institutions and individuals spend a lot of time and effort proving the validity of a certificate. Thus, it will facilitate the process of transition Students between universities in the Arab world and their employment in institutions. It will allow also to certificate holders to benefit from the ownership of Mada The life of their testimony without fear of damage or loss, as it will be stored and secured by the blockchain technology.",
  },
  ar: {
    sectionTitle: "شهادات غير قابلة للتزوير أو التزييف",
    sectionDescription:
      "في إطار سعي المنظمة العربية للتربية والثقافة والعلوم (الألكسو) في بناء القدرات الرقمية ودعم المجتمعات التقنية، والاستفادة المثلى من الخدمات الرقمية، أطلقت المنظمة مشروع نظام عربي موحد للتحقق من مصداقية الشهادات العلمية باستعمال تكنولوجيا البلوكتشين. وسيمكن هذا المشروع من توفير آلية أسرع و أدق وأكثر أمانا للتحقق من مصداقية الشهادات الرسمية، وتصديق المستندات وتأكيد هوية حامليها، الأمر الذي سيوفر للوزارات والجامعات والمؤسسات والأشخاص جهدا كبيرا ووقتا ثمينا لإثبات صحّة شهادة ما. وبالتالي سيسهّل عملية انتقال الطلاب بين الجامعات في الوطن العربي وتشغيلهم في المؤسسات. كما سيمكّن أصحاب الشهادات من الانتفاع بملكية مدى الحياة لشهادتهم دون الخوف عليها من التلف أو الضياع حيث أنها ستكون مخزنة ومؤمنة بتقنية البلوكتشين.",
  },
  fr: {
    sectionTitle: "Certificats inviolables ou falsifiables",
    sectionDescription:
      " Dans le cadre des efforts de l'Organisation arabe pour l'éducation, la culture et les sciences (ALECSO) pour construire des capacités numériques et soutenir les sociétés technologie, et pour tirer le meilleur parti des services numériques, l'organisation a lancé un projet de système arabe unifié pour vérifier la crédibilité Certificats académiques en utilisant la technologie de la blockchain. Cela permettra au projet de fournir un mécanisme plus rapide, plus précis et plus sûr Pour vérifier l'authenticité des certificats officiels, authentifier les documents et confirmer l'identité de leurs titulaires, qui seront fournis aux ministères Universités, institutions et individus passent beaucoup de temps et d'efforts à prouver la validité d'un certificat. Ainsi, il facilitera le processus de transition Étudiants entre les universités du monde arabe et leur emploi dans les institutions. Il permettra également aux titulaires de certificats de bénéficier de la propriété de Mada La vie de leur témoignage sans crainte de dommages ou de perte, car il sera stocké et sécurisé par la technologie de la blockchain.",
  },
};
export default function LandingPageV1() {
  AOS.init();
  return (
    <>
      <div className="overflow-hidden">
        <Header />
        <FirstSection />
        <SecondSection
          sectionTitle={getTranslation(translate, "sectionTitle")}
          sectionDescription={getTranslation(translate, "sectionDescription")}
          sectionImg={aboutImg}
        />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
        <SeventhSection />
        <Footer />
        <ScrollToTop />
      </div>
    </>
  );
}
