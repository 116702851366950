const translate = {
  ar: {
    TOAST_ERROR_TOKEN_EXPIRED:
      "فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً",
    TOAST_ERROR_METADATA: "المعطيات ناقصة ، الرجاء إدخال المعطيات كاملة",
    TOAST_SUCCESS: "تمت الاضافة بنجاح ",
    TOAST_ERROR_EMAIL_EXIST:
      "هذا المستخدم يوجد ، حاول إستخدام عنوان إلكتروني أخر ",
    TOAST_ERROR_SERVER_: "هناك خطأ في سرفير ",
    PAGE_TITLE: "  إضافة جامعة جديدة",
    MESSAGE_:
      "لإضافة جامعة يجب تعبئة البيانات التالية لإرسال رابط تسجيل خاص لإتمام عملية تسجيل الموظفين بين مستخدمي المنصة",
    NAME: "  الاسم الكامل للمسؤول ",
    PLACEHOLDER_NAME: "اسم ولقب المستخدم",
    EMAIL: "البريد الالكتروني ",
    PLACEHOLDER_EMAIL: "البريد الالكتروني لإرسال طلب التسجيل",
    PHONE: "الهاتف",
    PLACEHOLDER_PHONE: "هاتف المسؤول ",
    SPECIALITY: "إدارة",
    UNIVERSITY: "الجامعة",
    PLACEHOLDER_UNIVERSITY: " إسم الجامعة ",
    COUNTRY: "البلد",
    BUTTON_TITLE: "ارسال طلب تسجيل",
    BACK: "  الرجوع إلى القائمة",
    CREDIT: "الرموز",
    PLACEHOLDER_CREDIT: "رموز الجامعة",
    MODE_ONE: "  رفع ملف اكسل مرفق بملفات pdfs",
    MODE_TWO: "رفع ملف اكسل",
    MODE_THREE: "   إنشاء دبلومة رقمية",
    MODE_SELECTED: " إختيار طريقة إنشاء الدبلومات",
    EMAIL_RESPONSABLE_ALREADY_EXIST: "هذا البريد الالكتروني موجود بالفعل",
    UNIVERSITY_NAME_ALREADY_EXIST: "هذا الاسم موجود بالفعل",
    SELECT_INVITATION_EMAIL_TEMPLATE_LANGUAGE: "اختر لغة الدعوة",
  },
  en: {
    TOAST_ERROR_TOKEN_EXPIRED:
      "Your registration period has expired. Re-enter again",
    TOAST_ERROR_METADATA: " Data is missing, please enter complete information",
    TOAST_SUCCESS: "  Added successfully ",
    TOAST_ERROR_EMAIL_EXIST:
      "This user exists, try using another email address",
    TOAST_ERROR_SERVER_: "There is an error in the server",
    PAGE_TITLE: "Add a university",
    MESSAGE_:
      "To add a university, the following data must be filled in to send a special registration link to complete the employee registration process among the users of the platform",
    NAME: " Full name of the responsable ",
    PLACEHOLDER_NAME: "Username and surname",
    EMAIL: "Email",
    PLACEHOLDER_EMAIL: "The email to send the registration request",
    PHONE: "Phone",
    PLACEHOLDER_PHONE: " Responsable phone ",
    SPECIALITY: "Administration",
    UNIVERSITY: "University",
    PLACEHOLDER_UNIVERSITY: " University Name",
    COUNTRY: "Country",
    BUTTON_TITLE: "Submit a registration request",
    BACK: " Back to list",
    CREDIT: "Tokens",
    PLACEHOLDER_CREDIT: "University Tokens",
    MODE_ONE: "Upload an excel file attached to pdfs",
    MODE_TWO: "Upload excel file",
    MODE_THREE: " Create a digital diploma",
    MODE_SELECTED: "Choose the method of creating diplomas",
    EMAIL_RESPONSABLE_ALREADY_EXIST: "This email already exists",
    UNIVERSITY_NAME_ALREADY_EXIST: "This university name already exists",
    SELECT_INVITATION_EMAIL_TEMPLATE_LANGUAGE: "Select invitation language",
  },
  fr: {
    TOAST_ERROR_TOKEN_EXPIRED:
      "Votre période d'enregistrement a expiré. Entrez à nouveau",
    TOAST_ERROR_METADATA:
      "Les données sont manquantes, veuillez saisir les informations complètes",
    TOAST_SUCCESS: "Ajouté avec succès",
    TOAST_ERROR_EMAIL_EXIST:
      "Cet utilisateur existe, essayez d'utiliser une autre adresse e-mail",
    TOAST_ERROR_SERVER_: "Il y a une erreur dans le serveur",
    PAGE_TITLE: "Ajouter une université",
    MESSAGE_:
      "Pour ajouter une université, les données suivantes doivent être fournies pour envoyer un lien d'inscription spécial afin de compléter le processus d'inscription des employés parmi les utilisateurs de la plateforme",
    NAME: "Nom complet du responsable",
    PLACEHOLDER_NAME: "Nom d'utilisateur et prénom",
    EMAIL: "Email",
    PLACEHOLDER_EMAIL: "L'email pour envoyer la demande d'inscription",
    PHONE: "Téléphone",
    PLACEHOLDER_PHONE: " Téléphone du responsable ",
    SPECIALITY: "Administration",
    UNIVERSITY: "L'Université",
    PLACEHOLDER_UNIVERSITY: "Nom de l'université",
    COUNTRY: "Pays",
    BUTTON_TITLE: "Soumettre une demande d'inscription",
    BACK: " Retour à la liste",
    CREDIT: "Jetons",
    PLACEHOLDER_CREDIT: "Jetons de l'université",
    MODE_ONE: " Télécharger un fichier excel joint aux pdfs",
    MODE_TWO: "Téléchargez un fichier excel",
    MODE_THREE: " Créer un diplôme numérique",
    MODE_SELECTED: " Choisissez la méthode de création des diplômes",
    EMAIL_RESPONSABLE_ALREADY_EXIST: "Cet email existe déjà",
    UNIVERSITY_NAME_ALREADY_EXIST: "Ce nom d'université existe déjà",
    SELECT_INVITATION_EMAIL_TEMPLATE_LANGUAGE:
      "Sélectionnez la langue de l'invitation",
  },
};

export default translate;
