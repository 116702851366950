/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOADING_USER } from "../../store/features/userSlice/userSlice";
import { useSigninMutation } from "../../store/service/usersService";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import ForgotPassword from "./ForgotPassword";
import logo from "../../assets/logo.png";
import { mailIcon, passwordIcon } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";

const translate = {
  ar: {
    PAGE_TITLE: "  فضاء الجامعة",
    MESSAGE_: "      هل نسيت كلمات السر",
    MESSAGE_ONE: "    اكتب بريدك الالكتروني وسنرسل لك رابط تغير كلمة السر",
    EMAIL: "البريد الالكتروني",
    PASSWORD: "كلمة العبور",
    BUTTON_TITLE: "دخول",
    MESSAGE_TWO: "     بيانات الاعتماد غير صالحة",
    MESSAGE_THREE: "هل نسيت كلمة السّر ؟ ",
    CLICK: "اضغط هنا .",
  },
  en: {
    PAGE_TITLE: "University space",
    MESSAGE_: "Forgot your passwords?",
    MESSAGE_ONE:
      "Enter your email and we will send you a link to change your password",
    EMAIL: "Email",
    PASSWORD: "Password",
    BUTTON_TITLE: "Login",
    MESSAGE_TWO: "The credentials are invalid",
    MESSAGE_THREE: "Forgot your password ?",
    CLICK: "Click here .",
  },
  fr: {
    PAGE_TITLE: "Espace universitaire",
    MESSAGE_: "Mots de passe oubliés ?",
    MESSAGE_ONE:
      "Entrez votre email et nous vous enverrons un lien pour changer votre mot de passe",
    EMAIL: "Email",
    PASSWORD: "Mot de Passe",
    BUTTON_TITLE: "Connexion",
    MESSAGE_TWO: "Les identifiants ne sont pas valides",
    MESSAGE_THREE: "Vous avez oublié votre mot de passe ?",
    CLICK: "Cliquez ici .",
  },
};
function LoginPage() {
  let navigate = useNavigate();
  const [signin] = useSigninMutation();
  const [forgotPwdClicked, setForgotPwdClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [error, setError] = useState(false);
  let dispatch = useDispatch();
  const signInAction = async () => {
    setIsLoading(true);
    const dataToSend = {
      email,
      password,
    };

    await signin(dataToSend).then((res) => {
      if (res.data) {
        setIsLoading(false);
        dispatch(
          LOADING_USER({
            token: res.data.token,
            accessToken: true,
            role: res.data.role,
            username: res.data.username,
            email: res.data.email,
            numero: res.data.numero,
            isAccepted: res.data.isAccepted,
            mode: res.data.mode,
            university: res.data.university,
            signature: res?.data?.signature,
            logo: res?.data?.logo,
            responsableWallet: res?.data?.blockchainAddress,
          })
        );
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.role);

        navigate("/dashboard");
      } else {
        toast.error(res.error.data.message);
        setIsLoading(false);
        setError(true);
      }
    });
  };

  const changeEmail = (value) => {
    setEmail(value);
  };
  const changePassword = (value) => {
    setPassword(value);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-start justify-start space-y-8 bg-white lg:p-14 sm:p-3">
        <img src={logo} alt="" className="mb-2 w-[290px] h-14" />
        <Link
          to="/"
          className="flex items-center justify-center group  text-white transition-all duration-700 ease-in-out bg-blue-800 p-2 border-2 border-blue-800 rounded-md hover:!text-blue-800 hover:!bg-white"
        >
          <span className="mr-2">
            {" "}
            <IoMdArrowRoundBack />{" "}
          </span>
        </Link>
        {!forgotPwdClicked ? (
          <h1 className="font-bold text-indigo-900 lg:text-4xl md:text-4xl ">
            {getTranslation(translate, "PAGE_TITLE")}
          </h1>
        ) : (
          <>
            <h1 className="font-bold text-blue-900 lg:text-4xl">
              {getTranslation(translate, "MESSAGE_")}
            </h1>
            <p className="text-gray-500">
              {getTranslation(translate, "MESSAGE_ONE")}
            </p>
          </>
        )}

        {!forgotPwdClicked ? (
          <>
            <Input
              icon={mailIcon}
              type={"NO_CONTROL"}
              placeholder={getTranslation(translate, "EMAIL")}
              inputChange={changeEmail}
              value={email}
              setValid={setIsEmailValid}
            />
            <Input
              icon={passwordIcon}
              placeholder={getTranslation(translate, "PASSWORD")}
              password
              inputChange={changePassword}
              type={"NO_CONTROL"}
              value={password}
              setValid={setIsPasswordValid}
            />
            <div
              style={{
                transform: "scale(0.85)",
                transformOrigin: "0 0",
              }}
            >
              <ReCAPTCHA
                sitekey="6LehamEoAAAAALXfDBbL60ioXe1SBu2UuAJIaZRc"
                onChange={() => setCaptcha(true)}
              />
            </div>
            <MainButton
              title={getTranslation(translate, "BUTTON_TITLE")}
              action={signInAction}
              isLoading={isLoading}
              disabled={
                !isEmailValid || !isPasswordValid || password === "" || !captcha
              }
            />
            {error ? (
              <span className="font-bold text-red-500">
                {getTranslation(translate, "MESSAGE_TWO")}
              </span>
            ) : (
              <></>
            )}
            <div className="flex items-center justify-center w-full font-bold lg:text-2xl">
              <p> {getTranslation(translate, "MESSAGE_THREE")}</p>
              <p
                className="mr-2 text-blue-800 cursor-pointer"
                onClick={() => {
                  setForgotPwdClicked(true);
                }}
              >
                {getTranslation(translate, "CLICK")}
              </p>
            </div>
          </>
        ) : (
          <ForgotPassword setForgotPwdClicked={setForgotPwdClicked} />
        )}
      </div>
    </>
  );
}

export default LoginPage;
