/* eslint-disable react/prop-types */
import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner/Spinner";
import diploma_digital_preview from "../../assets/certified.png";
import { getTranslation } from "../../utils/translate/translation";
import { GiSaveArrow } from "react-icons/gi";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../utils/translate/multilanguage-reader";
import AcceptedDiplomaModalModeTwo from "../../components/modal/Diploma/modeTwo/AcceptedDiplomaModalModeTwo";
import AcceptedDiplomaModalModeOne from "../../components/modal/Diploma/modeOne/AcceptedDiplomaModalModeOne";
import AcceptedDiplomaModalModeThreeByStudent from "../../components/modal/Diploma/modeThree/AcceptedDiplomaModalModeThreeByStudent";
import { FaLink } from "react-icons/fa6";
import { TbClipboardCopy } from "react-icons/tb";
import { FaEye } from "react-icons/fa";
import { GiDiploma } from "react-icons/gi";
import { Tooltip } from "@material-tailwind/react";
import { GrDocumentVerified } from "react-icons/gr";
// eslint-disable-next-line no-unused-vars, react/prop-types
function Diploma(diploma) {
  const translate = {
    ar: {
      SPECIALITY: "الاختصاص:",
      STUDENTS_LIST: "قائمة الطلبة",
      SPECIALTY: "الاختصاص:",
      ACADEMIC_YEAR: "السنة الجامعية:",
      NOTE: "الملاحظة:",
      AVERAGE_TOTAL: "المعدل / المجموع:",
      DIPLOMA_ID: "معرّف الشهادة",
      UNIVERSITY: "الجامعة:",
      VIEW: "اطّلاع",
      COPY_LINK: "نسخ الرابط",
      VERIFICATION_LINK: "رابط التحقق",
      DIPLOMA_ID_BTN: "معرف الشهادة",
      QR_CODE: "معرّف الشهادة",
      LINK_TO_VERIFICATION: "رابط التحقق",
      DOWNLOAD_QR_CODE: "تحميل رمز الاستجابة السريعة",
      DIPLOMA_INFO: "معلومات الشهادة",
      LINK_COPIED: "تم نسخ الرابط ",
    },
    en: {
      SPECIALITY: "Specialty:",
      STUDENTS_LIST: "Students List",
      SPECIALTY: "Specialty:",
      ACADEMIC_YEAR: "Academic Year:",
      NOTE: "Note:",
      AVERAGE_TOTAL: "Average / Total:",
      DIPLOMA_ID: "Diploma ID",
      UNIVERSITY: "University",
      VIEW: "View",
      COPY_LINK: "Copy Link",
      VERIFICATION_LINK: "Verification Link",
      DIPLOMA_ID_BTN: "Diploma ID",
      QR_CODE: "QR Code",
      LINK_TO_VERIFICATION: "Verification Link",
      DOWNLOAD_QR_CODE: "Download QR Code",
      DIPLOMA_INFO: "Diploma Information",
      LINK_COPIED: "Link Copied",
    },
    fr: {
      SPECIALITY: "Spécialité :",
      STUDENTS_LIST: "Liste des étudiants",
      SPECIALTY: "Spécialité :",
      ACADEMIC_YEAR: "Année académique :",
      NOTE: "Remarque :",
      AVERAGE_TOTAL: "Moyenne / Total :",
      DIPLOMA_ID: "Identifiant du diplôme",
      UNIVERSITY: "Université :",
      VIEW: "Voir",
      COPY_LINK: "Copier le lien",
      VERIFICATION_LINK: "Lien de vérification",
      DIPLOMA_ID_BTN: "Identifiant du diplôme",
      QR_CODE: "Code QR",
      LINK_TO_VERIFICATION: "Lien de vérification",
      DOWNLOAD_QR_CODE: "Télécharger le code QR",
      DIPLOMA_INFO: "Informations sur le diplôme",
      LINK_COPIED: "Lien copié",
    },
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  const [
    linkToVerificationPageQrCodeClicked,
    setLinkToVerificationPageQrCodeClicked,
  ] = useState(false);
  const [QrCodeClicked, setQrCodeClicked] = useState(false);
  const onLinkVerificationClick = () => {
    setLinkToVerificationPageQrCodeClicked(
      !linkToVerificationPageQrCodeClicked
    );
    setQrCodeClicked(false);
  };
  const onQrCodeClicked = () => {
    setQrCodeClicked(!QrCodeClicked);
    setLinkToVerificationPageQrCodeClicked(false);
  };
  const copy = async () => {
    await navigator.clipboard.writeText(
      `https://saddakni.alecso.org/verification/${diploma?.diploma?._id}`
    );
    toast.info(getTranslation(translate, "LINK_COPIED"), {
      position: "top-left",
      theme: "colored",
      icon: false,
      autoClose: 1000,
    });
  };
  const downloadQRCode = (fileName) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = fileName + `qr.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      {diploma?.diploma?.status === "rejected" ||
      diploma?.diploma?.status === "pending-blockchain" ||
      diploma?.diploma?.status === "pending" ||
      diploma?.diploma?.status === "revoked" ||
      isThereCommunValues(
        Object.keys(diploma?.diploma?.type),
        Object.keys(diploma?.diploma?.student?.firstName)
      ) === false ? (
        <></>
      ) : (
        <div className="flex flex-col border-2 border-blue-200 h-full rounded-xl m-2 diplomaInfo lg:w-[auto] md:w-[auto] sm:w-fit max-w-[360px]">
          <div className="flex justify-end items-center p-2 bg-[#1565c0] rounded-t-lg">
            <Tooltip
              content={getTranslation(translate, "VIEW")}
              placement="bottom"
            >
              <button
                onClick={() => {
                  handleOpen();
                }}
                className="p-2 mx-2  text-sm text-white bg-[#117554] rounded-full transition hover:bg-white hover:!text-[#117554]"
              >
                {/* {getTranslation(translate, "VIEW")} */}
                <FaEye className="text-lg" />
              </button>
            </Tooltip>
            <Tooltip
              content={getTranslation(translate, "COPY_LINK")}
              placement="bottom"
            >
              <button
                className="p-2 mx-2  text-sm text-white bg-[#4F75FF] rounded-full  transition hover:bg-white hover:!text-[#4F75FF]"
                onClick={() => copy()}
              >
                <TbClipboardCopy className="text-lg" />
                {/* {getTranslation(translate, "COPY_LINK")} */}
              </button>
            </Tooltip>

            {diploma?.diploma?.hashTx ? (
              <Tooltip
                content={getTranslation(translate, "VERIFICATION_LINK")}
                placement="bottom"
              >
                <button
                  onClick={onLinkVerificationClick}
                  className="p-2 mx-2  text-sm text-white bg-[#FABC3F] rounded-full transition hover:bg-white hover:!text-[#FABC3F]"
                >
                  {/* {getTranslation(translate, "VERIFICATION_LINK")} */}
                  <FaLink className="text-lg" />
                </button>
              </Tooltip>
            ) : null}

            {diploma?.diploma?.hashTx ? (
              <Tooltip
                content={getTranslation(translate, "DIPLOMA_ID")}
                placement="bottom"
              >
                <button
                  onClick={onQrCodeClicked}
                  className="p-2 mx-2  text-sm text-white bg-[#C7253E] rounded-full transition hover:bg-white hover:!text-[#C7253E]"
                >
                  {/* {getTranslation(translate, "DIPLOMA_ID")} */}
                  <GiDiploma className="text-lg" />
                </button>
              </Tooltip>
            ) : null}
          </div>
          {QrCodeClicked && (
            <span className="p-2 text-blue-900 text-md">
              {getTranslation(translate, "DIPLOMA_ID")}
              <span className="font-bold"> {diploma?.diploma?._id}</span>
            </span>
          )}

          {linkToVerificationPageQrCodeClicked && (
            <div
              className={`${
                linkToVerificationPageQrCodeClicked === false ? "hidden" : ""
              } transition-all duration-500 ease-in-out`}
            >
              <div className="items-center justify-center w-[350px] h-[240px]">
                <QRCode
                  id="qr-gen"
                  value={
                    diploma?.diploma?.mode === "modeThree"
                      ? `https://saddakni.alecso.org/digital_diploma/${diploma?.diploma?._id}`
                      : `https://saddakni.alecso.org/verification/${diploma?.diploma?._id}`
                  }
                  size={246}
                  level={"H"}
                  includeMargin={true}
                  style={{
                    height: "240px",
                    maxWidth: "100%",
                    width: "350px",
                  }}
                />
              </div>
              <div className="p-1 flex gap-2 items-center">
                {diploma?.diploma?.mode === "modeThree" ? (
                  <a
                    href={`https://saddakni.alecso.org/digital_diploma/${diploma?.diploma?._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GrDocumentVerified className="text-lg" />
                  </a>
                ) : (
                  <Tooltip
                    content={getTranslation(translate, "VERIFICATION_LINK")}
                  >
                    <a
                      href={`https://saddakni.alecso.org/verification/${diploma?.diploma?._id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-[#1565c0] text-white p-2 rounded-md transition !border-2 hover:!text-blue-500 hover:bg-white hover:!border-blue-500"
                    >
                      <GrDocumentVerified className="text-lg" />
                      {/* {getTranslation(translate, "VERIFICATION_LINK")} */}
                    </a>
                  </Tooltip>
                )}
                <div>
                  <Tooltip
                    content={getTranslation(translate, "DOWNLOAD_QR_CODE")}
                  >
                    <button
                      className="bg-green-300 text-white p-2 rounded-md text-lg transition !border-2 hover:!text-green-500 hover:bg-white hover:!border-green-500"
                      onClick={() => {
                        downloadQRCode(diploma?.diploma?.hashIpfs);
                      }}
                    >
                      <GiSaveArrow className="text-lg" />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          <div
            className={`${
              linkToVerificationPageQrCodeClicked ? "hidden" : ""
            } transition-all duration-500 ease-in-out`}
          >
            {diploma?.diploma?.hashIpfs ? (
              <Document
                file={
                  // eslint-disable-next-line no-undef
                  "https://saddakni.alecso.org" +
                  "/ipfs/" +
                  diploma?.diploma?.hashIpfs
                }
                style={{ maxWidth: "350px" }}
                loading={
                  <div className="flex items-center justify-center w-full">
                    <Spinner />
                  </div>
                }
                className="flex items-center justify-center"
              >
                <Page
                  pageNumber={1}
                  width={300}
                  style={{ maxWidth: "350px" }}
                />
              </Document>
            ) : null}
            {diploma?.diploma?.mode === "modeThree" ? (
              <img
                src={diploma_digital_preview}
                alt=""
                className="w-[350px] h-[240px] digital-diploma-prview  rounded-sm"
              />
            ) : null}
            {diploma?.diploma?.mode === "modeTwo" ? (
              <img
                src={diploma_digital_preview}
                alt=""
                className="w-[350px] h-[240px] digital-diploma-prview  rounded-sm"
              />
            ) : null}
          </div>

          <div className="w-full p-2 bg-[#1565c0] rounded-b-lg">
            <p className="w-full text-xl text-white text-center font-bold">
              {multilanguageReader(diploma?.diploma?.speciality)?.toUpperCase()}
            </p>
            <div className="flex w-full py-2">
              <div className="flex items-start text-sm font-bold ">
                <span className="mr-4 text-white">
                  {" "}
                  {getTranslation(translate, "SPECIALITY")}
                </span>
                <span className="mr-2 text-white">
                  {multilanguageReader(diploma?.diploma?.speciality)}
                </span>
              </div>
            </div>
            <div className="flex w-full py-1">
              <div className="flex items-start text-sm font-bold ">
                <span className="mr-4 text-white">
                  {getTranslation(translate, "ACADEMIC_YEAR")}
                </span>
                <span className="mr-2 text-white">
                  {" "}
                  {diploma?.diploma?.year}
                </span>
              </div>
            </div>
            <div className="flex w-full py-1">
              <div className="flex items-start text-sm font-bold ">
                <span className="mr-4 text-white">
                  {" "}
                  {getTranslation(translate, "NOTE")}
                </span>
                <span className="mr-2 text-white">
                  {" "}
                  {multilanguageReader(diploma?.diploma?.honors)}
                </span>
              </div>
            </div>
            <div className="flex w-full py-1">
              <div className="flex items-start text-sm font-bold ">
                <span className="mr-4 text-white">
                  {getTranslation(translate, "AVERAGE_TOTAL")}
                </span>
                <span className="mr-2 text-white">
                  {" "}
                  {diploma.diploma?.average}
                </span>
              </div>
            </div>
            {diploma?.diploma?.idDiploma ? (
              <div className="flex w-full py-1">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-white">
                    {" "}
                    {getTranslation(translate, "DIPLOMA_ID")}
                  </span>
                  <span className="mr-2 text-white">
                    {`${diploma?.diploma?.idDiploma?.slice(
                      0,
                      8
                    )}...${diploma?.diploma?.idDiploma?.slice(-4)}`}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="flex w-full py-1">
              <div className="flex items-start text-sm font-bold ">
                <span className="mr-4 text-white">
                  {getTranslation(translate, "UNIVERSITY")}
                </span>
                <span className="mr-2 text-white">
                  {diploma?.diploma?.university?.name}
                </span>
              </div>
            </div>
          </div>

          {open && diploma?.diploma?.mode === "modeOne" && (
            <AcceptedDiplomaModalModeOne
              item={diploma?.diploma}
              diploma={diploma.item}
              setOpen={setOpen}
              open={open}
            />
          )}
          {open && diploma?.diploma?.mode === "modeTwo" && (
            <AcceptedDiplomaModalModeTwo
              item={diploma?.diploma}
              diploma={diploma.item}
              setOpen={setOpen}
              open={open}
            />
          )}

          {open && diploma?.diploma?.mode === "modeThree" && (
            <div className="w-full p-2">
              <AcceptedDiplomaModalModeThreeByStudent
                item={diploma?.diploma}
                diploma={diploma.item}
                setOpen={setOpen}
                open={open}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Diploma;
