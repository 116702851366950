import React from "react";
import NavBarItem from "./NavBarItem";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    Major: "Major",
    Properties: "Properties",
    AccessToThePlatform: "Access to the platform",
    Blockchain: "Blockchain",
    HowItWorks: "How it works",
    Goals: "Goals",
    ContactUs: "Contact us",
  },
  fr: {
    Major: "Principal",
    Properties: "Propriétés",
    AccessToThePlatform: "Accès à la plateforme",
    Blockchain: "Blockchain",
    HowItWorks: "Comment ça marche",
    Goals: "Objectifs",
    ContactUs: "Contactez-nous",
  },
  ar: {
    Major: "الرئيسية",
    Properties: "الخصائص",
    AccessToThePlatform: "الوصول إلى المنصة",
    Blockchain: "سلسلة كتل",
    HowItWorks: "كيف يعمل",
    Goals: "الأهداف",
    ContactUs: "اتصل بنا",
  },
};
export default function NavBar() {
  return (
    <div className="hidden lg:flex lg:gap-8 ">
      <NavBarItem
        text={getTranslation(translate, "Major")}
        to={"#hero"}
        Menustyle={false}
      />
      <NavBarItem
        text={getTranslation(translate, "Properties")}
        to={"#criteria"}
        Menustyle={false}
      />
      <NavBarItem
        text={getTranslation(translate, "AccessToThePlatform")}
        to={"#login"}
        Menustyle={false}
      />
      <NavBarItem
        text={getTranslation(translate, "Blockchain")}
        to={"#blockchain"}
        Menustyle={false}
      />
      <NavBarItem
        text={getTranslation(translate, "HowItWorks")}
        to={"#howto"}
        Menustyle={false}
      />
      <NavBarItem
        text={getTranslation(translate, "Goals")}
        to={"#goals"}
        Menustyle={false}
      />
      <NavBarItem
        text={getTranslation(translate, "ContactUs")}
        to={"#Contact-us"}
        Menustyle={false}
      />
    </div>
  );
}
