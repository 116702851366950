import React from "react";
import PropTypes from "prop-types";
const Alert = ({ type, message, icon, info, languageUpdate }) => {
  const errorStyle = "text-white  bg-[#f3616d] p-3";
  const infoStyle = "text-white bg-[#56b6f7] p-3";
  const style = type === "error" ? `${errorStyle}` : `${infoStyle}`;
  return (
    <div className={style}>
      <span className="flex items-center gap-2 text-lg font-semibold ">
        {icon}
        {message}
      </span>
      <span>{info}</span>
      {languageUpdate?.map((lang, key) => (
        <>
          <div className="flex items-center w-full h-full gap-1">
            <div className="w-2 h-2 bg-[#607080] rounded-lg "></div>
            <div className="flex gap-2">
              <span key={key}>
                {lang === "ar"
                  ? "العربية"
                  : lang === "fr"
                  ? "Français"
                  : "Anglais"}
              </span>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
Alert.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  message: PropTypes.string,
  info: PropTypes.string,
  languageUpdate: PropTypes.array,
};
export default Alert;
