import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { GET_UNIVERSITIES } from "../../store/features/universitySlice/universitySlice";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
import { FaRegAddressCard } from "react-icons/fa";
import { FaUniversity } from "react-icons/fa";
import { Tooltip } from "@material-tailwind/react";
import { FaEthereum } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { LiaUniversitySolid } from "react-icons/lia";
import { countryCodes } from "../../components/charts/CountryNumber";
const Table = ({
  universities,
  currentPage,
  perPage,
  setUniversities,
  setUniversityInfo,
  setOpen,
  setModalType,
}) => {
  const translation = {
    ar: {
      UNIVERSITY_NAME: "اسم الجامعة",

      COUNTRY: "البلد",
      ACTION: "العملية",

      CHOOSE: "اختر...",
      CREDIT: "الرصيد",
      RESPONSABLE_INFO: "معلومات المسؤول",
      NO_DATA: "لم يتم العثور على بيانات",
      UPDATE_UNIVERSITY: "تحديث الجامعة",
      CHANGE_RESPONSABLE: "تغيير المسؤول",
      BALANCE_ETHR: "الرصيد بالإثريوم",
    },
    en: {
      UNIVERSITY_NAME: "University Name",

      COUNTRY: "Country",
      RESPONSABLE_INFO: "Responsable Info",
      NO_DATA: "No data found",
      ACTION: "Action",

      CHOOSE: "Choose...",
      CREDIT: "Credit",
      UPDATE_UNIVERSITY: "Update University",
      CHANGE_RESPONSABLE: "Change Responsable",
      BALANCE_ETHR: "Balance in ETHR",
    },

    fr: {
      UNIVERSITY_NAME: "Nom de l'université",
      RESPONSABLE_INFO: "Information de Responsable",
      COUNTRY: "Pays",
      NO_DATA: "Aucune donnée trouvée",
      ACTION: "Action",
      UPDATE_UNIVERSITY: "Mettre à jour l'université",
      CHANGE_RESPONSABLE: "Changer le responsable",
      CHOOSE: "Choisir...",
      CREDIT: "Crédit",
      BALANCE_ETHR: "Solde en ETHR",
    },
  };

  const [universityNameClicked, setUniversityNameClicked] = useState(false);
  const [countryClicked, setCountryClicked] = useState(false);
  const dispatch = useDispatch();
  const sortByNested = (fieldPath, status) => {
    return (a, b) => {
      const fieldA = fieldPath.split(".").reduce((obj, key) => obj[key], a);
      const fieldB = fieldPath.split(".").reduce((obj, key) => obj[key], b);

      if (fieldA > fieldB) {
        return status ? -1 : 1;
      }
      if (fieldB > fieldA) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const onUniversityNameClick = () => {
    setUniversityNameClicked(!universityNameClicked);
    const sorted = [...universities].sort(
      sortByNested("university.name", universityNameClicked)
    );
    setUniversities(sorted);
    dispatch(GET_UNIVERSITIES({ universities: sorted }));
  };

  const onCountryClick = () => {
    setCountryClicked(!countryClicked);
    const sorted = [...universities].sort(
      sortByNested("university.country", countryClicked)
    );
    setUniversities(sorted);
    dispatch(GET_UNIVERSITIES({ universities: sorted }));
  };

  const convertWeiToEther = (wei) => {
    //chow only 4 decimal points
    return (wei / Math.pow(10, 18)).toFixed(10);
  };
  const language = localStorage.getItem("language");
  const countries = {
    ar: {
      PALESTINE: "دولة فلسطين",
      UNITED_ARAB_EMIRATES: "الإمارات العربية المتحدة",
      BAHRAIN: "البحرين",
      KUWAIT: "الكويت",
      SAUDI_ARABIA: "السعودية",
      YEMEN: "اليمن",
      QATAR: "قطر",
      OMAN: "سلطنة عمان",
      JORDAN: "الأردن",
      SYRIA: "سوريا",
      LEBANON: "لبنان",
      IRAQ: "العراق",
      EGYPT: "مصر",
      MOROCCO: "المغرب",
      LIBYA: "ليبيا",
      TUNISIA: "تونس",
      ALGERIA: "الجزائر",
      SUDAN: "السودان",
      MAURITANIA: "موريتانيا",
      DJIBOUTI: "جيبوتي",
      SOMALIA: "الصومال",
    },
    en: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "United Arab Emirates",
      BAHRAIN: "Bahrain",
      KUWAIT: "Kuwait",
      SAUDI_ARABIA: "Saudi Arabia",
      YEMEN: "Yemen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordan",
      SYRIA: "Syria",
      LEBANON: "Lebanon",
      IRAQ: "Iraq",
      EGYPT: "Egypt",
      MOROCCO: "Morocco",
      LIBYA: "Libya",
      TUNISIA: "Tunisia",
      ALGERIA: "Algeria",
      SUDAN: "Sudan",
      MAURITANIA: "Mauritania",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalia",
    },
    fr: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "Émirats arabes unis",
      BAHRAIN: "Bahreïn",
      KUWAIT: "Koweït",
      SAUDI_ARABIA: "Arabie saoudite",
      YEMEN: "Yémen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordanie",
      SYRIA: "Syrie",
      LEBANON: "Liban",
      IRAQ: "Irak",
      EGYPT: "Égypte",
      MOROCCO: "Maroc",
      LIBYA: "Libye",
      TUNISIA: "Tunisie",
      ALGERIA: "Algérie",
      SUDAN: "Soudan",
      MAURITANIA: "Mauritanie",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalie",
    },
  };
  return (
    <div className="max-h-[calc(100vh-500px)] overflow-y-auto">
      <table
        className={
          language === "ar"
            ? "z-10 w-full text-sm text-right  text-gray-500 dark:text-gray-400 "
            : "z-10 w-full text-sm  text-left text-gray-500 dark:text-gray-400 "
        }
      >
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th
              scope="col"
              className="px-4 py-2 cursor-pointer"
              onClick={onUniversityNameClick}
            >
              <div className="flex items-center ">
                <span>{getTranslation(translation, "UNIVERSITY_NAME")} </span>
                {universityNameClicked ? (
                  <div className="cursor-pointer ">
                    <DownArrow />
                  </div>
                ) : (
                  <div className="cursor-pointer ">
                    <UpArrow />
                  </div>
                )}
              </div>
            </th>
            <th scope="col" className="px-4 py-2 cursor-pointer">
              {getTranslation(translation, "BALANCE_ETHR")}
            </th>
            <th scope="col" className="px-4 py-2 cursor-pointer">
              {getTranslation(translation, "CREDIT")}
            </th>
            <th
              scope="col"
              className="px-8 py-2 cursor-pointer"
              onClick={onCountryClick}
            >
              <div className="flex items-center ">
                <span> {getTranslation(translation, "COUNTRY")} </span>
                {countryClicked ? (
                  <div className="cursor-pointer ">
                    <DownArrow />
                  </div>
                ) : (
                  <div className="cursor-pointer ">
                    <UpArrow />
                  </div>
                )}
              </div>
            </th>
            <th scope="col" className="px-4 py-2 cursor-pointer text-center">
              {getTranslation(translation, "ACTION")}
            </th>
          </tr>
        </thead>
        <tbody className="overflow-x-hidden h-10vh">
          {universities?.length === 0 && (
            <tr className="border-b dark:bg-gray-900 dark:border-gray-700">
              <td
                colSpan="4"
                className="text-center py-2 dark:text-gray-400 text-gray-500"
              >
                {getTranslation(translation, "NO_DATA")}
              </td>
            </tr>
          )}
          {universities
            ?.slice(currentPage * perPage - perPage, currentPage * perPage)
            .map((university, key) => (
              <tr
                key={key}
                className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                  (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
                }
                ${
                  (university?.university.CreditDepositeDemand?.status ||
                    university?.university.EtherDepositeDemand?.status) &&
                  "!bg-[#344fff2f]"
                } 
                `}
              >
                <td>
                  <div className="px-6 py-2 flex items-center">
                    <span className="mx-1">
                      <LiaUniversitySolid />
                    </span>
                    <span className=""> {university?.university?.name}</span>
                  </div>
                </td>
                <td>
                  <div className="px-6 py-2 flex items-center">
                    <span className="mx-1">
                      <FaEthereum />
                    </span>
                    <span className="">
                      {convertWeiToEther(university?.balance)}
                    </span>
                  </div>
                </td>

                <td>
                  <div className="px-6 py-2 flex items-center">
                    <span className="mx-1">
                      <FaCoins />
                    </span>
                    <span className=""> {university?.university?.credit}</span>
                  </div>
                </td>

                <td className="px-6 py-2">
                  {university?.university?.country && (
                    <span
                      className={`fi fi-${
                        countryCodes[university?.university?.country]
                      } mr-2`}
                      title={getTranslation(
                        translation,
                        university?.university?.country
                      )}
                    ></span>
                  )}
                  {getTranslation(countries, university?.university?.country)}
                </td>
                <td className="px-6 py-2 flex justify-center items-center">
                  <Tooltip
                    color="light"
                    content={getTranslation(translation, "RESPONSABLE_INFO")}
                  >
                    <div
                      className="bg-blue-800 text-white mx-1 p-2 px-3 rounded-md cursor-pointer hover:bg-blue-900"
                      onClick={() => {
                        setOpen(true);
                        setUniversityInfo(university);
                        setModalType("showResponsableInfo");
                      }}
                    >
                      <span className="text-white text-lg">
                        <FaRegAddressCard />
                      </span>
                    </div>
                  </Tooltip>

                  <Tooltip
                    color="light"
                    content={getTranslation(translation, "UPDATE_UNIVERSITY")}
                  >
                    <div
                      className="bg-[#198754] text-white mx-1 p-2 px-3 rounded-md cursor-pointer hover:bg-green-900"
                      onClick={() => {
                        setOpen(true);
                        setUniversityInfo(university);
                        setModalType("updateUniversity");
                      }}
                    >
                      <span className="text-white text-lg">
                        <FaUniversity />
                      </span>
                    </div>
                  </Tooltip>

                  <Tooltip
                    color="light"
                    content={getTranslation(translation, "CHANGE_RESPONSABLE")}
                  >
                    <div
                      className="bg-[#0dcaf0] text-white mx-1 p-2 px-3 rounded-md cursor-pointer hover:bg-[#176574]"
                      onClick={() => {
                        setOpen(true);
                        setUniversityInfo(university);
                        setModalType("changeResponsable");
                      }}
                    >
                      {" "}
                      <span className="text-white text-lg">
                        <FaRegAddressCard />
                      </span>
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
Table.propTypes = {
  universities: PropTypes.array,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  setUniversities: PropTypes.func,
  setOpen: PropTypes.func,
  setUniversityInfo: PropTypes.func,
  setModalType: PropTypes.func,
};
export default Table;
