import React from "react";
import PropTypes from "prop-types";
import imageSrc from "../assets/login.jpg";

function RegisterLayout({ children }) {
  return (
    <div className="grid p-8 lg:p-0 lg:grid-cols-10 gap-0 min-h-screen">
      <main className="lg:col-span-5">{children}</main>
      <div className="hidden w-full p-0 m-0 lg:col-span-5 lg:flex lg:items-center lg:justify-center h-full">
        <img src={imageSrc} className="h-full w-full" alt="University space" />
      </div>
    </div>
  );
}

RegisterLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RegisterLayout;
