import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdEdit } from "react-icons/md";
import { getTranslation } from "../../../utils/translate/translation";
import { IoMdClose } from "react-icons/io";
import { toastErrorServer, toastSucces } from "../../toast/Toast";
import { CiNoWaitingSign } from "react-icons/ci";
import { MdDone } from "react-icons/md";
import {
  useUpdateUniversityMutation,
  useSendEtherToResponsableMutation,
} from "../../../store/service/universityService";
import {
  useRejectEtherDepositeDemandeMutation,
  useRejectCreditDepositeDemandeMutation,
  useAcceptEtherDepositeDemandeMutation,
  useAcceptCreditDepositeDemandeMutation,
} from "../../../store/service/usersService";
import { Tooltip } from "@material-tailwind/react";
import Spinner from "../../spinner/Spinner";
import { containsOnlyDigits } from "../../../utils/Validators/Validators";
import { FaEthereum } from "react-icons/fa";
export default function UpdateUniversity({
  onClose,
  universityInfo,
  reloadData,
}) {
  const { university, balance } = universityInfo;
  /*  console.log("university in update ", university);
  console.log("balance in update ", balance); */
  const translation = {
    en: {
      /* update university modal translate */
      UPDATE_UNIVERSITY_MODAL_TITLE: "Update University",
      UNIVERSITY_NAME: "University Name",
      UNIVERSITY_COUNTRY: "Country",
      UNIVERSITY_CREDIT: "Credit",
      TOAST_SUCCESS: "University updated successfully",
      TOAST_ERROR: "Failed to update university",
      TOAST_INFO: "Please fill all fields",
      TOAST_INFO_NOT_EMPTY: "Fields cannot be empty",
      TOAST_INFO_UNIVERSITY_NAME: "university Name must contain only letters",
      TOAST_INFO_UNIVERSITY_COUNTRY: "Country must contain only letters",
      TOAST_INFO_UNIVERSITY_CREDIT: "Credit must contain only numbers",
      SAVE_BUTTON: "Save",
      CANCEL_BUTTON: "Cancel",
      EDIT_BUTTON: "Edit",
      CURRENT_ETHER_BALANCE: "Current Ether Balance",
      SEND_ETHER_BUTTON: "Send Ether",
      ACCEPT_DEPOSIT_DEMANDE: "Accept deposit ether demande",
      REFUSE_DEPOSIT_DEMANDE: "Refuse Deposit ether demande",
      ETHER_DEPOSIT_DEMANDE: "Deposit ether demande",
      CREDIT_DEPOSIT_DEMANDE: "Deposit credit demande",
      ACCEPT_DEPOSIT_CREDIT_DEMANDE: "Accept deposit credit demande",
      REFUSE_DEPOSIT_CREDIT_DEMANDE: "Refuse Deposit credit demande",
      PROCESSING_CREDIT_DEMANDE: "Processing credit demande ...",
      PROCESSING_Ether_DEMANDE: "Processing Ether demande ...",
      ETHER_SEND_SUCCESS: "Ether sent successfully",
      FAILED_TO_SEND_ETHER: "Failed to send ether",
      CREDIT_DEPOSIT_DEMANDE_REJECTED: "Credit deposit demande rejected",
      FAILED_TO_REJECT_DEPOSIT_DEMANDE: "Failed to reject deposit demande",
      ETHER_DEPOSIT_DEMANDE_REJECTED: "Ether deposit demande rejected",
      ETHER_DEPOSIT_DEMANDE_ACCEPTED: "Ether deposit demande accepted",
      FAILED_TO_ACCEPT_DEPOSIT_DEMANDE: "Failed to accept deposit demande",
      CREDIT_DEPOSIT_DEMANDE_ACCEPTED: "Credit deposit demande accepted",
    },
    ar: {
      /* update university modal translate */
      UPDATE_UNIVERSITY_MODAL_TITLE: "تحديث الجامعة",
      UNIVERSITY_NAME: "اسم الجامعة",
      UNIVERSITY_COUNTRY: "البلد",
      UNIVERSITY_CREDIT: "الرصيد",
      TOAST_SUCCESS: "تم تحديث الجامعة بنجاح",
      TOAST_ERROR: "فشل تحديث الجامعة",
      TOAST_INFO: "يرجى ملء جميع الحقول",
      TOAST_INFO_NOT_EMPTY: "الحقول لا يمكن أن تكون فارغة",
      TOAST_INFO_UNIVERSITY_NAME: "اسم الجامعة يجب أن يحتوي على حروف فقط",
      TOAST_INFO_UNIVERSITY_COUNTRY: "البلد يجب أن يحتوي على حروف فقط",
      TOAST_INFO_UNIVERSITY_CREDIT: "الرصيد يجب أن يحتوي على أرقام فقط",
      SAVE_BUTTON: "حفظ",
      CANCEL_BUTTON: "إلغاء",
      EDIT_BUTTON: "تعديل",
      CURRENT_ETHER_BALANCE: "الرصيد الحالي للإيثر",
      SEND_ETHER_BUTTON: "إرسال الإيثر",
      ACCEPT_DEPOSIT_DEMANDE: "قبول طلب إيداع الإيثر",
      REFUSE_DEPOSIT_DEMANDE: "رفض طلب إيداع الإيثر",
      ETHER_DEPOSIT_DEMANDE: "طلب إيداع الإيثر",
      CREDIT_DEPOSIT_DEMANDE: "طلب إيداع الرصيد",
      ACCEPT_DEPOSIT_CREDIT_DEMANDE: "قبول طلب إيداع الرصيد",
      REFUSE_DEPOSIT_CREDIT_DEMANDE: "رفض طلب إيداع الرصيد",
      PROCESSING_CREDIT_DEMANDE: "جاري معالجة طلب الرصيد ...",
      PROCESSING_Ether_DEMANDE: "جاري معالجة طلب الإيثر ...",
      ETHER_SEND_SUCCESS: "تم إرسال الإيثر بنجاح",
      FAILED_TO_SEND_ETHER: "فشل في إرسال الإيثر",
      CREDIT_DEPOSIT_DEMANDE_REJECTED: "تم رفض طلب إيداع الرصيد",
      FAILED_TO_REJECT_DEPOSIT_DEMANDE: "فشل في رفض طلب الإيداع",
      ETHER_DEPOSIT_DEMANDE_REJECTED: "تم رفض طلب إيداع الإيثر",
      ETHER_DEPOSIT_DEMANDE_ACCEPTED: "تم قبول طلب إيداع الإيثر",
      FAILED_TO_ACCEPT_DEPOSIT_DEMANDE: "فشل في قبول طلب الإيداع",
      CREDIT_DEPOSIT_DEMANDE_ACCEPTED: "تم قبول طلب إيداع الرصيد",
    },
    fr: {
      /* update university modal translate */
      UPDATE_UNIVERSITY_MODAL_TITLE: "Mettre à jour l'université",
      UNIVERSITY_NAME: "Nom de l'université",
      UNIVERSITY_COUNTRY: "Pays",
      UNIVERSITY_CREDIT: "Crédit",
      TOAST_SUCCESS: "Université mise à jour avec succès",
      TOAST_ERROR: "Impossible de mettre à jour l'université",
      TOAST_INFO: "Veuillez remplir tous les champs",
      TOAST_INFO_NOT_EMPTY: "Les champs ne peuvent pas être vides",
      TOAST_INFO_UNIVERSITY_NAME:
        "Le nom de l'université ne doit contenir que des lettres",
      TOAST_INFO_UNIVERSITY_COUNTRY: "Le pays ne doit contenir que des lettres",
      TOAST_INFO_UNIVERSITY_CREDIT:
        "Le crédit ne doit contenir que des chiffres",
      SAVE_BUTTON: "Enregistrer",
      CANCEL_BUTTON: "Annuler",
      EDIT_BUTTON: "Modifier",
      CURRENT_ETHER_BALANCE: "Solde actuel en éther",
      SEND_ETHER_BUTTON: "Envoyer de l'éther",
      ACCEPT_DEPOSIT_DEMANDE: "Accepter la demande de dépôt d'éther",
      REFUSE_DEPOSIT_DEMANDE: "Refuser la demande de dépôt d'éther",
      ETHER_DEPOSIT_DEMANDE: "Demande de dépôt d'éther",
      CREDIT_DEPOSIT_DEMANDE: "Demande de dépôt de crédit",
      ACCEPT_DEPOSIT_CREDIT_DEMANDE: "Accepter la demande de dépôt de crédit",
      REFUSE_DEPOSIT_CREDIT_DEMANDE: "Refuser la demande de dépôt de crédit",
      PROCESSING_CREDIT_DEMANDE: "Traitement de la demande de crédit ...",
      PROCESSING_Ether_DEMANDE: "Traitement de la demande d'éther ...",
      ETHER_SEND_SUCCESS: "Éther envoyé avec succès",
      FAILED_TO_SEND_ETHER: "Échec de l'envoi de l'éther",
      CREDIT_DEPOSIT_DEMANDE_REJECTED: "Demande de dépôt de crédit rejetée",
      ETHER_DEPOSIT_DEMANDE_REJECTED: "Demande de dépôt d'éther rejetée",
      FAILED_TO_REJECT_DEPOSIT_DEMANDE: "Échec du rejet de la demande de dépôt",
      ETHER_DEPOSIT_DEMANDE_ACCEPTED: "Demande de dépôt d'éther acceptée",
      FAILED_TO_ACCEPT_DEPOSIT_DEMANDE:
        "Échec de l'acceptation de la demande de dépôt",
      CREDIT_DEPOSIT_DEMANDE_ACCEPTED: "Demande de dépôt de crédit acceptée",
    },
  };

  const [updateUniversity] = useUpdateUniversityMutation();
  const [rejectEtherDepositeDemande] = useRejectEtherDepositeDemandeMutation();
  const [rejectCreditDepositeDemande] =
    useRejectCreditDepositeDemandeMutation();
  const [acceptCreditDepositeDemande] =
    useAcceptCreditDepositeDemandeMutation();
  const [acceptEtherDepositeDemande] = useAcceptEtherDepositeDemandeMutation();
  const [sendEtherToResponsable] = useSendEtherToResponsableMutation();
  const [loadingSendEther, setLoadingSendEther] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
  const [updatedInfo, setUpdatedInfo] = useState({
    universityId: university?._id,
    name: university?.name,
    country: university?.country,
    credit: university?.credit,
    universityBlockchainAdress: university?.blockchainAddress,
    universityBlockchainPrivateKey: university?.privateKey,
  }); // Track form data
  const [DepositeCreditDemandeAmount, setDepositeCreditDemandeAmount] =
    useState(university?.CreditDepositeDemand?.data?.creditAmount);
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  const handleEditMode = () => setIsEditMode(!isEditMode);
  const [
    onAcceptOrRejectCreditDepositeDemande,
    setOnAcceptOrRejectCreditDepositeDemande,
  ] = useState(false);
  const [
    onAcceptOrRejectEtherDepositeDemande,
    setOnAcceptOrRejectEtherDepositeDemande,
  ] = useState(false);
  const onValidForm = () => {
    // Regular expressions for validation
    const lettersOnlyRegex = /^[\p{L}\s]+$/u;
    const numbersOnlyRegex = /^[0-9]+$/; // Allows only numbers

    // Check if any field is empty
    if (
      updatedInfo.name === "" ||
      updatedInfo.country === "" ||
      updatedInfo.credit === ""
    ) {
      toastErrorServer(
        `${getTranslation(translation, "TOAST_INFO_NOT_EMPTY")}`
      );
      return false;
    }

    // Check if name or country contain only letters
    if (!lettersOnlyRegex.test(updatedInfo.name)) {
      toastErrorServer(
        `${getTranslation(translation, "TOAST_INFO_UNIVERSITY_NAME")}`
      );
      return false;
    }

    if (!lettersOnlyRegex.test(updatedInfo.country)) {
      toastErrorServer(
        `${getTranslation(translation, "TOAST_INFO_UNIVERSITY_COUNTRY")}:`
      );
      return false;
    }

    // Check if credit contains only numbers
    if (!numbersOnlyRegex.test(updatedInfo.credit)) {
      alert("Credit must contain only numbers");
      toastErrorServer(
        `${getTranslation(translation, "TOAST_INFO_UNIVERSITY_CREDIT")}`
      );
      return false;
    }

    // If all validations pass
    return true;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    /*   console.log(name, value, type); */
    if (type === "number" && !containsOnlyDigits(value) && value !== "") {
      return; // Do not update the value if it contains non-numeric characters
    }
    setUpdatedInfo({ ...updatedInfo, [name]: value }); // Update form values
  };
  const UpdateUniversityInfo = () => {
    if (onValidForm()) {
      setLoading(true);
      /*   console.log("updatedInfo", updatedInfo); */
      updateUniversity(updatedInfo)
        .unwrap()
        .then((res) => {
          console.log(res);
          toastSucces(`${getTranslation(translation, "TOAST_SUCCESS")}`);
          setLoading(false);
          //reset edit mode
          setIsEditMode(false);
          // empty updatedInfo
          setUpdatedInfo({});
          //reload data
          reloadData();
          // Close the modal
          onClose();
        })
        .catch((err) => {
          console.log(err);
          toastErrorServer(`${getTranslation(translation, "TOAST_ERROR")}`);
          setLoading(false);
        });
    }
  };

  const convertWeiToEther = (wei) => {
    //chow only 4 decimal points
    return (wei / Math.pow(10, 18)).toFixed(9);
  };
  const SendEther = async (universityBlockchainAdress) => {
    if (universityBlockchainAdress) {
      //send ether to responsable

      try {
        setLoadingSendEther(true);
        sendEtherToResponsable({
          universityBlockchainAdress: universityBlockchainAdress,
        })
          .unwrap()
          .then((res) => {
            console.log(res);
            setLoadingSendEther(false);
            reloadData();
            onClose();
            toastSucces(`${getTranslation(translation, "ETHER_SEND_SUCCESS")}`);
          });
      } catch (error) {
        console.log(error);
        toastErrorServer(
          `${getTranslation(translation, "FAILED_TO_SEND_ETHER")}`
        );
        setLoadingSendEther(false);
      }
    }
  };
  const RejectCreditDepositDemande = async () => {
    try {
      setOnAcceptOrRejectCreditDepositeDemande(true);
      rejectCreditDepositeDemande(university)
        .unwrap()
        .then((res) => {
          if (res) {
            console.log(res);
            setOnAcceptOrRejectCreditDepositeDemande(false);

            toastSucces(
              `${getTranslation(
                translation,
                "CREDIT_DEPOSIT_DEMANDE_REJECTED"
              )}`
            );
            setUpdatedInfo({});
            reloadData();
            onClose();
          } else {
            setOnAcceptOrRejectCreditDepositeDemande(false);
            toastErrorServer(
              `${getTranslation(
                translation,
                "FAILED_TO_REJECT_DEPOSIT_DEMANDE"
              )}`
            );
          }
        });
    } catch (error) {
      console.log(error);
      setOnAcceptOrRejectCreditDepositeDemande(false);
      toastErrorServer(
        `${getTranslation(translation, "FAILED_TO_REJECT_DEPOSIT_DEMANDE")}`
      );
    }
  };
  const RejectEtherDepositDemande = async () => {
    try {
      setOnAcceptOrRejectEtherDepositeDemande(true);
      rejectEtherDepositeDemande(university)
        .unwrap()
        .then((res) => {
          if (res) {
            setOnAcceptOrRejectEtherDepositeDemande(false);
            reloadData();
            onClose();
            toastSucces(
              `${getTranslation(translation, "ETHER_DEPOSIT_DEMANDE_REJECTED")}`
            );
          } else {
            setOnAcceptOrRejectEtherDepositeDemande(false);
            toastErrorServer(
              `${getTranslation(
                translation,
                "FAILED_TO_REJECT_DEPOSIT_DEMANDE"
              )}`
            );
          }
        });
    } catch (error) {
      console.log(error);
      setOnAcceptOrRejectEtherDepositeDemande(false);
      toastErrorServer(
        `${getTranslation(translation, "FAILED_TO_REJECT_DEPOSIT_DEMANDE")}`
      );
    }
  };
  const AcceptEtherDepositDemande = async () => {
    /* console.log("here is clicked in accept ether deposit demande", university); */
    try {
      setOnAcceptOrRejectEtherDepositeDemande(true);

      acceptEtherDepositeDemande(university)
        .unwrap()
        .then((res) => {
          if (res) {
            setOnAcceptOrRejectEtherDepositeDemande(false);
            reloadData();
            onClose();
            toastSucces(
              `${getTranslation(translation, "ETHER_DEPOSIT_DEMANDE_ACCEPTED")}`
            );
          } else {
            setOnAcceptOrRejectEtherDepositeDemande(false);
            toastErrorServer(
              `${getTranslation(
                translation,
                "FAILED_TO_ACCEPT_DEPOSIT_DEMANDE"
              )}`
            );
          }
        });
    } catch (error) {
      console.log(error);
      setOnAcceptOrRejectEtherDepositeDemande(false);
      toastErrorServer(
        `${getTranslation(translation, "FAILED_TO_ACCEPT_DEPOSIT_DEMANDE")}`
      );
    }
  };
  const AcceptCreditDepositDemande = async (DepositeCreditDemandeAmount) => {
    try {
      setOnAcceptOrRejectCreditDepositeDemande(true);

      acceptCreditDepositeDemande({
        university: university,
        creditAmount: DepositeCreditDemandeAmount,
      })
        .unwrap()
        .then((res) => {
          if (res) {
            setOnAcceptOrRejectCreditDepositeDemande(false);
            reloadData();
            onClose();
            toastSucces(
              `${getTranslation(
                translation,
                "CREDIT_DEPOSIT_DEMANDE_ACCEPTED"
              )}`
            );
          } else {
            setOnAcceptOrRejectCreditDepositeDemande(false);
            toastErrorServer(
              `${getTranslation(
                translation,
                "FAILED_TO_ACCEPT_DEPOSIT_DEMANDE"
              )}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setOnAcceptOrRejectCreditDepositeDemande(false);

          toastErrorServer(
            `${getTranslation(translation, "FAILED_TO_ACCEPT_DEPOSIT_DEMANDE")}`
          );
        });
    } catch (error) {
      console.log(error);
      setOnAcceptOrRejectCreditDepositeDemande(false);
      toastErrorServer(
        `${getTranslation(translation, "FAILED_TO_ACCEPT_DEPOSIT_DEMANDE")}`
      );
    }
  };
  return (
    <>
      <div className="flex items-center  justify-between bg-[#285090] text-white">
        <h2 className="text-xl p-1.5 px-2 font-semibold">
          {getTranslation(translation, "UPDATE_UNIVERSITY_MODAL_TITLE")}
        </h2>
        <span
          onClick={onClose}
          className="cursor-pointer text-xl p-2.5 transition ease-in-out hover:bg-red-600 hover:text-white"
        >
          <IoMdClose />
        </span>
      </div>
      {loading ? (
        <>
          <div className="flex items-center justify-center h-20 p-4">
            <Spinner />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="p-4 px-3 space-y-3">
            {/* Editable fields */}

            <div className="flex items-center justify-between gap-2">
              <span className="font-bold">
                {getTranslation(translation, "UNIVERSITY_NAME")}
              </span>

              <input
                type="text"
                name="name"
                disabled={!isEditMode}
                value={updatedInfo?.name || ""}
                placeholder={getTranslation(translation, "UNIVERSITY_NAME")}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200 disabled:bg-gray-200"
              />
            </div>

            <div className="flex items-center justify-between gap-4">
              <span className="font-bold">
                {getTranslation(translation, "UNIVERSITY_COUNTRY")}
              </span>

              <input
                type="text"
                name="country"
                disabled={!isEditMode}
                value={updatedInfo?.country || ""}
                placeholder={getTranslation(translation, "UNIVERSITY_COUNTRY")}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200 disabled:bg-gray-200"
              />
            </div>

            <div
              className={`mt-4 ${
                university?.CreditDepositeDemand?.status &&
                "border-2 p-2 rounded-md justify-between border-gray-400"
              }`}
            >
              <div className="flex justify-between items-center gap-4">
                <span className="font-bold">
                  {getTranslation(translation, "UNIVERSITY_CREDIT")}
                </span>

                <input
                  type="number"
                  name="credit"
                  lang="en"
                  disabled={!isEditMode}
                  value={updatedInfo?.credit || ""}
                  placeholder={getTranslation(translation, "UNIVERSITY_CREDIT")}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200 disabled:bg-gray-200"
                />
              </div>
              {university?.CreditDepositeDemand?.status && (
                <div className="flex items-center pt-2 mt-4 mb-2 border-t-2 border-solid">
                  <p>
                    {getTranslation(translation, "CREDIT_DEPOSIT_DEMANDE")}{" "}
                  </p>

                  <div className="mx-2">
                    <input
                      type="number"
                      lang="en"
                      disabled={onAcceptOrRejectCreditDepositeDemande}
                      defaultValue={
                        university?.CreditDepositeDemand?.data?.creditAmount
                      }
                      onChange={(e) => {
                        setDepositeCreditDemandeAmount(e.target.value);
                      }}
                      className="border w-20 border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-[#285090] transition duration-200 disabled:bg-gray-200"
                    />
                  </div>

                  {onAcceptOrRejectCreditDepositeDemande ? (
                    <>
                      <Spinner />{" "}
                      {getTranslation(translation, "PROCESSING_CREDIT_DEMANDE")}
                    </>
                  ) : (
                    <div className="flex items-center gap-2 mx-2">
                      <Tooltip
                        color="light"
                        content={getTranslation(
                          translation,
                          "REFUSE_DEPOSIT_CREDIT_DEMANDE"
                        )}
                      >
                        <button
                          onClick={RejectCreditDepositDemande}
                          className={`p-2 bg-red-500 rounded-md text-white cursor-pointer hover:bg-red-600  ${
                            onAcceptOrRejectCreditDepositeDemande &&
                            "cursor-not-allowed"
                          }`}
                          disabled={onAcceptOrRejectCreditDepositeDemande}
                        >
                          <CiNoWaitingSign />
                        </button>
                      </Tooltip>
                      <Tooltip
                        color="light"
                        content={getTranslation(
                          translation,
                          "ACCEPT_DEPOSIT_CREDIT_DEMANDE"
                        )}
                      >
                        <button
                          onClick={() => {
                            AcceptCreditDepositDemande(
                              DepositeCreditDemandeAmount
                            );
                          }}
                          disabled={onAcceptOrRejectCreditDepositeDemande}
                          className={`p-2 bg-blue-600 rounded-md text-white cursor-pointer hover:bg-blue-700   ${
                            onAcceptOrRejectCreditDepositeDemande &&
                            "cursor-not-allowed"
                          }`}
                        >
                          <MdDone />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className={`mt-4 ${
                university?.EtherDepositeDemand?.status &&
                "border-2 p-2 rounded-md border-gray-400"
              }`}
            >
              <div className={`flex items-center justify-between gap-4`}>
                <div className="flex items-center gap-3">
                  <span className="font-bold">
                    {getTranslation(translation, "CURRENT_ETHER_BALANCE")}
                  </span>
                  <p className="text-gray-700">{convertWeiToEther(balance)}</p>
                </div>
                <button
                  className={`flex items-center justify-center rounded-md bg-green-700 text-white p-2 ${
                    loadingSendEther ||
                    (!university.blockchainAddress && "cursor-not-allowed")
                  }`}
                  disabled={loadingSendEther || !university.blockchainAddress}
                  onClick={() => {
                    //notice ** we send the adress of the university to send ether to it because it will execute the transaction later
                    SendEther(university.blockchainAddress);
                  }}
                >
                  {loadingSendEther ? (
                    <Spinner />
                  ) : (
                    getTranslation(translation, "SEND_ETHER_BUTTON")
                  )}

                  <FaEthereum />
                </button>
              </div>
              {university?.EtherDepositeDemand?.status && (
                <div className="flex items-center pt-2 mt-4 mb-2 border-t-2 border-solid">
                  <p>{getTranslation(translation, "ETHER_DEPOSIT_DEMANDE")} </p>
                  {onAcceptOrRejectEtherDepositeDemande ? (
                    <>
                      <Spinner />{" "}
                      {getTranslation(translation, "PROCESSING_Ether_DEMANDE")}
                    </>
                  ) : (
                    <div className="flex items-center gap-2 mx-2">
                      <Tooltip
                        color="light"
                        content={getTranslation(
                          translation,
                          "REFUSE_DEPOSIT_DEMANDE"
                        )}
                      >
                        <button
                          onClick={RejectEtherDepositDemande}
                          className="p-2 text-white bg-red-500 rounded-md cursor-pointer hover:bg-red-600"
                        >
                          <CiNoWaitingSign />
                        </button>
                      </Tooltip>
                      <Tooltip
                        color="light"
                        content={getTranslation(
                          translation,
                          "ACCEPT_DEPOSIT_DEMANDE"
                        )}
                      >
                        <button
                          onClick={AcceptEtherDepositDemande}
                          className="p-2 text-white bg-blue-600 rounded-md cursor-pointer hover:bg-blue-700"
                        >
                          <MdDone />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>{" "}
          {/* Edit and Update Buttons */}
          <div className="p-2">
            {isEditMode ? (
              <div className="flex items-center justify-around py-2">
                <button
                  onClick={UpdateUniversityInfo}
                  className="bg-[#285090] w-1/2 text-white py-1 mx-1 px-4 rounded-md hover:bg-[#1c3c73] transition"
                >
                  {getTranslation(translation, "SAVE_BUTTON")}
                </button>
                <button
                  onClick={handleEditMode}
                  className="w-1/2 px-4 py-1 mx-1 ml-2 text-white transition bg-red-600 rounded-md hover:bg-red-700"
                >
                  {getTranslation(translation, "CANCEL_BUTTON")}
                </button>
              </div>
            ) : (
              <button
                onClick={handleEditMode}
                className="bg-[#285090] text-white py-2 px-4 rounded-md transition flex items-center justify-center gap-2 w-full text-center font-bold hover:!bg-blue-800"
              >
                <MdEdit /> {getTranslation(translation, "EDIT_BUTTON")}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

UpdateUniversity.propTypes = {
  onClose: PropTypes.func,
  universityInfo: PropTypes.object,
  reloadData: PropTypes.func,
};
