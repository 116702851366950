import React from "react";
import { useState, useEffect } from "react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import PropTypes from "prop-types";

export default function LanguageButton({ openDirection, extraClass }) {
  const [SelectedLanguage, setSelectedLanguage] = useState("EN");
  if (!localStorage.getItem("language")) {
    localStorage.setItem("language", "en");
  }
  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("language").toUpperCase());
  }, []);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem("language", language.toLowerCase());
    window.location.reload();
  };
  return (
    <div>
      <div className="relative group">
        <button
          className={`bg-white flex items-center gap-2 min-w-[70px] text-black/80 px-4 py-2 rounded-lg group-hover:bg-blue-900 group-hover:border-white group-hover:text-white ${extraClass}`}
        >
          <div className="flex items-center gap-2">
            {SelectedLanguage === "EN" ? (
              <span className="fi fi-gb"></span>
            ) : SelectedLanguage === "AR" ? (
              <span className="fi fi-arab"></span>
            ) : (
              <span className="fi fi-fr"></span>
            )}
            {SelectedLanguage}
          </div>
        </button>
        <div
          className={`absolute ${openDirection}-10 left-0 w-full bg-white rounded-lg shadow-lg hidden group-hover:block`}
        >
          <button
            onClick={() => {
              handleLanguageChange("EN");
            }}
            className="w-full flex items-center gap-2 rounded-t-lg text-left py-2 p-2 rounded-b-lg hover:bg-gray-300"
          >
            <div>
              <span className="fi fi-gb"></span>
            </div>
            <span>EN</span>
          </button>
          <button
            onClick={() => {
              handleLanguageChange("AR");
            }}
            className="w-full flex items-center gap-2 text-left py-2 p-2 rounded-b-lg hover:bg-gray-300"
          >
            <span className="fi fi-arab"></span>
            <span>AR</span>
          </button>
          <button
            onClick={() => {
              handleLanguageChange("FR");
            }}
            className="w-full flex items-center gap-2 text-left py-2 p-2 rounded-b-lg hover:bg-gray-300"
          >
            <div>
              <span className="fi fi-fr"></span>
            </div>
            <span>FR</span>
          </button>
        </div>
      </div>
    </div>
  );
}
LanguageButton.propTypes = {
  openDirection: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
};
