import React from "react";

import PropTypes from "prop-types";
export default function FooterSection({ title, companyLogo }) {
  return (
    <div>
      <div>
        <h1 className="text-center font-black">{title}</h1>
        <div className="flex justify-center items-center my-8">
          <img src={companyLogo} alt="" className="w-52" />
        </div>
      </div>
    </div>
  );
}

FooterSection.propTypes = {
  title: PropTypes.string.isRequired,
  companyLogo: PropTypes.string.isRequired,
};
