import React from "react";
import PropTypes from "prop-types";
import { diplomasIcon, loginIcons, studentsIcon } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    NB_STUDENTS: " عدد الطلبة",
    NB_DIPLOMAS: "عدد الشهادات",
    VISITE_BY_DAY: "الزيارات اليومية",
  },
  en: {
    NB_STUDENTS: " Number of students",
    NB_DIPLOMAS: "Number of Diplomas",
    VISITE_BY_DAY: "Daily visits",
  },
  fr: {
    NB_STUDENTS: "Nombre d'étudiants",
    NB_DIPLOMAS: " Nombre de Diplomes",
    VISITE_BY_DAY: "Visites quotidiennes",
  },
};
const Card = ({ data }) => {
  return (
    <div className="xs:grid sm:w-auto md:flex lg:flex gap-6 justify-between">
      <div className="my-4 flex items-center bg-white  lg:w-1/4 rounded-xl md:w-1/3 sm:w-full">
        <div
          className={`bg-[#57caeb] ltr:rounded-l-md rtl:rounded-r-md text-center flex items-center justify-center text-white min-h-[80px] h-full min-w-[60px]`}
        >
          {studentsIcon}
        </div>
        <div className="flex flex-col items-start justify-start py-3 mx-4">
          <span className="text-[#7c8db5] text-xl">
            {getTranslation(translate, "NB_STUDENTS")}
          </span>
          <span className="font-extrabold text-lg leading-5  text-[#25396f]">
            {data.nbStudents}
          </span>
        </div>
      </div>
      <div className="my-4 flex items-center bg-white  lg:w-1/4 rounded-xl md:w-1/3 sm:w-full ">
        <div
          className={`bg-[#5ddab4] ltr:rounded-l-md rtl:rounded-r-md text-center flex items-center justify-center text-white min-h-[80px] h-full min-w-[60px]`}
        >
          {diplomasIcon}
        </div>
        <div className="flex flex-col items-start justify-start py-3 mx-4">
          <span className="text-[#7c8db5] text-xl">
            {" "}
            {getTranslation(translate, "NB_DIPLOMAS")}
          </span>
          <span className="font-extrabold leading-5 text-lg text-[#25396f]">
            {data.nbDiplomas}
          </span>
        </div>
      </div>
      <div className="my-4 flex items-center bg-white lg:w-1/4 rounded-xl md:w-1/3 sm:w-full ">
        <div
          className={`bg-[#9694ff] ltr:rounded-l-md rtl:rounded-r-md text-center flex items-center justify-center text-white min-h-[80px] h-full min-w-[60px]`}
        >
          {loginIcons}
        </div>
        <div className="flex flex-col items-start justify-start py-3 mx-4">
          <span className="text-[#7c8db5] text-xl">
            {" "}
            {getTranslation(translate, "VISITE_BY_DAY")}
          </span>
          <span className="font-extrabold leading-5 text-lg text-[#25396f]">
            {data.nbLogin.toString().slice(0, 4)}
          </span>
        </div>
      </div>
    </div>
  );
};
Card.propTypes = {
  data: PropTypes.object,
};
export default Card;
