import PropTypes from "prop-types";
import NavigationBarButton from "./NavigationBarButton";
import { useId } from "react";

function NavigationBarAccordion({
  title,
  icon,
  subMenu,
  selectedAccordion,
  setSelectedAccordion,
  showCompletesidebar,
  accordionName,
  isBadgeActive,
}) {
  const buttonContainerStyles = `my-4 flex items-center justify-between cursor-pointer w-full h-12 flex rounded font-normal ${
    location.pathname === "/"
      ? "bg-[#435ebe] text-white"
      : "hover:bg-[#f0f1f5] text-[#25396f]"
  }`;
  const isSubMenuClicked = () => {
    for (const element of subMenu) {
      if (element.path === location.pathname) {
        return true;
      }
    }
  };
  const changeAccordionState = () => {
    if (accordionName === selectedAccordion && !isSubMenuClicked()) {
      setSelectedAccordion("");
    } else {
      setSelectedAccordion(accordionName);
    }
  };

  const isAccordionOpen = selectedAccordion === accordionName;
  const subMenuId = useId();
  return (
    <div
      className="w-full border-none rounded-lg "
      onClick={changeAccordionState}
    >
      <button className={buttonContainerStyles}>
        <NavigationBarButton
          icon={icon}
          activePath={"/"}
          path={"/"}
          title={title}
          isBadgeActive={isBadgeActive}
          showCompletesidebar={showCompletesidebar}
        />

        <svg
          className={`w-6 h-6 transition-transform ${
            isAccordionOpen && "transform rotate-180"
          }`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isAccordionOpen && (
        <div className={`py-1 ${showCompletesidebar ? "px-4" : "px-1"}`}>
          {subMenu.map((item) => (
            <NavigationBarButton
              key={subMenuId}
              icon={item.icon}
              activePath={item.activePath}
              path={item.path}
              title={item.title}
              tooltipValue={"tooltipvalue"}
              action={item.selectPage}
              isBadgeActive={item.isBadgeActive}
              showCompletesidebar={showCompletesidebar}
            />
          ))}
        </div>
      )}
    </div>
  );
}

NavigationBarAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  subMenu: PropTypes.array.isRequired,
  activeBadge: PropTypes.bool,
  activePath: PropTypes.string,
  path: PropTypes.string,
  action: PropTypes.func,
  selectedPage: PropTypes.string,
  selectedAccordion: PropTypes.string.isRequired,
  setSelectedAccordion: PropTypes.func,
  accordionName: PropTypes.string,
  isBadgeActive: PropTypes.bool,
  showCompletesidebar: PropTypes.bool,
};

export default NavigationBarAccordion;
