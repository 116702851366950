import React from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    partnerName: "K2lis",
    allRightsReserverd: "All rights reserved.",
  },
  fr: {
    partnerName: "K2lis",
    allRightsReserverd: "Tous droits réservés.",
  },
  ar: {
    partnerName: "K2lis",
    allRightsReserverd: "كل الحقوق محفوظة.",
  },
};
export default function CopyRightSection({ companyName }) {
  return (
    <div className="p-2 text-center my-4">
      <p className="border-t border-blue-900 py-4 text-sm">
        {" "}
        © Copyright{" "}
        <span className="font-bold">
          {companyName} - {getTranslation(translate, "partnerName")}
        </span>{" "}
        {getTranslation(translate, "allRightsReserverd")}
      </p>
    </div>
  );
}
CopyRightSection.propTypes = {
  title: PropTypes.string.isRequired,
  companyLogo: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};
