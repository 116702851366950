import React from "react";
import PropTypes from "prop-types";
import Button from "../button/Button";

export default function AreaItem({
  AriaTitle,
  AriaIcon,
  AriaTitletype,
  listItem,
  btnColor,
  isLink,
  LinkTo,
  showBanner,
  bannerColor,
  bannerText,
  aosAnimation,
  aosAnimationDelay,
}) {
  return (
    <div
      className="flex flex-col text-center overflow-hidden bg-[#f8f8f8] w-full p-0.5  border-2 border-gray-300/20 rounded-md shadow-md lg:min-h-[430px] xl:min-h-[400px]"
      data-aos={aosAnimation}
      data-aos-delay={aosAnimationDelay}
      data-aos-duration="1200"
    >
      <div className="relative">
        {showBanner && (
          <div
            className={`bg-[${bannerColor}] text-white text-center absolute top-[18px] right-[-68px] w-[200px] rotate-45 z-10 text-sm`}
          >
            {bannerText}
          </div>
        )}
        <div
          className={`text-center font-black py-4 rounded-t-md ${AriaTitletype}`}
        >
          <h3>{AriaTitle} </h3>
        </div>

        <div className="bg-white py-8 flex-grow lg:min-h-[310px]">
          <div className="text-[#010483] my-2 text-center flex justify-center items-center text-5xl">
            {AriaIcon}
          </div>
          <div>
            <ul className="text-center py-2 text-sm">
              {/* Dynamically Render Additional List Items */}
              {listItem.map((item, index) => (
                <li className="my-1 p-1" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-auto bg-[#f8f8f8]">
          <Button
            text="Login"
            btnColor={btnColor}
            isLink={isLink}
            LinkTo={LinkTo}
          />
        </div>
      </div>
    </div>
  );
}

AreaItem.propTypes = {
  AriaTitle: PropTypes.string.isRequired,
  AriaIcon: PropTypes.string.isRequired,
  AriaTitletype: PropTypes.string.isRequired,
  listItem: PropTypes.arrayOf(PropTypes.string).isRequired,
  showBanner: PropTypes.bool,
  bannerText: PropTypes.string,
  btnColor: PropTypes.string,
  isLink: PropTypes.bool,
  LinkTo: PropTypes.string,
  bannerColor: PropTypes.string,
  aosAnimation: PropTypes.string,
  aosAnimationDelay: PropTypes.string,
};

AreaItem.defaultProps = {
  showBanner: false,
  bannerText: "",
  bannerColor: "#1acc8d",
  btnColor: "#1acc8d",
};
