import { configureStore } from "@reduxjs/toolkit";
import diplomaReducer from "./features/diplomasSlice/diplomasSlice";
import authReducer from "./features/auth/authSlice";
import studentsReducer from "./features/studentsSlice/studentsSlice";
import userReducer from "./features/userSlice/userSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import studentReducer from "./features/studentSlice/studentSlice";
import usersReducer from "./features/usersSlice/usersSlice";
import universityReducer from "./features/universitySlice/universitySlice";
import { studentsApi } from "./service/studentsService";
import { usersApi } from "./service/usersService";
import { diplomasApi } from "./service/diplomasService";
import { userApi } from "./service/userService";
import { authApi } from "./service/authService";
import { dashboardApi } from "./service/dashboardService";
import { universitiesApi } from "./service/universityService";

const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    students: studentsReducer,
    users: usersReducer,
    student: studentReducer,
    diploma: diplomaReducer,
    dashboard: dashboardReducer,
    university: universityReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [studentsApi.reducerPath]: studentsApi.reducer,
    [diplomasApi.reducerPath]: diplomasApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [universitiesApi.reducerPath]: universitiesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      usersApi.middleware,
      studentsApi.middleware,
      diplomasApi.middleware,
      userApi.middleware,
      authApi.middleware,
      dashboardApi.middleware
    ),
});

export default store;
