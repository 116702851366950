import React from "react";
import SectionTitle from "../section-title/SectionTitle";
import GoalItem from "./GoalItem";
import { FaRegFlag } from "react-icons/fa";
import { RiFileList3Line } from "react-icons/ri";
import { GoMortarBoard } from "react-icons/go";
import { BsBank } from "react-icons/bs";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    SectionTitle: "Platform objectives ",
    SectionDescription: "By 2023, achieve the inclusion of:",
    GoalItem1: "Arab country",
    GoalItem2: "university",
    GoalItem3: "student",
    GoalItem4: "Digital diploma",
  },
  fr: {
    SectionTitle: "Objectifs de la plateforme",
    SectionDescription: "A l'horizon 2023, atteindre l'inclusion de :",
    GoalItem1: "pays arabes",
    GoalItem2: "université",
    GoalItem3: "étudiant",
    GoalItem4: "Diplôme numérique",
  },
  ar: {
    SectionTitle: "أهداف المنصة",
    SectionDescription: `في أفق 2023، الوصول إلى إدراج :`,
    GoalItem1: "دولة عربية ",
    GoalItem2: "جامعة",
    GoalItem3: "طالب",
    GoalItem4: "شهادة رقمية ",
  },
};
export default function SixthSection() {
  return (
    <div id="goals" className="bg-[#f5f5ff] py-12">
      <div className="container mx-auto">
        <SectionTitle
          title={getTranslation(translate, "SectionTitle")}
          p={getTranslation(translate, "SectionDescription")}
        />
        <div
          className="grid grid-cols-1 p-2 gap-3 w-full md:gap-3 md:grid-cols-2 lg:gap-5 lg:grid-cols-4"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1200"
          data-aos-once="true"
          data-aos-mirror="false"
        >
          <GoalItem
            icon={<FaRegFlag />}
            text={getTranslation(translate, "GoalItem1")}
            count={"24"}
          />
          <GoalItem
            icon={<BsBank />}
            text={getTranslation(translate, "GoalItem2")}
            count={"1000"}
          />
          <GoalItem
            icon={<GoMortarBoard />}
            text={getTranslation(translate, "GoalItem3")}
            count={"500000"}
          />
          <GoalItem
            icon={<RiFileList3Line />}
            text={getTranslation(translate, "GoalItem4")}
            count={"1000000"}
          />
        </div>
      </div>
    </div>
  );
}
