import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
export default function Button({ text, btnColor, isLink, LinkTo }) {
  return (
    <div>
      {isLink ? (
        <Link to={LinkTo}>
          <button
            className={`bg-[${btnColor}] text-sm text-white rounded-full w-28 my-4 py-2 transition-all duration-200 ease-in-out hover:bg-[#359d79]`}
          >
            {text}
          </button>
        </Link>
      ) : (
        <button
          className={`bg-[${btnColor}] text-sm text-white rounded-full w-28 my-4 py-2 transition-all duration-200 ease-in-out hover:bg-[#359d79]`}
        >
          {text}
        </button>
      )}
    </div>
  );
}
Button.propTypes = {
  text: PropTypes.string.isRequired,
  btnColor: PropTypes.string,
  isLink: PropTypes.bool,
  LinkTo: PropTypes.string,
};
