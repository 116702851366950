import React from "react";
import { IoClose } from "react-icons/io5";
import NavBarItem from "../nav-bar/NavBarItem";
import PropTypes from "prop-types";
import LanguageButton from "../button/LanguageButton";
import { getTranslation } from "../../../utils/translate/translation";

const translate = {
  en: {
    Major: "Major",
    Properties: "Properties",
    AccessToThePlatform: "Access to the platform",
    Blockchain: "Blockchain",
    HowItWorks: "How it works",
    Goals: "Goals",
    ContactUs: "Contact us",
  },
  fr: {
    Major: "Principal",
    Properties: "Propriétés",
    AccessToThePlatform: "Accès à la plateforme",
    Blockchain: "Blockchain",
    HowItWorks: "Comment ça marche",
    Goals: "Objectifs",
    ContactUs: "Contactez-nous",
  },
  ar: {
    Major: "الرئيسية",
    Properties: "الخصائص",
    AccessToThePlatform: "الوصول إلى المنصة",
    Blockchain: "سلسلة كتل",
    HowItWorks: "كيف يعمل",
    Goals: "الأهداف",
    ContactUs: "اتصل بنا",
  },
};

export default function NavMenu({ isOpen, toggleMenu }) {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-blue-900 transition-transform transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ transitionDuration: "300ms" }}
    >
      <div className="relative flex flex-col items-start px-6 py-12 pt-14 bg-[#020563] h-full w-full shadow-lg">
        <button
          onClick={toggleMenu}
          className="absolute top-4 right-4 text-white text-2xl"
        >
          <IoClose />
        </button>

        <div className="block bg-white w-full p-4 px-6 rounded-lg h-full my-2 mt-7">
          <NavBarItem
            text={getTranslation(translate, "Major")}
            to={"#hero"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
          <NavBarItem
            text={getTranslation(translate, "Properties")}
            to={"#criteria"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
          <NavBarItem
            text={getTranslation(translate, "AccessToThePlatform")}
            to={"#login"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
          <NavBarItem
            text={getTranslation(translate, "Blockchain")}
            to={"#blockchain"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
          <NavBarItem
            text={getTranslation(translate, "HowItWorks")}
            to={"#howto"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
          <NavBarItem
            text={getTranslation(translate, "Goals")}
            to={"#goals"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
          <NavBarItem
            text={getTranslation(translate, "ContactUs")}
            to={"#Contact-us"}
            Menustyle={true}
            toggleMenu={toggleMenu} // Pass toggleMenu
          />
        </div>
        <div>
          <LanguageButton openDirection={"bottom"} />
        </div>
      </div>
    </div>
  );
}

NavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};
