import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { menuIcon } from "../assets/svg/Index";
import admin from "../assets/admin.png";
import "/node_modules/flag-icons/css/flag-icons.min.css";

// eslint-disable-next-line no-unused-vars
import BalanceWrapper from "../components/balanceWrapper/BalanceWrapper";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";

const Header = ({ show, setShow }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [openPopover, setOpenPopover] = React.useState(false);
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };
  const setSelectedLanguage = (language) => {
    localStorage.setItem("language", language);
    window.location.reload();
  };
  const language = localStorage.getItem("language");
  return (
    <div className="flex w-full bg-[#285090]">
      <div className=" relative flex h-24 items-center  justify-between  rounded-md w-full px-[1%] sm:h-20">
        <div className="flex gap-2 items-center">
          <div
            className="cursor-pointer flex items-center pr-[1%] xl:hidden"
            onClick={() => setShow(!show)}
          >
            <span className="!text-white"> {menuIcon}</span>
          </div>
          {role === "ROLE_RESP_UNI" && (
            <div className="p-2 rounded-3xl">
              <BalanceWrapper />
            </div>
          )}
        </div>
        <div className="flex items-center gap-3">
          <div className="flex  items-center w-[auto] gap-3  p-2 bg-white rounded-3xl cursor-pointer">
            <img
              src={admin}
              className="w-10 h-10"
              onClick={() => {
                navigate("/settings");
              }}
            />
            <span
              className="hidden sm:hidden  font-semibold text-[#25396f] md:flex lg:flex"
              onClick={() => {
                navigate("/settings");
              }}
            >
              {user?.username}
            </span>

            {/* <div
              className="cursor-pointer rounded-3xl"
              onClick={() => setShowMenu(!showMenu)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 text-[#435ebe]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>
            </div> */}
            <Popover
              open={openPopover}
              handler={setOpenPopover}
              placement="bottom"
            >
              <PopoverHandler {...triggers}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-[#435ebe]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                  />
                </svg>
              </PopoverHandler>
              <PopoverContent
                {...triggers}
                className="z-50 max-w-[24rem] !p-2 "
              >
                <div className="flex flex-col w-full p-1">
                  <div className="flex items-center w-full justify-around  h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded">
                    <button
                      className="flex items-center justify-around w-24"
                      onClick={() => {
                        setSelectedLanguage("en");
                      }}
                    >
                      <span className="fi fi-gb"></span>
                      <span className="mx-1">English</span>
                    </button>
                    <span className="w-4">
                      {language === "en" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center w-full justify-around  h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded">
                    <button
                      className="flex items-center justify-around w-24"
                      onClick={() => {
                        setSelectedLanguage("fr");
                      }}
                    >
                      <span className="fi fi-fr"></span>
                      <span className="mx-1">Français</span>
                    </button>
                    <span className="w-4">
                      {language === "fr" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center w-full justify-around  h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded">
                    <button
                      className="flex items-center justify-around w-24"
                      onClick={() => {
                        setSelectedLanguage("ar");
                      }}
                    >
                      <span className="fi fi-arab"></span>
                      <span className="mx-1">العربية </span>
                    </button>
                    <span className="w-4">
                      {language === "ar" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        {/* <>{showMenu && <LanguageChooser />}</> */}
      </div>
    </div>
  );
};
Header.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
export default Header;
