import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { getTranslation } from "../../utils/translate/translation";
import { IoIosArrowDown } from "react-icons/io";
const translate = {
  ar: {
    DAY: "اليوم",
    MONTH: "الشهر",
    YEAR: "السنة",
    DATE_OF_BIRTH: "تاريخ الميلاد",
  },
  en: {
    DAY: "Day",
    MONTH: "Month",
    YEAR: "Year",
    DATE_OF_BIRTH: "Date of Birth",
  },
  fr: {
    DAY: "Jour",
    MONTH: "Mois",
    YEAR: "Année",
    DATE_OF_BIRTH: "Date de naissance",
  },
};

export default function DateInput({
  setDayOfBirth,
  setMonthOfBirth,
  setYearOfBirth,
}) {
  const [day, setLocalDay] = useState("");
  const [month, setLocalMonth] = useState("");
  const [year, setLocalYear] = useState("");

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [daysInMonth, setDaysInMonth] = useState([]);

  useEffect(() => {
    if (month && year) {
      const daysCount = new Date(parseInt(year), parseInt(month), 0).getDate();
      setDaysInMonth(Array.from({ length: daysCount }, (_, i) => i + 1));
    }
  }, [month, year]);

  const handleDayChange = (value) => {
    setLocalDay(value);
    setDayOfBirth(value);
  };

  const handleMonthChange = (value) => {
    setLocalMonth(value);
    setMonthOfBirth(value);
  };

  const handleYearChange = (value) => {
    setLocalYear(value);
    setYearOfBirth(value);
  };

  return (
    <div className="m-0">
      <p className="text-sm text-gray-500 my-2">
        {getTranslation(translate, "DATE_OF_BIRTH")}
      </p>
      <div className="grid grid-cols-3 gap-4">
        <div className="space-y-2">
          <Menu>
            <MenuHandler>
              <button className="flex items-center justify-between w-full border rounded-lg p-2">
                {day || getTranslation(translate, "DAY")}{" "}
                <IoIosArrowDown className="w-5 h-5 ml-2" />
              </button>
            </MenuHandler>
            <MenuList className="max-h-60 overflow-y-auto">
              {daysInMonth.map((d) => (
                <MenuItem key={d} onClick={() => handleDayChange(d.toString())}>
                  {d}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>

        <div className="space-y-2">
          <Menu>
            <MenuHandler>
              <button className="flex items-center justify-between w-full border rounded-lg p-2">
                {month
                  ? months[parseInt(month) - 1]
                  : getTranslation(translate, "MONTH")}{" "}
                <IoIosArrowDown className="w-5 h-5 ml-2" />
              </button>
            </MenuHandler>
            <MenuList className="max-h-60 overflow-y-auto">
              {months.map((m, index) => (
                <MenuItem
                  key={m}
                  onClick={() => handleMonthChange((index + 1).toString())}
                >
                  {m}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>

        <div className="space-y-2">
          <Menu>
            <MenuHandler>
              <button className="flex items-center justify-between w-full border rounded-lg p-2">
                {year || getTranslation(translate, "YEAR")}{" "}
                <IoIosArrowDown className="w-5 h-5 ml-2" />
              </button>
            </MenuHandler>
            <MenuList className="max-h-60 overflow-y-auto">
              {years.map((y) => (
                <MenuItem
                  key={y}
                  onClick={() => handleYearChange(y.toString())}
                >
                  {y}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      </div>
    </div>
  );
}

DateInput.propTypes = {
  setDayOfBirth: PropTypes.func.isRequired,
  setMonthOfBirth: PropTypes.func.isRequired,
  setYearOfBirth: PropTypes.func.isRequired,
};
