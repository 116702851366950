import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetStudentbyIdMutation } from "../../store/service/studentsService";
import StudentInfo from "../StudentFiche/StudentInfo";
import Diploma from "../StudentFiche/Diploma";
import { keyReturn } from "../../utils/translate/multilanguage-reader";
import { FaArrowRight } from "react-icons/fa";
import { getTranslation } from "../../utils/translate/translation";
import { IoMdClose } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import { Spinner } from "@material-tailwind/react";
export default function StudentInfoModal({ showModal, closeModal, studentId }) {
  const [student, setStudent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [getStudentById] = useGetStudentbyIdMutation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const diplomasPerSlide = 2; // Number of diplomas to show per slide

  const [selectedLanguage, setSelectedLanguage] = useState("");
  //fetch student data by id
  useEffect(() => {
    fetchStudent();
  }, [studentId]);
  const fetchStudent = async () => {
    setIsLoading(true);
    try {
      getStudentById(studentId).then((res) => {
        setStudent(res?.data?.data);
        setSelectedLanguage(keyReturn(res?.data?.data?.firstName));
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const nextSlide = () => {
    if (currentSlide < student.diplomas.length / diplomasPerSlide - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const translate = {
    ar: {
      STUDENT_INFO: "معلومات الطالب :",
      NO_DIPLOMAS: "لا توجد شهادات",
    },
    fr: {
      STUDENT_INFO: "Info de l'étudiant :",
      NO_DIPLOMAS: "Pas de diplômes",
    },
    en: {
      STUDENT_INFO: "Student Info :",
      NO_DIPLOMAS: "No diplomas found",
    },
  };
  return (
    <div>
      {showModal && studentId && (
        <div className="fixed inset-0 bg-gray-500 z-50">
          <div className="m-3 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white rounded-t-md overflow-y-auto max-h-[750px] sm:min-w-[500px] md:min-w-[650px] lg:!min-w-[860px]">
              <div className="flex justify-between items-center bg-blue-800  text-white rounded-t-md">
                <h2 className="text-2xl font-bold px-2.5">
                  {getTranslation(translate, "STUDENT_INFO")}{" "}
                  <span className="text-lg">{studentId && studentId}</span>
                </h2>
                <button
                  className="text-2xl  h-[52px] w-[52px] flex items-center rounded-tr-md justify-center transition hover:bg-red-400"
                  onClick={() => {
                    closeModal();
                    //empty current slide
                    setCurrentSlide(0);
                    //empty student data
                    setStudent({});
                  }}
                >
                  <IoMdClose />
                </button>
              </div>
              <div className="overflow-y-auto">
                {isLoading ? (
                  <div className="flex justify-center items-center h-[500px]">
                    <Spinner color="blue" size="3xl" />
                  </div>
                ) : (
                  <StudentInfo
                    student={student}
                    selectedLanguage={selectedLanguage}
                  />
                )}
              </div>
              {/* Diplomas Carousel */}
              {student?.diplomas?.length > 0 && (
                <div className="p-1">
                  <div className="relative flex items-center">
                    {/* Diplomas Slides */}
                    <div className="overflow-hidden w-full">
                      <div
                        className="flex transition-transform ease-in-out duration-500"
                        style={{
                          transform: `translateX(-${currentSlide * 100}%)`,
                        }}
                      >
                        {student.diplomas
                          ?.reduce((acc, curr, index) => {
                            if (index % diplomasPerSlide === 0) {
                              acc.push([curr]);
                            } else {
                              acc[acc.length - 1].push(curr);
                            }
                            return acc;
                          }, [])
                          //filter only show the status of diplomas aaccepted
                          .filter((diplomaPair) =>
                            diplomaPair.every(
                              (diploma) => diploma.status === "accepted"
                            )
                          )
                          .map((diplomaPair, index) => (
                            <div
                              key={index}
                              className="flex-none w-full flex justify-around items-center gap-4"
                            >
                              {diplomaPair.map((diploma) => (
                                <div key={diploma._id}>
                                  <Diploma diploma={diploma} />
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Navigation Buttons */}
                    {currentSlide === 0 ? null : (
                      <button
                        onClick={prevSlide}
                        className="absolute left-2 p-2 bg-[#435ebe] text-white rounded-full shadow hover:bg-white hover:border-gray-400 hover:!text-[#435ebe] transition cursor-pointer"
                        disabled={currentSlide === 0}
                      >
                        <FaArrowLeft />
                      </button>
                    )}
                    {student?.diplomas?.filter(
                      (diploma) => diploma.status === "accepted"
                    ).length > 2 && (
                      <button
                        onClick={nextSlide}
                        className="absolute font-black right-2 p-2 bg-[#435ebe] text-white rounded-full shadow hover:bg-white hover:border-gray-400 hover:!text-[#435ebe] transition cursor-pointer"
                        disabled={
                          currentSlide >=
                          Math.ceil(
                            student.diplomas.length / diplomasPerSlide
                          ) -
                            1
                        }
                      >
                        <FaArrowRight />
                      </button>
                    )}
                  </div>
                </div>
              )}
              {console.log("student loay", student?.diplomas)}
              {student?.diplomas?.filter(
                (diploma) => diploma.status === "accepted"
              ).length === 0 && (
                <div className="flex justify-center items-center h-[400px]">
                  <p className="text-lg text-gray-400">
                    {getTranslation(translate, "NO_DIPLOMAS")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Correctly define the prop types for the component
StudentInfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  studentId: PropTypes.string.isRequired,
};

// Default values if needed (optional)
StudentInfoModal.defaultProps = {
  showModal: false,
  studentId: "",
};
