import React from "react";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Chart, ArcElement } from "chart.js";
import { getTranslation } from "../../utils/translate/translation";

Chart.register(ArcElement);
const translate = {
  ar: {
    DIPLOMAS_BY_GENDER: "الشهادات حسب الجنس",
  },
  en: {
    DIPLOMAS_BY_GENDER: "Diplomas by gender",
  },
  fr: {
    DIPLOMAS_BY_GENDER: "Diplomes par sexe",
  },
};
const DoughnutChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      showLabel: true,
      legend: {
        position: "bottom",
        padding: "10",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
      labels: {
        render: "percentage",
        precision: 2,
      },
    },
  };
  return (
    <div>
      <div className="font-bold text-white bg-[#285090] text-1xl p-2 rounded-t-lg">
        {getTranslation(translate, "DIPLOMAS_BY_GENDER")}
      </div>
      <div className="flex justify-center my-2 max-h-[200px] xl:max-h-[240px]">
        <Doughnut data={chartData} options={options} />
      </div>
    </div>
  );
};
DoughnutChart.propTypes = {
  chartData: PropTypes.object,
};
export default DoughnutChart;
