import PropTypes from "prop-types";
import React, { useState } from "react";
import { read, utils, set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);

import { useDispatch, useSelector } from "react-redux";
import { ADD_FROM_CSV } from "../../store/features/studentsSlice/studentsSlice";
import FileInput from "../../components/input/FileInput";
import { toastErrorServer } from "../../components/toast/Toast";

import { convertDateFromXlsFileToString } from "../../utils/SheetUtils/SheetReader";
import { downloadFileStudent } from "../../utils/student_file_header";
import { getTranslation } from "../../utils/translate/translation";
import {
  isDateNotValid,
  isNinValid,
  isXlsFieldNotValid,
} from "../../utils/Validators/Validators";
import Spinner from "../../components/spinner/Spinner";

const translate = {
  ar: {
    TOAST_ERROR: "الرجاء التثبت في المعطيات !",
    MESSAGE_: "  قم برفع ملف اكسل Excel مثل هذا الأنموذج ",
    CLICK: "اضغط هنا",
    FIRSTNAME: "الاسم الأول",
    LASTNAME: "اسم العائلة",
    NIN: "المعرّف",
    DATE_OF_BRITH: "تاريخ الولادة",
    MESSAGE_ONE:
      "يتضمن بيانات الطلبة. مع العلم أن الأعمدة الثلاث الأولى (First name /Last Name / Nin) هي فقط الضرورية أما باقي الأعمدة فهي اختيارية لإكمال عملية تسجيل الطلبة.",
  },
  en: {
    TOAST_ERROR: "Please verify the data!",
    MESSAGE_: " Upload an Excel file like this Excel template' ",
    CLICK: "Click here ",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    NIN: "Identifier",
    DATE_OF_BRITH: "Date of brith",
    MESSAGE_ONE:
      "Includes student data. Note that only the first three columns (First name / Last Name / Nin) are necessary, while the rest of the columns are optional to complete the student registration process.",
  },
  fr: {
    TOAST_ERROR: "Veuillez vérifier les données !",
    MESSAGE_: " Téléchargez un fichier Excel comme ce modèle Excel' ",
    CLICK: " Cliquez ici",
    FIRSTNAME: "Prénom",
    LASTNAME: "Nom",
    NIN: "Identifiant ",
    DATE_OF_BRITH: "Date de naissance",
    MESSAGE_ONE:
      "Inclut les données des étudiants. Notez que seules les trois premières colonnes (Prénom / Nom / Nin) sont nécessaires, tandis que le reste des colonnes est facultatif pour compléter le processus d'inscription des étudiants.",
  },
};
function AddStudentsUploader({ setError, setOpen }) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const studentsFromRedux = useSelector(
    (state) => state.students.studentsToAdd
  );

  function onClear() {
    let emptyStudents = [];
    dispatch(ADD_FROM_CSV({ studentsToAdd: emptyStudents }));
  }

  function isHeaderValid(header) {
    return (
      header.includes("firstName") &&
      header.includes("lastName") &&
      header.includes("nin") &&
      header.includes("dateOfBirth")
    );
  }

  function deleteDuplicatedNin(data) {
    return data.reduce((acc, current) => {
      const x = acc.find(
        (item) => item.nin === current.nin && current.language === item.language
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  function dispatchStudents(students) {
    let studentsWithNoDuplicatedNins = deleteDuplicatedNin(students);
    dispatch(
      ADD_FROM_CSV({
        studentsToAdd: studentsWithNoDuplicatedNins,
      })
    );
  }

  function showErrorsModal(errorObj) {
    setError(errorObj);
    setOpen(true);
  }

  function extractErrors(results, index) {
    let colonnes = [];
    let ligne = index + 1;
    if (isXlsFieldNotValid(results[index]["firstName"])) {
      colonnes.push(`${getTranslation(translate, "FIRSTNAME")}`);
      colonnes.push(" , ");
    }
    if (isXlsFieldNotValid(results[index]["lastName"])) {
      colonnes.push(`${getTranslation(translate, "LASTNAME")}`);
      colonnes.push(" , ");
    }
    if (isNinValid(results[index]["nin"])) {
      colonnes.push(`${getTranslation(translate, "NIN")}`);
      colonnes.push(" , ");
    }
    if (isDateNotValid(results[index]["dateOfBirth"])) {
      colonnes.push(`${getTranslation(translate, "DATE_OF_BRITH")}`);
      colonnes.push(" , ");
    }

    return { ligne, colonnes };
  }

  function handleFileChange(file) {
    setIsLoading(true);

    const reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, {
        type: "array",
        cellDates: true,
        cellNF: false,
        cellText: false,
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const results = utils.sheet_to_json(worksheet, { raw: true });
      let students = [];
      const header = utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];

      if (isHeaderValid(header)) {
        let errorObj = [];

        for (let index = 0; index < results.length; index++) {
          let isThereAnyError =
            isXlsFieldNotValid(results[index]["firstName"]) ||
            isXlsFieldNotValid(results[index]["lastName"]) ||
            isNinValid(results[index]["nin"]) ||
            isDateNotValid(results[index]["dateOfBirth"]);
          if (isThereAnyError) {
            const { ligne, colonnes } = extractErrors(results, index);
            errorObj.push({
              ligne,
              colonnes,
            });
          } else {
            const studentUploader = {
              firstName: results[index]["firstName"],
              lastName: results[index]["lastName"],
              nin: results[index]["nin"].toString(),
              dateOfBirth: convertDateFromXlsFileToString(
                results[index]["dateOfBirth"]
              ),
              countryOfBirth: results[index]["countryOfBirth"],
              sex: results[index]["sex"],
              nationalty: results[index]["nationalty"],
              phone: results[index]["phone"]?.toString(),
              email: results[index]["email"],
              language: results[index]["language"] ?? "en",
            };
            students.push(studentUploader);
          }
        }
        if (errorObj.length > 0) {
          showErrorsModal(errorObj);
        }
        dispatchStudents(students);
      } else {
        toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
      }
      setIsLoading(false);
    };
    reader.readAsArrayBuffer(file);
  }

  return (
    <div className="flex flex-col items-center justify-end w-full p-4 rounded bg-[#f2f7ff]">
      {isLoading && (
        <div className="loader">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <p className="w-full ">
            {getTranslation(translate, "MESSAGE_")}
            <span
              className="font-bold text-red-700 cursor-pointer "
              onClick={downloadFileStudent}
            >
              {getTranslation(translate, "CLICK")}
            </span>
            {getTranslation(translate, "MESSAGE_ONE")}
          </p>
          <div className="w-full">
            <FileInput
              onChange={handleFileChange}
              OnClear={onClear}
              isUploaded={studentsFromRedux?.length > 0}
            />
          </div>
        </>
      )}
    </div>
  );
}
AddStudentsUploader.propTypes = {
  setOpen: PropTypes.func,
  setError: PropTypes.func,
};
export default AddStudentsUploader;
