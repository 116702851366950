import React from "react";
import { TbWorld } from "react-icons/tb";
import SectionTitle from "../section-title/SectionTitle";
import AreaItem from "./AreaItem";
import { BsMortarboard } from "react-icons/bs";
import { BsBank2 } from "react-icons/bs";
import { BsBank } from "react-icons/bs";
import { getTranslation } from "../../../utils/translate/translation";
const translate = {
  en: {
    sectionTitle: "Login to the platform ",
    sectionDescription: "Please select a user type",
    area1: "Institutions area  ",
    area1Item1: "A space for all institutions and companies ",
    area1Item2: "to check credibility ",
    area1Item3: "Certificate presented by the student ",
    area2: "Student area ",
    area2Item1: "See the digital portfolio of its certificates",
    area2Item2: " Extract QR-CODE",
    area2Item3: "Send a digital copy of the certificate",
    area3: "University area ",
    area3Item1: "Add graduate student certificates",
    area3Item2: "Search in the archive of issued certificates ",
    area3Item3: "Management of student data and certificates",
    area4: "Ministry space ",
    area4Item1: "Add universities to the platform ",
    area4Item2: "University account management",
    area4Item3: "Consult the statistics of the certificates issued",
    loginBtn: "Login",
  },
  ar: {
    sectionTitle: "تسجيل الدخول إلى المنصة ",
    sectionDescription: "يرجى تحديد نوع المستخدم",
    area1: "فضاء المؤسّسات",
    area1Item1: "فضاء لكل المؤسسات والشركات",
    area1Item2: "للتحقق من المصداقية ",
    area1Item3: "الشهادة التي قدمها الطالب ",
    area2: "فضاء  الطالب ",
    area2Item1: "الاطلاع على المحفظة الرقمية لشهاداته",
    area2Item2: " استخراج QR-CODE",
    area2Item3: "إرسال نسخة رقمية من الشهادة",
    area3: "فضاء  الجامعة ",
    area3Item1: "إضافة شهادات الطلبة المتخرّجين",
    area3Item2: "البحث في أرشيف الشهادات المسلّمة",
    area3Item3: "إدارة بيانات الطلبة والشهادات",
    area4: "فضاء الوزارة ",
    area4Item1: "إضافة الجامعات على المنصّة",
    area4Item2: "إدارة حسابات الجامعات",
    area4Item3: "الاطلاع على احصائيات الشهادات المسلّمة",
    loginBtn: "تسجيل الدخول",
  },
  fr: {
    sectionTitle: "Connexion à la plateforme ",
    sectionDescription: "Veuillez sélectionner un type d'utilisateur",
    area1: "Espace des institutions ",
    area1Item1: "Un espace pour toutes les institutions et entreprises ",
    area1Item2: "pour vérifier la crédibilité ",
    area1Item3: "Certificat présenté par l'étudiant ",
    area2: "Espace étudiant ",
    area2Item1: "Voir le portfolio numérique de ses certificats",
    area2Item2: " Extraire le QR-CODE",
    area2Item3: "Envoyer une copie numérique du certificat",
    area3: "Espace universitaire ",
    area3Item1: "Ajouter des certificats d'étudiants diplômés",
    area3Item2: "Rechercher dans l'archive des certificats délivrés ",
    area3Item3: "Gestion des données et des certificats des étudiants",
    area4: "Espace ministériel ",
    area4Item1: "Ajouter des universités à la plateforme ",
    area4Item2: "Gestion du compte universitaire",
    area4Item3: "Consultation des statistiques des certificats délivrés",
    loginBtn: "Connexion",
  },
};
export default function ThirdSection() {
  return (
    <div id="login" className="bg-white pt-12">
      <SectionTitle
        title={getTranslation(translate, "sectionTitle")}
        p={getTranslation(translate, "sectionDescription")}
      />
      <div
        data-aos-once="true"
        data-aos-mirror="false"
        className="container grid gap-3 p-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
      >
        <AreaItem
          showBanner={true}
          bannerText={"The officials"}
          AriaTitle={getTranslation(translate, "area1")}
          AriaIcon={<TbWorld />}
          listItem={[
            getTranslation(translate, "area1Item1"),
            getTranslation(translate, "area1Item2"),
            getTranslation(translate, "area1Item3"),
          ]}
          btnColor={"#1acc8d"}
          isLink={true}
          LinkTo={"/third_party"}
          aosAnimation="fade-left"
          aosAnimationDelay="200"
        />
        <AreaItem
          AriaTitle={getTranslation(translate, "area2")}
          AriaIcon={<BsMortarboard />}
          listItem={[
            getTranslation(translate, "area2Item1"),
            getTranslation(translate, "area2Item2"),
            getTranslation(translate, "area2Item3"),
          ]}
          AriaTitletype={"bg-[#1acc8d] text-white font-bold"}
          btnColor={"#1acc8d"}
          isLink={true}
          LinkTo={"/Login_Student"}
          aosAnimation="fade-left"
          aosAnimationDelay="300"
        />
        <AreaItem
          AriaTitle={getTranslation(translate, "area3")}
          AriaIcon={<BsBank2 />}
          listItem={[
            getTranslation(translate, "area3Item1"),
            getTranslation(translate, "area3Item2"),
            getTranslation(translate, "area3Item3"),
          ]}
          btnColor={"#1acc8d"}
          isLink={true}
          LinkTo={"/login"}
          aosAnimation="fade-left"
          aosAnimationDelay="400"
        />
        <AreaItem
          AriaTitle={getTranslation(translate, "area4")}
          AriaIcon={<BsBank />}
          listItem={[
            getTranslation(translate, "area4Item1"),
            getTranslation(translate, "area4Item2"),
            getTranslation(translate, "area4Item3"),
          ]}
          btnColor={"#1acc8d"}
          isLink={true}
          LinkTo={"/backend/ministry_login/"}
          aosAnimation="fade-left"
          aosAnimationDelay="500"
        />
      </div>
    </div>
  );
}
