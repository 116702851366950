import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import { getTranslation } from "../../../utils/translate/translation";
import { FaEthereum } from "react-icons/fa";
import { useSendEtherDepositeDemandeMutation } from "../../../store/service/usersService";
import { toastSucces, toastErrorServer } from "../../toast/Toast";
import Spinner from "../../spinner/Spinner";
export default function DepositEtherModal({ onClose, etherBalance }) {
  const translation = {
    en: {
      MODAL_TITLE: "deposit ETH Request",
      CURRENT_ETH_BALANCE: "Current ETH Balance",
      SEND_DEPOSIT_REQUEST: "Send Deposit Request",
    },
    fr: {
      MODAL_TITLE: "Envoyer une demande de dépôt ETH",
      CURRENT_ETH_BALANCE: "Solde ETH actuel",
      SEND_DEPOSIT_REQUEST: "Envoyer la demande de dépôt",
    },
    ar: {
      MODAL_TITLE: "إرسال طلب إيداع ETH",
      CURRENT_ETH_BALANCE: "الرصيد الحالي للإيثريوم",
      SEND_DEPOSIT_REQUEST: "إرسال طلب الإيداع",
    },
  };
  const [sendEtherDepositeDemande] = useSendEtherDepositeDemandeMutation();
  const [onProcessDepositRequest, setOnProcessDepositRequest] = useState(false);
  const sendEthDepositRequest = async () => {
    setOnProcessDepositRequest(true);
    sendEtherDepositeDemande().then((res) => {
      if (res.error) {
        setOnProcessDepositRequest(false);
        toastErrorServer(res.error.data.message);
        return;
      }
      if (res.data) {
        setOnProcessDepositRequest(false);
        toastSucces("Deposit Request Sent Successfully");
      }
    });
  };
  return (
    <div>
      <div className="flex items-center  justify-between bg-[#285090] text-white">
        <h2 className="text-xl p-1.5 px-2 font-semibold">
          {getTranslation(translation, "MODAL_TITLE")}
        </h2>
        <span
          onClick={onClose}
          className="cursor-pointer text-xl p-2.5 transition ease-in-out hover:bg-red-600 hover:text-white"
        >
          <IoMdClose />
        </span>
      </div>
      <div className="p-2 px-3">
        <p className="w-full text-center text-gray-600 mb-4 mt-2">
          {getTranslation(translation, "MODAL_P")}
        </p>
        <div>
          <div className="flex items-center gap-2 my-2 mb-4">
            <p className="font-black">
              {getTranslation(translation, "CURRENT_ETH_BALANCE")}
            </p>{" "}
            <div className="bg-gray-200 rounded-md flex p-1 gap-2 border-2 border-gray-400">
              <p className="text-xl font-black">{etherBalance}</p>
              <FaEthereum className="text-[#285090] text-2xl" />
            </div>
          </div>

          <div className="flex items-center justify-center w-full py-2">
            <button
              className="bg-[#285090] text-white p-2 rounded-md w-full"
              disabled={onProcessDepositRequest}
              onClick={sendEthDepositRequest}
            >
              {onProcessDepositRequest ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                  <p>Processing...</p>
                </div>
              ) : (
                getTranslation(translation, "SEND_DEPOSIT_REQUEST")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
DepositEtherModal.propTypes = {
  onClose: PropTypes.func,
  etherBalance: PropTypes.number,
};
