import React from "react";
import PropTypes from "prop-types";

export default function TextArea({ type, placeholder, className, name }) {
  return (
    <div>
      <textarea
        type={type}
        placeholder={placeholder}
        className={className}
        name={name}
      />
    </div>
  );
}

TextArea.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
