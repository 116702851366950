import React from "react";
import PropTypes from "prop-types";
export default function FeautureItem({
  featureTitle,
  featureDescription,
  featureIcon,
}) {
  return (
    <div
      data-aos="zoom-in"
      data-aos-delay="100"
      data-aos-duration="1200"
      data-aos-once="true"
      data-aos-mirror="false"
    >
      <div className="flex gap-3 items-center mt-4 group justify-between">
        <div>
          <h2 className="font-black my-2 text-lg transition-all ease-in-out duration-300 hover:text-[#1acc8d]">
            {featureTitle}
          </h2>
          <p className="text-base">{featureDescription}</p>
        </div>
        <div className="float-right flex items-center justify-center w-[64px] h-[64px] rounded-full border-2 border-[#1acc8d]  transition-all ease-in-out duration-300  group-hover:bg-[#1acc8d]">
          <div className="p-4">{featureIcon}</div>
        </div>
      </div>
    </div>
  );
}
FeautureItem.propTypes = {
  featureTitle: PropTypes.string.isRequired,
  featureDescription: PropTypes.string.isRequired,
  featureIcon: PropTypes.string.isRequired,
};
