import React from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../utils/translate/translation";
import Spinner from "../../components/spinner/Spinner";
import defaultAvatar from "../../assets/default-avatar.jpg";
const StudentInfo = ({ student, selectedLanguage }) => {
  const translate = {
    ar: {
      NATIONAL_ID: "المعرف الوطني :",
      DATE_OF_BIRTH: "تاريخ الميلاد :",
      PLACE_OF_BIRTH: "مكان الولادة :",
      EMAIL: "البريد الإلكتروني:",
      PHONE: "الهاتف :",
    },
    fr: {
      NATIONAL_ID: "Identifiant national :",
      DATE_OF_BIRTH: "Date de naissance :",
      PLACE_OF_BIRTH: "Lieu de naissance :",
      EMAIL: "Email :",
      PHONE: "Téléphone :",
    },
    en: {
      NATIONAL_ID: "National ID:",
      DATE_OF_BIRTH: "Date of Birth:",
      PLACE_OF_BIRTH: "Place of Birth:",
      EMAIL: "Email:",
      PHONE: "Phone:",
    },
  };
  return (
    <>
      {student && selectedLanguage ? (
        <div className="block lg:grid md:grid-cols-[19%_81%] xl:md:grid-cols-[20%_80%]">
          <div className="flex items-center justify-center">
            <img
              src={student?.photo || defaultAvatar}
              alt="Student"
              className="border-4 border-gray-300 border-solid rounded-md max-w-[200px] max-h-[150px] object-cover xl:max-w-[220px] xl:max-h-[180px]"
            />
          </div>
          <div className="p-2 bg-white">
            <p className="text-lg my-1.5">
              {" "}
              {student?.firstName?.[selectedLanguage].toUpperCase()}
              {"  "}
              {student?.lastName?.[selectedLanguage].toUpperCase()}
            </p>
            <p className="font-[400] text-base text-[#607080] mb-2">
              {" "}
              {student.description}
            </p>
            <div className="grid grid-cols-2 border-t-2 gap-2 border-gray-300 py-1.5">
              <div className="text-lg">
                <span className=" text-[#607080] font-bold">
                  {" "}
                  {getTranslation(translate, "NATIONAL_ID")}{" "}
                </span>
                <span className="  text-[#607080]"> {student.nin}</span>
              </div>
              <div className="text-lg">
                <span className=" text-[#607080] font-bold">
                  {" "}
                  {getTranslation(translate, "DATE_OF_BIRTH")}
                </span>
                <span className="  text-[#607080]"> {student.dateOfBirth}</span>
              </div>
              <div className="text-lg">
                <span className=" text-[#607080] font-bold">
                  {" "}
                  {getTranslation(translate, "PLACE_OF_BIRTH")}{" "}
                </span>
                <span className="  text-[#607080]">
                  {student.countryOfBirth?.[selectedLanguage]}
                </span>
              </div>
              <div className="text-lg">
                <span className=" text-[#607080] font-bold">
                  {getTranslation(translate, "EMAIL")}
                </span>
                <span className="  text-[#607080]"> {student.email}</span>
              </div>
              <div className="text-lg">
                <span className=" text-[#607080] font-bold">
                  {" "}
                  {getTranslation(translate, "PHONE")}{" "}
                </span>
                <span className="  text-[#607080]"> {student.phone}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[500px]">
          <Spinner color="blue" size="3xl" />
        </div>
      )}
    </>
  );
};
StudentInfo.propTypes = {
  student: PropTypes.object,
  selectedLanguage: PropTypes.string,
};
export default StudentInfo;
