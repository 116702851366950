import React from "react";
import { Select, Option } from "@material-tailwind/react";
import PropTypes from "prop-types";

import { mapIcon } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
function SelectCountry({ value, setCountry }) {
  const countryCodes = {
    PALESTINE: "ps",
    UNITED_ARAB_EMIRATES: "ae",
    BAHRAIN: "bh",
    KUWAIT: "kw",
    SAUDI_ARABIA: "sa",
    YEMEN: "ye",
    QATAR: "qa",
    OMAN: "om",
    JORDAN: "jo",
    SYRIA: "sy",
    LEBANON: "lb",
    IRAQ: "iq",
    EGYPT: "eg",
    MOROCCO: "ma",
    LIBYA: "ly",
    TUNISIA: "tn",
    ALGERIA: "dz",
    SUDAN: "sd",
    MAURITANIA: "mr",
    DJIBOUTI: "dj",
    SOMALIA: "so",
  };
  const translate = {
    ar: {
      PALESTINE: "دولة فلسطين",
      UNITED_ARAB_EMIRATES: "الإمارات العربية المتحدة",
      BAHRAIN: "البحرين",
      KUWAIT: "الكويت",
      SAUDI_ARABIA: "السعودية",
      YEMEN: "اليمن",
      QATAR: "قطر",
      OMAN: "سلطنة عمان",
      JORDAN: "الأردن",
      SYRIA: "سوريا",
      LEBANON: "لبنان",
      IRAQ: "العراق",
      EGYPT: "مصر",
      MOROCCO: "المغرب",
      LIBYA: "ليبيا",
      TUNISIA: "تونس",
      ALGERIA: "الجزائر",
      SUDAN: "السودان",
      MAURITANIA: "موريتانيا",
      DJIBOUTI: "جيبوتي",
      SOMALIA: "الصومال",
    },
    en: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "United Arab Emirates",
      BAHRAIN: "Bahrain",
      KUWAIT: "Kuwait",
      SAUDI_ARABIA: "Saudi Arabia",
      YEMEN: "Yemen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordan",
      SYRIA: "Syria",
      LEBANON: "Lebanon",
      IRAQ: "Iraq",
      EGYPT: "Egypt",
      MOROCCO: "Morocco",
      LIBYA: "Libya",
      TUNISIA: "Tunisia",
      ALGERIA: "Algeria",
      SUDAN: "Sudan",
      MAURITANIA: "Mauritania",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalia",
    },
    fr: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "Émirats arabes unis",
      BAHRAIN: "Bahreïn",
      KUWAIT: "Koweït",
      SAUDI_ARABIA: "Arabie saoudite",
      YEMEN: "Yémen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordanie",
      SYRIA: "Syrie",
      LEBANON: "Liban",
      IRAQ: "Irak",
      EGYPT: "Égypte",
      MOROCCO: "Maroc",
      LIBYA: "Libye",
      TUNISIA: "Tunisie",
      ALGERIA: "Algérie",
      SUDAN: "Soudan",
      MAURITANIA: "Mauritanie",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalie",
    },
  };

  const handleOnChange = (e) => {
    setCountry(e);
  };

  const language = localStorage.getItem("language");
  const countries = Object.keys(translate[language]);

  return (
    <form className="w-full">
      <div className="relative mb-6">
        <Select
          defaultValue={""}
          variant="static"
          value={value}
          onChange={handleOnChange}
          className={`dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden outline-blue-300/70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md w-full ${
            language === "ar" ? "pr-10 text-right " : "pl-10 text-left "
          } p-2.5 flex items-start justify-start`}
        >
          {countries.map((country) => {
            const countryCode = countryCodes[country];
            return (
              <Option
                key={country}
                value={country}
                className="w-full flex items-center"
              >
                <span
                  className={`fi fi-${countryCode} ${
                    language === "ar" ? "mr-12" : "ml-12"
                  }`}
                ></span>
                <span className={`${language === "ar" ? "mr-4" : "ml-2"}`}>
                  {getTranslation(translate, country)}
                </span>
              </Option>
            );
          })}
        </Select>
        <div
          className={`absolute inset-y-0 ${
            language === "ar" ? "right-0 pr-3" : "left-0 pl-3"
          } flex items-center mt-2 pointer-events-none ${!value && "mb-2"}`}
        >
          {mapIcon}
        </div>
      </div>
    </form>
  );
}

SelectCountry.propTypes = {
  value: PropTypes.string,
  setCountry: PropTypes.func,
};

export default SelectCountry;
