/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dashboardIcon,
  diplomaIcon,
  studentsIcons,
  universityIcon,
  usersIcon,
} from "../../assets/svg/Index";
import logo from "../../assets/logo.png";
import NavigationBarButton from "./NavigationBarButton";
import NavigationBarAccordion from "./NavigationBarAccordion";
import { IoAddCircle } from "react-icons/io5";
import PropTypes from "prop-types";
import { MdLogout } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { PiUsersThreeFill } from "react-icons/pi";
import { TiUserAdd } from "react-icons/ti";
import { FaUniversity } from "react-icons/fa";
import { FaRegRectangleList } from "react-icons/fa6";
const translate = {
  ar: {
    STUDENTS: "قائمة الطلبة",
    ADD_STUDENT: "إضافة طلبة",
    STUDENTS_DEMANDES: "قائمة الطلبات",
    DIPLOMAS: "قائمة الشهادات ",
    DIPLOMAS_NOT_CONFIRM: "شهادات بانتظار التأكيد ",
    DIPLOMAS_REJECTED: "قائمة الشهادات المرفوضة ",
    ADD_DIPLOMAS: "إضافة شهادة ",
    USERS: "قائمة المستخدمين ",
    UNIVERSITIES_LIST: "قائمة الجامعات",
    ADD_USER: "إضافة مستخدم ",
    ADD_UNIVERSITY: "إضافة جامعة  ",
    LIST: "     قائمة الخدمات",
    DASHBOARD: "لوحة التحكّم",
    STUDENT_MANAGEMENT: "إدارة الطلبة",
    DIPLOMAS_MANAGEMENT: "إدارة الشهادات",
    UNIVERSITY_MANAGEMENT: "إدارة الجامعات",
    USERS_MANAGEMENT: "إدارة المستخدمين ",
    SETTING: "الإعدادات",
    LOGOUT: "خروج",
  },
  en: {
    STUDENTS: " List of students",
    ADD_STUDENT: "Add students",
    STUDENTS_DEMANDES: "List of demands",
    DIPLOMAS: " List of diplomas ",
    DIPLOMAS_NOT_CONFIRM: "Diplomas awaiting confirmation",
    DIPLOMAS_REJECTED: "List of rejected Diplomas",
    ADD_DIPLOMAS: " Add diploma ",
    USERS: " List of users ",
    UNIVERSITIES_LIST: " List of universities",
    ADD_USER: " Add user ",
    ADD_UNIVERSITY: " Add university ",
    LIST: "     List of services    ",
    DASHBOARD: " Dashboard",
    STUDENT_MANAGEMENT: " Student management    ",
    DIPLOMAS_MANAGEMENT: " Diplomas Management",
    USERS_MANAGEMENT: " User management ",
    UNIVERSITY_MANAGEMENT: " University management ",
    SETTING: "Settings",
    LOGOUT: "Logout",
  },
  fr: {
    STUDENTS: " Liste des étudiants",
    ADD_STUDENT: "Ajouter des étudiants",
    STUDENTS_DEMANDES: " Liste des demandes",
    DIPLOMAS: " Liste des diplômes ",
    DIPLOMAS_NOT_CONFIRM: "  Diplômes en attente de confirmation ",
    DIPLOMAS_REJECTED: "   Liste des diplomes rejetés ",
    ADD_DIPLOMAS: " Ajouter un diplome ",
    USERS: " Liste des utilisateurs    ",
    UNIVERSITIES_LIST: " Liste des universités",
    ADD_USER: " Ajouter un utilisateur    ",
    ADD_UNIVERSITY: " Ajouter une université    ",
    LIST: "      Liste des prestations",
    DASHBOARD: " Tableau de bord",
    STUDENT_MANAGEMENT: "Gestion des étudiants",
    DIPLOMAS_MANAGEMENT: "Gestion des diplomes  ",
    USERS_MANAGEMENT: " Gestion des utilisateurs ",
    UNIVERSITY_MANAGEMENT: "Gestion des universités ",
    SETTING: "Paramètres",
    LOGOUT: "Se déconnecter",
  },
};
import { PiStudentFill } from "react-icons/pi";
import { CiNoWaitingSign } from "react-icons/ci";
import { GiDiploma } from "react-icons/gi";
import { getTranslation } from "../../utils/translate/translation";
import { MdPendingActions } from "react-icons/md";
function NavigationBarBody({ show, setShow, activeBadge, setActiveBadge }) {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [selectedAccordion, setSelectedAccordion] = useState("");
  const [showCompletesidebar, setShowCompletesidebar] = useState(true);
  const studentSubMenuItemsResponsable = [
    {
      title: ` ${getTranslation(translate, "STUDENTS")}`,
      path: "/student",
      icon: (
        <>
          <PiStudentFill />
        </>
      ),
      activePath: "/student",
      selectPage: () => {
        changeSelectedPage("/student");
      },
    },
  ];
  const studentSubMenuItemsAgent = [
    {
      title: ` ${getTranslation(translate, "STUDENTS")}`,
      path: "/student",
      icon: (
        <>
          <PiStudentFill />
        </>
      ),
      activePath: "/student",
      selectPage: () => {
        changeSelectedPage("/student");
      },
    },
    {
      title: ` ${getTranslation(translate, "STUDENTS_DEMANDES")}`,
      path: "/student-demandes",
      icon: (
        <>
          <FaRegRectangleList />
        </>
      ),
      activePath: "/student-demandes",
      selectPage: () => {
        changeSelectedPage("/student-demandes");
      },
    },
    {
      title: `${getTranslation(translate, "ADD_STUDENT")}`,
      path: "/add_student",
      icon: (
        <>
          <IoAddCircle />
        </>
      ),
      activePath: "/add_student",
      selectPage: () => {
        changeSelectedPage("/add_student");
      },
    },
  ];
  const diplomaSubMenuItemsResponsable = [
    {
      title: ` ${getTranslation(translate, "DIPLOMAS")}`,
      path: "/diplomasList",
      icon: (
        <>
          <GiDiploma />
        </>
      ),
      activePath: "/diplomasList",
      selectPage: () => {
        changeSelectedPage("/diplomasList");
      },
    },
    {
      title: ` ${getTranslation(translate, "DIPLOMAS_NOT_CONFIRM")}`,
      path: "/listsAgent",
      icon: (
        <>
          <MdPendingActions />
        </>
      ),
      activePath: "/listsAgent",
      selectPage: () => {
        changeSelectedPage("/listsAgent");
      },
      isBadgeActive: true,
    },
    {
      title: ` ${getTranslation(translate, "DIPLOMAS_REJECTED")}`,
      path: "/diplomasRejected",
      icon: (
        <>
          <CiNoWaitingSign />
        </>
      ),
      activePath: "/diplomasRejected",
      selectPage: () => {
        changeSelectedPage("/diplomasRejected");
      },
    },
  ];
  const diplomaSubMenuItemsAgent = [
    {
      title: `${getTranslation(translate, "DIPLOMAS")}`,
      path: "/diplomasList",
      icon: (
        <>
          <GiDiploma />
        </>
      ),
      activePath: "/diplomasList",
      selectPage: () => {
        changeSelectedPage("/diplomasList");
      },
    },
    {
      title: `${getTranslation(translate, "ADD_DIPLOMAS")}`,
      path: "/add_diplomas",
      icon: (
        <>
          <GiDiploma />
        </>
      ),
      activePath: "/add_diplomas",
      selectPage: () => {
        changeSelectedPage("/add_diplomas");
      },
    },
    {
      title: ` ${getTranslation(translate, "STUDENTS_DEMANDES")}`,
      path: "/diploma-demandes",
      icon: (
        <>
          <FaRegRectangleList />
        </>
      ),
      activePath: "/diploma-demandes",
      selectPage: () => {
        changeSelectedPage("/diploma-demandes");
      },
    },
    {
      title: `${getTranslation(translate, "DIPLOMAS_NOT_CONFIRM")}`,
      path: "/diplomas_not_confirm",
      icon: (
        <>
          {" "}
          <MdPendingActions />
        </>
      ),
      activePath: "/diplomas_not_confirm",
      selectPage: () => {
        changeSelectedPage("/diplomas_not_confirm");
      },
    },
    {
      title: `${getTranslation(translate, "DIPLOMAS_REJECTED")}`,
      path: "/diplomasRejected",
      icon: (
        <>
          {" "}
          <CiNoWaitingSign />
        </>
      ),
      activePath: "/diplomasRejected",
      selectPage: () => {
        changeSelectedPage("/diplomasRejected");
      },
      isBadgeActive: true,
    },
  ];
  const usersSubMenuItemsResponsable = [
    {
      title: ` ${getTranslation(translate, "USERS")}`,
      path: "/usersList",
      icon: (
        <>
          <PiUsersThreeFill />
        </>
      ),
      activePath: "/usersList",
      selectPage: () => {
        changeSelectedPage("/usersList");
      },
    },
  ];
  const universitySubMenuItemsResponsable = [
    {
      title: `${getTranslation(translate, "ADD_UNIVERSITY")}`,
      path: "/add_university",
      icon: (
        <>
          <IoAddCircle />
        </>
      ),
      activePath: "/add_university",
      selectPage: () => {
        changeSelectedPage("/add_university");
      },
    },
    {
      title: `${getTranslation(translate, "UNIVERSITIES_LIST")}`,
      path: "/universities_list",
      icon: (
        <>
          <FaUniversity />
        </>
      ),
      activePath: "/universities_list",
      selectPage: () => {
        changeSelectedPage("/universities_list");
      },
    },
  ];
  const changeSelectedPage = (url) => {
    navigate(url);
  };
  const onButtonClick = (url) => {
    changeSelectedPage(url);
    setSelectedAccordion("");
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
  };

  const language = localStorage.getItem("language");

  return (
    <div
      className={`h-full bg-white w-[${
        showCompletesidebar ? "320px" : "90px"
      }]`}
    >
      {show && (
        <div
          className="flex justify-end pb-4 cursor-pointer "
          onClick={() => setShow(!show)}
        >
          <span className="p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </div>
      )}
      {showCompletesidebar && (
        <div className="flex px-8 py-4 items-center justify-center">
          <img src={logo} alt="Logo" className="items-center " />
        </div>
      )}
      <div className="hidden xl:flex xl:items-center xl:justify-end px-2">
        <span
          className={`bg-[#285090] flex justify-center items-center text-white p-2 px-3 my-2 rounded-lg cursor-pointer transition duration-300 ease-in-out ${
            !showCompletesidebar && "w-full"
          } hover:bg-white hover:border-2 hover:border-[#285090] hover:!text-[#285090] `}
          onClick={() => {
            setShowCompletesidebar(!showCompletesidebar);
          }}
        >
          {showCompletesidebar ? <FaArrowLeft /> : <FaArrowRight />}
        </span>
      </div>
      <div className={`mt-2 py-2 ${showCompletesidebar ? "px-4" : "px-2"}`}>
        {showCompletesidebar && (
          <p className="pb-2 pr-2 text-md font-medium text-[#25396f]">
            {getTranslation(translate, "LIST")}
          </p>
        )}

        <NavigationBarButton
          title={getTranslation(translate, "DASHBOARD")}
          showCompletesidebar={showCompletesidebar}
          path="/dashboard"
          action={onButtonClick}
          icon={dashboardIcon}
          language={language}
        />
        {(role === "ROLE_RESP_UNI" || role === "ROLE_AGENT_UNI") && (
          <>
            {role === "ROLE_RESP_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "STUDENT_MANAGEMENT")}
                  icon={studentsIcons}
                  subMenu={studentSubMenuItemsResponsable}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  accordionName={"studentAccordion"}
                  showCompletesidebar={showCompletesidebar}
                />
              </>
            )}

            {role === "ROLE_AGENT_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "STUDENT_MANAGEMENT")}
                  icon={studentsIcons}
                  subMenu={studentSubMenuItemsAgent}
                  accordionName={"studentAccordion"}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  showCompletesidebar={showCompletesidebar}
                />
              </>
            )}
            {role === "ROLE_AGENT_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "DIPLOMAS_MANAGEMENT")}
                  icon={diplomaIcon}
                  subMenu={diplomaSubMenuItemsAgent}
                  accordionName={"diplomaAccordion"}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  isBadgeActive={true}
                  showCompletesidebar={showCompletesidebar}
                />
              </>
            )}
            {role === "ROLE_RESP_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "DIPLOMAS_MANAGEMENT")}
                  icon={diplomaIcon}
                  subMenu={diplomaSubMenuItemsResponsable}
                  accordionName={"diplomaAccordion"}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  isBadgeActive={true}
                  showCompletesidebar={showCompletesidebar}
                />
              </>
            )}
          </>
        )}
        {(role === "ROLE_RESP_UNI" ||
          role === "ROLE_ADMIN_ALECSO" ||
          role === "ROLE_ADMIN_K2LIS") && (
          <>
            <NavigationBarAccordion
              title={getTranslation(translate, "USERS_MANAGEMENT")}
              icon={usersIcon}
              subMenu={
                role === "ROLE_ADMIN_ALECSO"
                  ? usersSubMenuItemsResponsable
                  : [
                      ...usersSubMenuItemsResponsable,
                      {
                        title: ` ${getTranslation(translate, "ADD_USER")}`,
                        path: "/add_user",
                        icon: (
                          <>
                            <TiUserAdd />
                          </>
                        ),
                        activePath: "/add_user",
                        selectPage: () => {
                          changeSelectedPage("/add_user");
                        },
                      },
                    ]
              }
              accordionName={"usersAccordion"}
              selectedAccordion={selectedAccordion}
              setSelectedAccordion={setSelectedAccordion}
              showCompletesidebar={showCompletesidebar}
            />
          </>
        )}
        {role === "ROLE_ADMIN_ALECSO" && (
          <>
            <NavigationBarAccordion
              title={getTranslation(translate, "UNIVERSITY_MANAGEMENT")}
              icon={universityIcon}
              subMenu={universitySubMenuItemsResponsable}
              accordionName={"universityAccordion"}
              selectedAccordion={selectedAccordion}
              setSelectedAccordion={setSelectedAccordion}
              showCompletesidebar={showCompletesidebar}
            />
          </>
        )}

        <div className="w-2/3 mb-2 border border-blue-900" />

        <NavigationBarButton
          title={getTranslation(translate, "SETTING")}
          activePath="/settings"
          showCompletesidebar={showCompletesidebar}
          path="/settings"
          action={onButtonClick}
          icon={<IoSettingsOutline className="text-2xl" />}
        />
        <NavigationBarButton
          title={getTranslation(translate, "LOGOUT")}
          action={handleLogout}
          showCompletesidebar={showCompletesidebar}
          icon={<MdLogout className="text-2xl" />}
          logoutItem={true}
          activePath="/"
          path="/"
        />
      </div>
    </div>
  );
}

NavigationBarBody.propTypes = {
  role: PropTypes.string,
  changeSelectedPage: PropTypes.func,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  navigate: PropTypes.func,
  setSelectedPage: PropTypes.func,
  selectedPage: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.number,
  activeBadge: PropTypes.bool,
  setActiveBadge: PropTypes.func,
};

export default NavigationBarBody;
