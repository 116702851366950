import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Spinner from "../../components/spinner/Spinner";
import { useVerifyDiplomaMutation } from "../../store/service/diplomasService";
import certifiedLogo from "../../assets/certified.png";
import QRCode from "react-qr-code";

import { multilanguageReader } from "../../utils/translate/multilanguage-reader";
import { getTranslation } from "../../utils/translate/translation";

function VerificationPage() {
  const translate = {
    ar: {
      ORG_SPACE: "فضاء المؤسسة ",
      TYPE: "نوع الشهادة:",
      DIPLOMA_VERIFICATION: "   التحقق من صحّة شهادة ",
      SEARCH_RESULTS: "نتائج البحث ",
      METADATA: "البيانات الوصفية ",
      YEAR: "السنة الجامعية :",
      SPECIALITY: "الاختصاص:",
      AVERAGE: " المعدّل / المجموع :",
      REMARK: "الملاحظة :",
      DIPLOMA_PHOTO_ON_BLOCKCHAIN: "صورة الشهادة على البلوكتشين :",
      THIS_DIPLOMA_IS_NOT_FOUND: "هذه الشهادات غير موجودة ",
      VERIFICATION_LINK: "رابط التحقق ",
      DIPLOMA_IS_VALID: "تم التثبت من صحة الشهادة ",
      OPERATION_IN_PROGRESS: "عملية التحقق جارية ",
      DIPLOMA_IS_CONFIRM_BY: "مصادقة من طرف",
      DIPLOMA_IS_REVOKE: "هذه الشهادة سحبت",
    },
    en: {
      ORG_SPACE: "Organization Space ",
      DIPLOMA_VERIFICATION: "Diploma Verification :",
      SEARCH_RESULTS: "Search Results :",
      METADATA: "Metadata :",
      YEAR: "Academic Year :",
      SPECIALITY: "Speciality :",
      AVERAGE: "Average/Total :",
      REMARK: "Remark :",
      DIPLOMA_PHOTO_ON_BLOCKCHAIN: "Diploma Photo on Blockchain :",
      THIS_DIPLOMA_IS_NOT_FOUND: "This diploma is not found ",
      VERIFICATION_LINK: "Verification Link ",
      DIPLOMA_IS_VALID: "Diploma is valid ",
      TYPE: "Type Diploma:",
      OPERATION_IN_PROGRESS: "Operation in progress ",
      DIPLOMA_IS_CONFIRM_BY: "Confirmed by",
      DIPLOMA_IS_REVOKE: "This certificate has been withdrawn",
    },
    fr: {
      ORG_SPACE: "Espace de l'organisation ",
      DIPLOMA_VERIFICATION: "Vérification de diplôme :",
      SEARCH_RESULTS: "Résultats de recherche :",
      METADATA: "Métadonnées :",
      YEAR: "Année académique :",
      SPECIALITY: "Spécialité :",
      AVERAGE: "Moyenne/Total :",
      REMARK: "Remarque :",
      DIPLOMA_PHOTO_ON_BLOCKCHAIN: "Photo du diplôme sur la blockchain :",
      THIS_DIPLOMA_IS_NOT_FOUND: "Ce diplôme n'est pas trouvé ",
      VERIFICATION_LINK: "Lien de vérification ",
      DIPLOMA_IS_VALID: "Le diplôme est valide ",
      OPERATION_IN_PROGRESS: "Opération en cours ",
      TYPE: "Type Diplome:",
      DIPLOMA_IS_CONFIRM_BY: "Confirmé de la part",
      DIPLOMA_IS_REVOKE: "Ce certificat a été retiré",
    },
  };

  let id = useParams();
  let [verify] = useVerifyDiplomaMutation();
  const [revoke, setRevoke] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [responsableAddress, setResponsableAddress] = useState("");
  const [spinnerActive, setSpinnerActive] = useState(true);
  const [hashIpfs, setHashIpfs] = useState("");
  const [diploma, setDiploma] = useState({});
  useEffect(() => {
    setSpinnerActive(true);

    let data = {
      diplomaId: id.id,
    };
    verify(data).then((res) => {
      setDiploma(res?.data?.data);
      setResponsableAddress(res?.data?.responsable);

      setRevoke(res?.data?.revoked);
      if (res) {
        setSpinnerActive(false);
        if (res?.data?.data?.hashIpfs) {
          setHashIpfs(res?.data?.data?.hashIpfs);
        }
      }
    });
  }, []);
  const diplomasDigital = (id) => {
    window.open(`https://saddakni.alecso.org/digital_diploma/${id}`);
  };
  const fantomLink = () => {
    window.open(`https://testnet.ftmscan.com/address/${responsableAddress}`);
  };
  return (
    <>
      <div className="flex flex-col justify-center h-full p-10 ">
        <h1 className="text-2xl font-bold text-[#25396f]">
          {getTranslation(translate, "ORG_SPACE")}
        </h1>
        {!spinnerActive ? (
          <div className="flex flex-col items-start justify-between md:flex md:flex-row">
            {hashIpfs || (!hashIpfs && diploma?.status === "accepted") ? (
              <div className="flex flex-col justify-start w-full h-full mt-8">
                <p className="text-sm font-bold text-[#607080] pb-2">
                  {getTranslation(translate, "DIPLOMA_VERIFICATION")}
                </p>

                <div className="flex flex-col items-start min-h-[500px] h-fit w-full  p-4 bg-white rounded-xl ">
                  <h2 className=" text-xl font-bold text-[#25396f]">
                    {getTranslation(translate, "SEARCH_RESULTS")}
                  </h2>
                  <div className="h-full sm:justify-center xl:flex xl:w-full lg:w-full lg:flex md:flex sm:grid sm:gap-2 sm:w-full">
                    <div className="flex flex-col items-center justify-center w-full ">
                      <span className="w-full text-md font-medium text-[#656091] ">
                        {getTranslation(translate, "METADATA")}
                      </span>

                      <div className="w-full mb-4">
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              {" "}
                              {getTranslation(translate, "SPECIALITY")}{" "}
                            </span>
                            <span className="mr-2 ">
                              {" "}
                              {multilanguageReader(diploma?.speciality)}
                            </span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              {" "}
                              {getTranslation(translate, "TYPE")}{" "}
                            </span>
                            <span className="mr-2 ">
                              {" "}
                              {multilanguageReader(diploma?.type)}
                            </span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                          <div className="flex items-start w-full text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              {getTranslation(translate, "YEAR")}
                            </span>
                            <span className="mr-2 "> {diploma?.year}</span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              {getTranslation(translate, "AVERAGE")}
                            </span>
                            <span className="mr-2 "> {diploma?.average} </span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              {getTranslation(translate, "REMARK")}
                            </span>
                            <span className="mr-2 ">
                              {" "}
                              {multilanguageReader(diploma?.honors)}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full">
                      {diploma?.mode === "modeTwo" && (
                        <>
                          <QRCode
                            id="qr-gen"
                            size={300}
                            // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`https://saddakni.alecso.org/verification/${diploma?._id}`}
                            viewBox={`0 0 256 256`}
                          />
                        </>
                      )}
                      {diploma?.mode === "modeOne" && (
                        <>
                          <span className="text-lg text-blue-900">
                            {getTranslation(
                              translate,
                              "DIPLOMA_PHOTO_ON_BLOCKCHAIN"
                            )}
                          </span>
                          {spinnerActive && <Spinner />}

                          {hashIpfs && (
                            <div className="flex items-center justify-center w-full max-w-[20px] rounded-t-xl thirdParty">
                              <Document
                                file={
                                  // eslint-disable-next-line no-undef
                                  process.env.REACT_APP_URL +
                                  "/ipfs/" +
                                  hashIpfs
                                }
                                className="flex items-center justify-center"
                              >
                                <Page pageNumber={1} />
                              </Document>
                            </div>
                          )}

                          {hashIpfs === null && (
                            <span>
                              {getTranslation(
                                translate,
                                "THIS_DIPLOMA_IS_NOT_FOUND"
                              )}{" "}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {diploma?.mode === "modeThree" &&
                      diploma?.status !== "revoked" && (
                        <div className="flex flex-col items-center justify-center w-full gap-2">
                          <>
                            <span className="text-xl font-semibold text-blue-900">
                              Code QR
                            </span>
                            <QRCode
                              id="qr-gen"
                              size={300}
                              // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                              value={`https://saddakni.alecso.org/digital_diploma/${diploma?._id}`}
                              viewBox={`0 0 256 256`}
                            />
                            <span
                              className="text-md font-semibold cursor-pointer text-[#25396f]"
                              onClick={() => diplomasDigital(diploma._id)}
                            >
                              {getTranslation(translate, "VERIFICATION_LINK")}
                            </span>
                          </>
                        </div>
                      )}
                  </div>
                  {revoke ? (
                    <div className="flex flex-col items-center justify-center w-full mt-20">
                      <span className="font-bold text-red-500 lg:text-4xl md:text-xl sm:text-sm">
                        {getTranslation(translate, "DIPLOMA_IS_REVOKE")}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10 bg-red-500 rounded-full"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center w-full mt-20">
                      <span className="font-bold text-green-500 lg:text-4xl md:text-xl sm:text-sm">
                        {getTranslation(translate, "DIPLOMA_IS_VALID")}
                      </span>
                      <img
                        src={certifiedLogo}
                        alt="certified"
                        className="w-20 h-20 mt-8 "
                      />
                    </div>
                  )}
                  {!revoke && (
                    <div className="pt-2">
                      <span className="text-2xl font-medium text-[#25396f]">
                        {getTranslation(translate, "DIPLOMA_IS_CONFIRM_BY")}{" "}
                        <span
                          className="text-lg font-semibold cursor-pointer text-[#25396f]"
                          onClick={() => fantomLink()}
                        >
                          {responsableAddress}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-16">
                <span className="text-4xl font-bold text-blue-900">
                  {getTranslation(translate, "THIS_DIPLOMA_IS_NOT_FOUND")}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <span className="text-4xl font-bold text-blue-900">
              {getTranslation(translate, "OPERATION_IN_PROGRESS")}
            </span>
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
}

export default VerificationPage;
